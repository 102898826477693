import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import form from '../../../../scss/form.module.scss';
import { createFormObject } from '../../../../utils/form';
export const getUpdateForm = ({ data, handleUpdate, className: classNameParam, type, ...params }) => {
    return ({ className: classNameProp, children, edit = true, ...props }) => {
        const { attributes, options = {} } = Object.merge(params, props);
        const { className } = options;
        return (_jsx("div", { className: `${classNameParam} ${classNameProp} ${className}`, children: _jsx("form", { ...attributes, className: `${form.form} ${attributes?.className}`, onSubmit: (e) => {
                    attributes?.onSubmit?.(e);
                    handleUpdate(e);
                }, children: _jsxs(_Fragment, { children: [edit
                            ? createFormObject(type, {
                                method: 'update',
                                formOptions: options,
                            }, [data])
                            : createFormObject(type, {
                                method: 'read',
                                formOptions: options,
                            }, [data]), children] }) }) }));
    };
};
