/* eslint-disable react/prop-types */
import { ErrorBoundary, Query } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import type { Pattern as PatternInterface } from 'interfaces';

import styles from './index.module.scss';

export const Pattern: FunctionComponent = () => {
  const { t } = useTranslation();

  const params = useParams();

  const patternId = params.id;

  return (
    <ErrorBoundary>
      <Query<PatternInterface> query={`get_pattern_from_id?id=${patternId}`}>
        {({ data: pattern, loading }) => {
          if (loading) return <div>Loading...</div>;

          return (
            <div className={styles.main}>
              <h1>{t('productSheet')}</h1>
              <div>
                <div>
                  <img src={`data:image/gif;base64,${pattern.thumbnail}`} alt={`${t('patternImage')} ${pattern.name}`} draggable={false} />
                </div>
                <div>
                  <div>
                    <h3>{t('name')} :</h3>
                    <h3>{pattern.name}</h3>
                  </div>
                  <div>
                    <h3>Date :</h3>
                    <h3>{new Date(pattern.uploaded_at).toLocaleDateString('fr')}</h3>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    </ErrorBoundary>
  );
};
