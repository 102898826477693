import type { FunctionComponent, HTMLAttributes } from 'react';
import { useEffect, useState } from 'react';

import styles from './index.module.scss';

interface DropdownProps extends HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  children: JSX.Element | JSX.Element[];
  watchResize?: boolean;
  element?: (open: boolean) => JSX.Element;
  handleOpen?: (open: boolean) => any;
}

export const Dropdown: FunctionComponent<DropdownProps> = ({ open = false, children, className, watchResize = false, element, handleOpen, ...props }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [boxHeight, setBoxHeight] = useState(0);
  const [boxRef, setBoxRef] = useState<HTMLDivElement>();
  const [observer, setObserver] = useState<ResizeObserver>();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    setObserver(new ResizeObserver(([entry]) => setBoxHeight(parseInt(getComputedStyle(entry.target).height, 10))));
  }, []);

  useEffect(() => {
    if (boxRef) {
      if (watchResize && observer) observer.observe(boxRef);
      else setBoxHeight(parseInt(getComputedStyle(boxRef).height, 10));
    }

    return () => observer?.disconnect();
  }, [observer, boxRef]);

  return (
    <div className={`${styles.main} ${className}`}>
      <div
        onClick={() => {
          if (handleOpen) handleOpen(!isOpen);
          else setIsOpen(!isOpen);
        }}
      >
        {element?.(isOpen)}
      </div>
      <div data-testid='dropdown' style={{ height: isOpen ? boxHeight : '0px' }}>
        <div
          className={styles.box}
          ref={r => {
            if (r) setBoxRef(r);
          }}
          {...props}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
