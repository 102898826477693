import { contextGenerator } from '@generalizers/react-context';
import structuredClone from '@ungap/structured-clone';
import type { Dispatch, FunctionComponent, PropsWithChildren, SetStateAction } from 'react';

import type { Size } from 'components/utils/SizeIcon';

// Filters / File / ImageSrc

const defaultFilters: Filters = {
  dateBegin: { name: 'from' },
  dateEnd: { name: 'to' },
  books: [],
  rebracks: [],
  all_books: false,
  all_rebracks: false,
  showEmpty: false,
  drawings: [
    'abstract',
    'african',
    'animal',
    'arabesque',
    'camouflage',
    'tile',
    'writing',
    'ethnic',
    'exotic',
    'fantasy',
    'figurative',
    'flower',
    'fruit',
    'folk',
    'geometric',
    'birds',
    'leopard',
    'polka dot',
    'stripe',
    'tie and dye',
    'vegetal',
    'zebra',
    'gingham',
  ].map(v => ({ name: v, checked: false })),
  colors: [
    ['white', '#ccc', '#fff'],
    ['black', '#222', '#555'],
    ['grey', '#444', '#888'],
    ['red', '#b00', '#f00'],
    ['orange', '#dc6601', '#f1b04c'],
    ['yellow', '#cccc00', '#ffff00'],
    ['blue', '#202090', '#5252ff'],
    ['teal', '#007777', '#00bbbb'],
    ['pink', '#a0a', '#ff00ff'],
    ['purple', '#700070', '#a000a0'],
    ['brown', '#7a5230', '#dc9456'],
    ['green', '#276221', '#5bb450'],
    ['lime', '#32cd32', '#62fd62'],
    ['silver', '#bbc2cc', 'white', '#bbc2cc'],
    ['golden', '#d4af37', '#ff8', '#d4af37'],
    ['multicolor', 'red', 'yellow', 'green', 'cyan', 'blue', 'purple'],
  ].map(([name, ...colors]) => ({
    name,
    checked: false,
    colors,
  })),
  sort: {
    order_by: 'id',
    desc: true,
  },
  text: '',
  searchByPattern: '',
  patterns: [],
};

export const defaultDelay = 1000;

export const getDefaultFilters = (addon?: Partial<Filters>): Filters => ({ ...structuredClone(defaultFilters), ...structuredClone(addon) });

export const { useHook: useFilters, Provider: FiltersProvider, Consumer: FiltersConsumer } = contextGenerator<Filters>(getDefaultFilters(), 'Filters');

export const { useHook: useImage, Provider: ImageProvider, Consumer: ImageConsumer } = contextGenerator<File | undefined>(undefined, 'Image');
export const {
  useHook: useImageSrc,
  Provider: ImageSrcProvider,
  Consumer: ImageSrcConsumer,
} = contextGenerator<string | undefined>(undefined, 'ImageSrc');

export const { useHook: useSize, Provider: SizeProvider, Consumer: SizeConsumer } = contextGenerator<Size>('medium', 'Size');

export const FiltersImagesInitializer: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <FiltersProvider>
    <ImageProvider>
      <ImageSrcProvider>{children}</ImageSrcProvider>
    </ImageProvider>
  </FiltersProvider>
);

type State<T> = Dispatch<SetStateAction<T>>;
type StateArray<T> = [T, State<T>];
type FiltersImageArray = [StateArray<Filters>, StateArray<string | undefined>];

interface FiltersImages {
  children: (values: FiltersImageArray) => any;
}

export const useFiltersImages = () => [useFilters(), useImageSrc()] as FiltersImageArray;

export const FiltersImagesConsumer: FunctionComponent<FiltersImages> = ({ children }) => {
  return (
    <FiltersConsumer>
      {([filters, setFilters]) => (
        <ImageSrcConsumer>
          {([imageSrc, setImageSrc]) =>
            children([
              [filters, setFilters],
              [imageSrc, setImageSrc],
            ])
          }
        </ImageSrcConsumer>
      )}
    </FiltersConsumer>
  );
};

export interface FilterType {
  name: string;
  value?: number;
}

type Tags = { [k: string]: FilterType };

export interface Filters {
  dateBegin: FilterType;
  dateEnd: FilterType;
  books: number[];
  rebracks: number[];
  all_books: boolean;
  all_rebracks: boolean;
  drawings: CheckedFilter[];
  pattern_id?: number;
  text?: string;
  colors: ColorFilter[];
  sort: { order_by: 'name' | 'id' | 'random'; desc: boolean };
  showEmpty: boolean;
  searchByPattern?: string;
  patterns: number[];
}

export interface CheckedFilter {
  name: string;
  checked: boolean;
}

interface ColorFilter extends CheckedFilter {
  colors: string[];
}

// Page
export const { useHook: usePage, Provider: PageProvider, Consumer: PageConsumer } = contextGenerator<number>(1, 'Page');

export const {
  useHook: useVisualSearch,
  Provider: VisualSearchProvider,
  Consumer: VisualSearchConsumer,
} = contextGenerator<File | undefined>(undefined, 'VisualSearch');

export type View = 'pattern' | 'page' | 'rebrack';

export const {
  useHook: useView,
  Provider: ViewProvider,
  Consumer: ViewConsumer,
} = contextGenerator<View>((localStorage.getItem('catalogView') as View) ?? 'pattern', 'View');
