import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from "../components/utils/Form";
import { Fragment } from "react";
export const asFormTypes = (types) => types;
/**
 * Function used to create a Form element from form options
 *
 * @param type
 * @param options
 * @param data
 * @returns JSX.Element
 */
export const createFormObject = (type, options, data = []) => {
    return createFormObjectRecursive(type, options, data);
};
export const createFormObjectRecursive = (type, options, data = [], idName) => {
    const { method, noSubmit = false, formOptions = {} } = options;
    const { updatable = _jsx("button", { type: "submit", children: "Submit" }) } = formOptions;
    const pkName = idName ??
        Object.entries(type)
            .filter(([_, value]) => value.pk)
            .map(([k, v]) => v.name ?? k)[0];
    if (method == "create") {
        return (_jsxs(_Fragment, { children: [Object.entries(type).map(([fName, object], i) => {
                    return (_jsx(Form, { formObject: object, formObjectOptions: options, pkName: pkName, type: method, typeKey: fName }, i));
                }), !noSubmit && (typeof updatable != "boolean" || updatable) && updatable] }));
    }
    return (_jsx(_Fragment, { children: data.map((line, i) => {
            const idValue = line[pkName];
            const lineKeys = Object.keys(line);
            return (_jsxs(Fragment, { children: [_jsx(_Fragment, { children: Object.entries(type).map(([key, v], i) => {
                            return (_jsx(Form, { type: "update", formObject: v, formObjectOptions: options, typeKey: key, keys: lineKeys, pkName: pkName, idValue: idValue, dataLine: line, data: data }, i));
                        }) }), _jsx(_Fragment, { children: method != "read" &&
                            !noSubmit &&
                            (typeof updatable != "boolean" || updatable) &&
                            updatable })] }, i));
        }) }));
};
export const extractInsert = (insert, s) => {
    return insert
        .filter(([search]) => search == s)
        .map(([, e], i) => _jsx(Fragment, { children: e?.() }, i));
};
export const isFormEvent = (e) => {
    if ("target" in e)
        return true;
    return false;
};
