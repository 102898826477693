import type { NamedEntity } from 'interfaces';

export enum PatternType {
  DetectionPattern = 0,
  PagePattern = 1,
  RebrackPattern = 2,
  Pattern = 3,
}

export interface IFile {
  id: number;
  name: string;
}

export interface Pattern extends NamedEntity {
  thumbnail: string;
  uploaded_at: string;
  type: PatternType;
  width_in_cm: number;
  height_in_cm: number;
  uploaded_files: IFile[];
}

export interface RebrackPattern extends Pattern {
  rebrack_siblings: number[];
}

export function parseRebrack(pattern: Pattern): asserts pattern is RebrackPattern {
  if (pattern.type != PatternType.RebrackPattern) throw new TypeError('Not a rebrack');
}
