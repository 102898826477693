import { useTrigger } from '@generalizers/react-events';
import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import style from './index.module.scss';

interface NamedTriggerButtonProps {
  left: string;
  right: string;
  onChange?: (lang: string) => any;
}

export const NamedTriggerButton: FunctionComponent<NamedTriggerButtonProps> = ({ left, right, onChange: handleChange }) => {
  const [side, triggerSide] = useTrigger();
  const [box, setBox] = useState<HTMLDivElement>();

  useEffect(() => {
    handleChange?.(side ? left : right);
  }, [side]);

  return (
    <button className={style.main} onClick={() => triggerSide()}>
      <div ref={r => r && setBox(r)} style={{ left: side ? 0 : box ? box.offsetWidth + 8 : 0 }}>
        {side ? left : right}
      </div>
    </button>
  );
};
