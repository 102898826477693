import { useTrigger } from '@generalizers/react-events';
import { Fade, Skeleton, Slide, Tooltip } from '@mui/material';
import { Query } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { useImageSrc } from 'components/SmartCatalog/contexts';
import { HeartIcon } from 'components/utils/HeartIcon';

import type { ElementType } from 'interfaces/Selection';
import type { Pattern } from 'interfaces/pattern/Pattern';

import { ReactComponent as VSBoldIcon } from 'assets/images/visual_search_bold.svg';

import styles from './index.module.scss';

export interface RawSolutionItemProps {
  url?: string;
  link: (item: any) => string;
  active: boolean;
  buttonLink?: string;
  type?: ElementType;
  customLoader?: JSX.Element;
  pattern?: Pattern;
  id: number;
}

const loader = <Skeleton className={styles.placeholder} variant={'rectangular'} />;

export const RawSolutionItem: FunctionComponent<RawSolutionItemProps> = props => {
  const { url, active, buttonLink, type = 'pattern', customLoader, pattern } = props;
  const navigate = useNavigate();
  const [, setImageSrc] = useImageSrc();

  return (
    <Query<Pattern | undefined> query={url ?? ''} ignore={!active || !!pattern}>
      {({ data }) => {
        const item = data ?? pattern;

        return (
          <div className={styles.main}>
            {item ? (
              <div>
                <div>
                  <Slide direction='down' in={true} mountOnEnter>
                    <Fade in={true} mountOnEnter>
                      <img src={`data:image/png;base64,${item.thumbnail}`} draggable={false} />
                    </Fade>
                  </Slide>
                </div>
                <div className={styles.item}>
                  <div className={styles.itemBox}>
                    <div>
                      <Tooltip title={item.name} placement='left'>
                        <div className={styles.name}>{item.name}</div>
                      </Tooltip>
                    </div>
                    {buttonLink && (
                      <div
                        className={styles.itemBoxVS}
                        onClick={e => {
                          e.stopPropagation();
                          setImageSrc(`data:image/png;base64,${item.thumbnail}`);
                          navigate(`/${buttonLink}/search/${item.id}`);
                        }}
                      >
                        <VSBoldIcon />
                      </div>
                    )}
                    <HeartIcon className={styles.itemHeart} id={item.id} type={type} />
                  </div>
                </div>
              </div>
            ) : (
              <div>{customLoader || loader}</div>
            )}
          </div>
        );
      }}
    </Query>
  );
};
