type PathType =
  | 'home'
  | 'catalog'
  | 'lockeoDetection'
  | 'patterns'
  | 'results'
  | 'matches'
  | 'management'
  | 'admin'
  | 'digitalization'
  | 'settings'
  | 'me'
  | 'product'
  | 'defense'
  | 'protection'
  | 'counterfeits'
  | 'selections'
  | 'books'
  | 'annotation'
  | 'rebracks';

type Paths = {
  [k in PathType]: Path;
};

export class Path {
  private en: string;

  static join(...pathTypes: PathType[]) {
    return pathTypes.map(p => paths[p]).join('/');
  }

  static get(p: string) {
    if (!this.exists(p)) throw new Error(`${p} is not in paths`);
    return paths[p as PathType];
  }

  static exists(p: string) {
    return p in paths;
  }

  constructor(en: string) {
    this.en = en;
  }

  get url() {
    return this.en
      .split(' ')
      .map((d, i) => (i != 0 ? d.capitalize() : d))
      .join('');
  }

  toString() {
    return this.url;
  }
}

export const paths: Paths = {
  home: new Path('home'),
  catalog: new Path('catalog'),
  lockeoDetection: new Path('lockeo detection'),
  patterns: new Path('patterns'),
  results: new Path('results'),
  matches: new Path('matches'),
  management: new Path('management'),
  admin: new Path('admin'),
  digitalization: new Path('digitalization'),
  settings: new Path('settings'),
  me: new Path('me'),
  product: new Path('product'),
  defense: new Path('defense'),
  protection: new Path('protection'),
  counterfeits: new Path('counterfeits'),
  selections: new Path('selections'),
  books: new Path('books'),
  annotation: new Path('annotation'),
  rebracks: new Path('rebracks'),
};
