import { TextField } from '@mui/material';
import { Query, useRequest } from '@neovision/react-query';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { useState } from 'react';

import type { Pattern } from 'interfaces/pattern';

import styles from './index.module.scss';

export const Annotation: FunctionComponent = () => {
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionFr, setDescriptionFr] = useState('');
  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className={styles.main}>
      <Query<Pattern> query={'get_pattern_to_annote'}>
        {({ data: pattern, loading, forceRefresh }) => {
          if (loading) return <div>Loading...</div>;

          return (
            <>
              <div className={styles.img}>
                <img src={`data:image/png;base64,${pattern.thumbnail}`} />
              </div>
              <div className={styles.desc}>
                <i>Write down a description that would allow you to retreive this pattern</i>
                <div>
                  <div>
                    <label>English description</label>
                    <TextField
                      className={styles.other}
                      variant='filled'
                      multiline
                      minRows={10}
                      maxRows={10}
                      value={descriptionEn}
                      onChange={e => setDescriptionEn(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <label>French description</label>
                    <TextField
                      className={styles.other}
                      variant='filled'
                      multiline
                      minRows={10}
                      maxRows={10}
                      value={descriptionFr}
                      onChange={e => setDescriptionFr(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => {
                      request(`api/textualAnnotations/`, {
                        method: 'POST',
                        data: {
                          pattern: pattern.id,
                          description: descriptionEn,
                          description_fr: descriptionFr,
                        },
                      }).then(() => {
                        setDescriptionFr('');
                        setDescriptionEn('');
                        forceRefresh();
                      });
                    }}
                  >
                    Annotate
                  </button>
                  <button
                    onClick={() => {
                      const en = descriptionEn.trim();
                      const fr = descriptionFr.trim();
                      const from = en != '' ? 'en' : 'fr';
                      const to = en != '' ? 'fr' : 'en';

                      if ((en == '' || fr == '') && en != fr) {
                        request(`translate?text=${en != '' ? descriptionEn : descriptionFr}&from=${from}&to=${to}`).then(translation => {
                          if (en != '') setDescriptionFr(translation);
                          else setDescriptionEn(translation);
                        });
                      } else
                        enqueueSnackbar(`Both fields already have translations. <br>FR="${descriptionFr}" <br>EN="${descriptionEn}"`, {
                          variant: 'error',
                        });
                    }}
                  >
                    Translate missing language
                  </button>
                </div>
              </div>
            </>
          );
        }}
      </Query>
    </div>
  );
};
