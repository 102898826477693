import { Query } from '@neovision/react-query';
import i18next from 'i18next';
import hash from 'object-hash';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ListFooter } from 'components/SmartCatalog/Catalog/ListFooter';
import { useFiltersImages, usePage, useSize, useVisualSearch } from 'components/SmartCatalog/contexts';
import type { Size } from 'components/utils/SizeIcon';
import { SolutionItem } from 'components/utils/SolutionItem';

import { paths } from 'utils/paths';
import { PAGE_MAX_ELEMENTS } from 'utils/settings';

import styles from './index.module.scss';

export const PatternList: FunctionComponent = () => {
  const { t } = useTranslation();
  const [[filters], [imageSrc]] = useFiltersImages();
  const [image] = useVisualSearch();
  const [page] = usePage();
  const [size] = useSize();

  const col = size == 'small' ? 7 : size == 'medium' ? 5 : 3;

  const {
    dateBegin,
    dateEnd,
    all_books,
    books,
    all_rebracks,
    rebracks,
    pattern_id,
    text = '',
    colors,
    drawings,
    sort,
    searchByPattern,
    patterns,
  } = filters;
  const formData = useMemo(() => {
    const formData = new FormData();

    formData.append(
      'data',
      JSON.stringify({
        filter: {
          dateBegin: dateBegin.value || undefined,
          dateEnd: dateEnd.value || undefined,
          all_books: all_books && !all_rebracks,
          all_rebracks: all_rebracks && !all_books && books.length == 0,
          books,
          rebracks,
          pattern_filter: searchByPattern,
          patterns,
        },
        text:
          [...drawings, ...colors]
            .filter(c => c.checked)
            .reduce((prev, curr) => {
              return prev + curr.name + ' ';
            }, '') + text,
        params: { page_number: page, images_by_page: PAGE_MAX_ELEMENTS },
        sort,
      }),
    );
    image && imageSrc && formData.append('image', image);
    pattern_id && formData.append('pattern_id', pattern_id.toString());

    return formData;
  }, [hash(filters), hash(image ?? {}), imageSrc, page]);

  return (
    <div className={styles.main}>
      <Query<{ ids: number[]; count: number }> query={'patterns/'} method={'POST'} data={formData} cache={0}>
        {({ data, fetching }) => {
          const { ids = [], count } = data ?? {};

          const patterns = !fetching ? ids : [...new Array(PAGE_MAX_ELEMENTS)];

          return (
            <>
              <div className={styles.patterns} style={{ gridTemplateColumns: `repeat(${col}, 1fr)` }}>
                {patterns.map((patternId, i) => {
                  return (
                    <SolutionItem
                      key={i}
                      index={i}
                      url={`get_pattern_from_id?id=${patternId}&thumbnail=true`}
                      active={!!patternId}
                      link={({ id }) => `/${paths.catalog}/${paths.product}/${id}`}
                      buttonLink={paths.catalog.toString()}
                      includeSimilar
                      uniqueLoad={i == 0}
                    />
                  );
                })}
              </div>
              <ListFooter count={count} length={patterns.length} item={t('patterns')} />
            </>
          );
        }}
      </Query>
    </div>
  );
};
