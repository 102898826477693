import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from "../../../hooks/query";
import { parseLoader } from "../../../utils/api";
import ErrorBoundary from "../ErrorBoundary";
import { useQueryOptions } from "../QueryOptionsProvider";
import { StateFunction } from "../StateFunction";
/**
 * The query component is a fetch wrapper that allows to directly add logic in the design of the react DOM architecture
 *
 * Go to the [examples directory](https://github.com/NeovisionSAS/react-query/tree/main/src/examples) to see examples
 */
export const Query = ({ children, loader: qLoader, ...qRest }) => {
    const [{ loader: oLoader, mode }] = useQueryOptions();
    const { loading, ...qData } = useQuery(qRest);
    const loader = Object.merge(parseLoader(oLoader), parseLoader(qLoader));
    return (_jsx(ErrorBoundary, { detail: mode == "development", children: loading && loader.autoload ? (_jsx(_Fragment, { children: loader.loader ?? _jsx("div", { children: "Loading data..." }) })) : (_jsx(StateFunction, { children: () => children({ loading, ...qData }) })) }));
};
