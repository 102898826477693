import type { CSSProperties, FunctionComponent, HTMLAttributes } from 'react';
import { FaBackward, FaForward } from 'react-icons/fa';

import styles from './index.module.scss';

interface PageProps extends HTMLAttributes<HTMLDivElement> {
  page: number;
  startPage?: number;
  lastPage?: number;
  current?: number;
  className?: string;
  pageShow?: (page: number) => JSX.Element;
  style?: CSSProperties;
  handleLeft?: () => any;
  handleRight?: () => any;
}

const shownAmount = 4;

export const Paper: FunctionComponent<PageProps> = props => {
  const { page, className, startPage = 0, lastPage, current, pageShow, handleLeft, handleRight, ...attrs } = props;
  const realPage = (page - 1) * 2;
  const show = current ? current * 2 >= realPage - shownAmount && current * 2 <= realPage + shownAmount : true;

  return (
    <div {...attrs} className={`${styles.main} ${className ? className : ''}`}>
      <div style={{ pointerEvents: current && realPage == current * 2 ? 'all' : 'none' }}>
        {show && (
          <>
            <div>{startPage < realPage && (pageShow?.(realPage) ?? <></>)}</div>
            <div>
              <div>{realPage}</div>
              {lastPage && realPage < lastPage && (
                <div className={styles.arrow} onClick={handleRight} style={{ right: 0 }}>
                  <FaForward />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div style={{ pointerEvents: current && realPage + 1 == current * 2 - 1 ? 'all' : 'none' }}>
        {show && (
          <>
            <div>{pageShow?.(realPage + 1) ?? <></>}</div>
            <div>
              <div>{realPage + 1}</div>
              {realPage >= startPage && (
                <div
                  className={styles.arrow}
                  onClick={handleLeft}
                  style={{
                    left: 0,
                  }}
                >
                  <FaBackward />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
