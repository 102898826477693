import type { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from 'react';

import { ReactComponent as VS } from 'assets/images/visual_search.svg';
import { ReactComponent as VSCam } from 'assets/images/visual_search_cam.svg';

import styles from './index.module.scss';

interface VSIconProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const VSIcon: FunctionComponent<VSIconProps> = ({ className, ...props }) => {
  return (
    <div {...props} className={`${className} ${styles.main}`}>
      <VS />
      <VSCam />
    </div>
  );
};
