import type { FunctionComponent } from 'react';

import { isChristmas } from 'utils/functions';

import Logo from 'assets/images/Logo.png';
import LogoChristmas from 'assets/images/LogoChristmas.png';

import styles from './index.module.scss';

interface TextiliaLoaderProps {
  active: boolean;
}

export const TextiliaLoader: FunctionComponent<TextiliaLoaderProps> = ({ active }) => {
  return (
    <div data-testid='textiliaLoader' className={styles.main} style={{ opacity: active ? 1 : 0, pointerEvents: active ? 'all' : 'none' }}>
      <img src={isChristmas() ? LogoChristmas : Logo} draggable={false} />
    </div>
  );
};
