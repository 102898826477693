import { useMouse } from '@generalizers/react-events';
import type { CRUDObject } from '@neovision/react-query';
import { CRUD } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';

import type { UserContact } from 'interfaces';
import type WebsiteToScrap from 'interfaces/WebsiteToScrap';

import styles from './index.module.scss';

export const Settings: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.main}>
      <div>
        <h2>{t('contactsToAlert')}</h2>
        <div className={styles.form}>
          <CRUD endpoints={'api/userContacts'}>
            {(userCrud: CRUDObject<UserContact[]>) => {
              const [userUpdate, setUserUpdate] = useState(false);

              useMouse('click', () => setUserUpdate(false));

              const read = userCrud.read;
              if (read.loading) return <div>Loading...</div>;

              const resultContactNum = read.data.filter(user => user.result_contact).length;

              return read.data.length > 0 ? (
                <>
                  {resultContactNum == 0 && <div className={styles.formWarning}>{t('warningNoContactSelected')}</div>}
                  <form
                    className={`${userUpdate && styles.formEdit}`}
                    onSubmit={e => userUpdate && userCrud.handleUpdate(e, { name: 'id', method: 'PATCH' }).then(() => setUserUpdate(false))}
                    onClick={e => userUpdate && e.stopPropagation()}
                  >
                    {read.data.map((user, i) => {
                      const name = `${user.first_name} ${user.last_name} - ${user.email}`;

                      return (
                        <Fragment key={`user-contact-${i}`}>
                          <div>
                            <input
                              type='checkbox'
                              id={`user-contact-${user.id}`}
                              name={`result_contact-${user.id}`}
                              defaultChecked={user.result_contact}
                              disabled={!userUpdate}
                            />
                            <label htmlFor={`user-contact-${user.id}`}>{name}</label>
                          </div>
                        </Fragment>
                      );
                    })}
                    {userUpdate && <button type='submit'>{t('change')}</button>}
                  </form>
                  {!userUpdate && (
                    <button
                      onClick={e => {
                        e.stopPropagation();
                        setUserUpdate(true);
                      }}
                    >
                      {t('change')}
                    </button>
                  )}
                </>
              ) : (
                <div>{t('noOneInThisCompany')}</div>
              );
            }}
          </CRUD>
        </div>
      </div>
      <div>
        <h2>{t('websitesToWatch')}</h2>
        <div className={styles.form}>
          <CRUD endpoints={`api/websitesToScrap`}>
            {(websiteCrud: CRUDObject<WebsiteToScrap[]>) => {
              const [websiteUpdate, setWebsiteUpdate] = useState(false);

              useMouse('click', () => setWebsiteUpdate(false));

              const read = websiteCrud.read;
              if (read.loading) return <div>Loading...</div>;

              const appendJsx = (
                <form
                  className={styles.formAdd}
                  onSubmit={e => websiteCrud.handleCreate(e).then(() => setWebsiteUpdate(false))}
                  onClick={e => e.stopPropagation()}
                >
                  <div>
                    <label htmlFor='name'>{t('name')}*</label>
                    <input id='name' name={`name`} placeholder='Zara' required />
                  </div>
                  <div>
                    <label htmlFor='website'>{t('website').capitalize()}*</label>
                    <input id='website' name={`website`} placeholder='zara.fr' required />
                  </div>
                  <button type='submit'>{t('add')}</button>
                </form>
              );

              return read.data.length > 0 ? (
                <>
                  <form
                    className={`${websiteUpdate && styles.formEdit}`}
                    onSubmit={e => websiteCrud.handleUpdate(e, { name: 'id', method: 'PATCH' }).then(() => setWebsiteUpdate(false))}
                    onClick={e => websiteUpdate && e.stopPropagation()}
                  >
                    {read.data.map((website, i) => {
                      return (
                        <div key={`website-${i}`}>
                          <input
                            type={`checkbox`}
                            id={`active-${i}`}
                            name={`active-${website.id}`}
                            defaultChecked={website.active}
                            disabled={!websiteUpdate}
                          />
                          <label htmlFor={`active-${i}`}>{website.name}</label>
                          <label htmlFor={`active-${i}`}>{website.website}</label>
                          <FaTimes
                            visibility={websiteUpdate && !website.default ? 'visible' : 'hidden'}
                            onClick={() => websiteCrud.handleDelete({ id: website.id }).then(() => setWebsiteUpdate(false))}
                          />
                        </div>
                      );
                    })}
                    {websiteUpdate && <button type='submit'>{t('update')}</button>}
                  </form>
                  {websiteUpdate && appendJsx}
                  {!websiteUpdate && (
                    <button
                      onClick={e => {
                        e.stopPropagation();
                        setWebsiteUpdate(true);
                      }}
                    >
                      {t('change')} / {t('add')}
                    </button>
                  )}
                </>
              ) : (
                <>
                  <div>{t('noWebsitesToWatch')}</div>
                  {appendJsx}
                </>
              );
            }}
          </CRUD>
        </div>
      </div>
    </div>
  );
};
