import accountStyles from '../index.module.scss';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { ResetPasswordCode } from 'components/App/Account/ResetPassword/Code';

import form from 'scss/components/_form.module.scss';

import styles from './index.module.scss';

export const ResetPassword: FunctionComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState(localStorage.getItem('userEmail') ?? '');
  const navigate = useNavigate();

  const handleResetPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Auth.forgotPassword(username)
      .then(() => {
        navigate(`code/${username}`);
      })
      .catch((e: any) => {
        enqueueSnackbar(`${e.message} : ${t('impossibleToSendMail')}`, { variant: 'error' });
        console.error("Impossible d'envoyer un email", e);
      });
  };

  return (
    <Routes>
      <Route path='/code/:username' element={<ResetPasswordCode />} />
      <Route
        path='*'
        element={
          <div className={`${accountStyles.main} ${styles.main}`}>
            <form className={form.account} onSubmit={handleResetPassword}>
              <h2>{t('reinitializePassword')}</h2>
              <div>
                <label htmlFor='username'>E-mail*</label>
                <input type='email' name='username' value={username} onChange={e => setUsername(e.target.value)} required />
              </div>
              <button type='submit'>{t('recoveryEmail')}</button>
            </form>
          </div>
        }
      />
    </Routes>
  );
};
