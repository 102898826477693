import accountStyles from '../../index.module.scss';
import styles from '../index.module.scss';
import type { CognitoUser } from '@aws-amplify/auth';
import { Auth } from '@aws-amplify/auth';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import form from 'scss/components/_form.module.scss';

interface TemporaryProps {
  setFakeUser: (user: CognitoUser) => any;
}

export const Temporary: FunctionComponent<TemporaryProps> = ({ setFakeUser }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { email } = useParams();
  const navigate = useNavigate();

  const handleRegister = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    email &&
      Auth.signIn(email, code)
        .then(user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            localStorage.setItem('userEmail', email);
            setFakeUser(user);
            navigate(`/register/changePassword`);
          } else throw new Error('Wrong or no challenge to change password');
        })
        .catch(e => {
          enqueueSnackbar(t('impossibleToConnect'), { variant: 'error' });
          console.error(e.message);
        });
  };

  return (
    <div className={`${accountStyles.main} ${styles.main}`}>
      <form className={form.account} onSubmit={handleRegister}>
        <h2>{t('register')}</h2>
        <div>
          <label htmlFor='email'>E-mail*</label>
          <input type='email' name='email' defaultValue={email} disabled readOnly />
        </div>
        <div>
          <label htmlFor='code'>{t('temporaryPassword')}*</label>
          <input type='password' name='code' value={code} onChange={e => setCode(e.target.value)} required />
        </div>
        <button type='submit'>{t('confirm')}</button>
      </form>
    </div>
  );
};
