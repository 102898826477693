import type { Company } from 'interfaces';

export const isActive = (date?: string | Date) => {
  if (date != null) {
    let d = date;
    if (typeof d == 'string') d = new Date(date);
    d.setUTCHours(23, 59, 59, 999);
    return new Date().getTime() <= d.getTime();
  }
  return false;
};

export const isCompanyActive = (company: Company) => {
  return isActive(company.catalog_subscription_end) || isActive(company.detection_subscription_end) || isActive(company.protection_subscription_end);
};

export const phonePattern = '(\\+[1-9][0-9]?|0)?[1-7]( |-)?[0-9]{2}( |-)?[0-9]{2}( |-)?[0-9]{2}( |-)??[0-9]{2}';
