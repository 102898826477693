import { useMouse, useTrigger } from '@generalizers/react-events';
import { Alert, CircularProgress, Skeleton } from '@mui/material';
import { CRUD, Query, StateFunction, useRequest } from '@neovision/react-query';
import saveAs from 'file-saver';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegPlusSquare } from 'react-icons/fa';
import { FiShare2 } from 'react-icons/fi';
import { HiOutlineSave } from 'react-icons/hi';
import { IoChevronUp } from 'react-icons/io5';
import { IoTrashOutline } from 'react-icons/io5';
import { RxCross1 } from 'react-icons/rx';
import { Link } from 'react-router-dom';

import { BlackButton } from 'components/utils/Button/BlackButton';
import { Dropdown } from 'components/utils/Dropdown';
import { Search } from 'components/utils/Search';

import type { Selection, SelectionElements } from 'interfaces/Selection';
import type { ShareType } from 'interfaces/ShareType';

import { dropdownTransforms } from 'utils/dropdown';
import { Path } from 'utils/paths';

import styles from './index.module.scss';

export const Selections: FunctionComponent = () => {
  const [newSelection, setNewSelection] = useState<string>();
  const [selectionSearch, setSelectionSearch] = useState('');
  const [showAmount, setShowAmount] = useState(4);
  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useMouse('down', () => {
    setNewSelection(undefined);
  });

  return (
    <div className={styles.main}>
      <CRUD<Selection[]> endpoints={'api/selections'}>
        {({ read: { data, loading }, handleDelete, handleCreate, handleUpdate }) => {
          return (
            <>
              <div className={styles.header}>
                <div>
                  <Search placeholder={t('searchSelection')} value={selectionSearch} onChange={e => setSelectionSearch(e.target.value)} />
                  <label>{t('elementAmount')}</label>
                  <input
                    className={styles.show}
                    type={'number'}
                    min={2}
                    value={showAmount}
                    onChange={e => setShowAmount(parseInt(e.target.value))}
                    max={200}
                  />
                </div>
                <div>
                  <div className={styles.add}>
                    {newSelection || newSelection == '' ? (
                      <form
                        onMouseDown={e => e.stopPropagation()}
                        onSubmit={e => {
                          handleCreate(e);
                          setNewSelection(undefined);
                        }}
                      >
                        <input name={`name`} autoFocus={true} />
                        <button type='submit'>{t('create')}</button>
                      </form>
                    ) : (
                      <BlackButton className={styles.add} onClick={() => setNewSelection('')}>
                        <FaRegPlusSquare />
                        <div>{t('createNewSelection')}</div>
                      </BlackButton>
                    )}
                  </div>
                </div>
              </div>
              {!loading && (
                <div>
                  {data
                    .filter(({ name }) => name == '' || name.toLocaleLowerCase().includes(selectionSearch.toLocaleLowerCase()))
                    .sort(({ name: n1 }, { name: n2 }) => n1.localeCompare(n2))

                    .map(({ id, name }) => {
                      return (
                        <StateFunction key={id}>
                          {() => {
                            const [selectionOpen, setSelectionOpen] = useState(false);
                            const { t } = useTranslation();

                            return (
                              <div className={styles.item}>
                                <Query<SelectionElements> query={`get_selections_elements/?selection=${id}`}>
                                  {({ data, loading, manualUpdate }) => {
                                    const [download, setDownload] = useState(false);
                                    const [share, setShare] = useState(false);
                                    const [edit, triggerEdit] = useTrigger();

                                    const { pages, patterns, rebracks } = data ?? {};

                                    const noPatterns = pages?.length == 0 && patterns?.length == 0 && rebracks?.length == 0;

                                    useEffect(() => {
                                      if (noPatterns && selectionOpen) setSelectionOpen(false);
                                    }, [noPatterns]);

                                    if (loading) return <Skeleton variant='rectangular' />;

                                    const handleName = e => {
                                      handleUpdate({ id, name: e.target.value }, { method: 'PATCH' });
                                      triggerEdit(false);
                                    };

                                    return (
                                      <>
                                        <div className={styles.bar}>
                                          <div>
                                            <Link className={styles.link} to={id.toString()}>
                                              {name}
                                            </Link>
                                            {!noPatterns && (
                                              <div
                                                className={styles.arrow}
                                                style={dropdownTransforms(selectionOpen)}
                                                onClick={() => setSelectionOpen(!selectionOpen)}
                                              >
                                                <IoChevronUp />
                                              </div>
                                            )}
                                          </div>
                                          <div className={styles.actions}>
                                            {noPatterns && <Alert severity='warning'>{t('noPatternsInSelection')}</Alert>}
                                            {share ? (
                                              <CircularProgress style={{ width: 20, height: 20 }} />
                                            ) : (
                                              <button
                                                onClick={() => {
                                                  setShare(true);
                                                  request<ShareType>(`get_selection_zip_url?selection=${id}`)
                                                    .then(({ url }) => {
                                                      navigator.clipboard
                                                        .writeText(url)
                                                        .then(() => enqueueSnackbar(t('copiedShareLink'), { variant: 'success' }));
                                                    })
                                                    .finally(() => setShare(false));
                                                }}
                                              >
                                                <FiShare2 />
                                              </button>
                                            )}
                                            {download ? (
                                              <CircularProgress style={{ width: 20, height: 20 }} />
                                            ) : (
                                              <button
                                                onClick={() => {
                                                  setDownload(true);
                                                  request(`get_selection_zip?selection=${id}`, {
                                                    responseType: 'blob',
                                                  })
                                                    .then(r => {
                                                      saveAs(r, `${name}.zip`);
                                                    })
                                                    .finally(() => setDownload(false));
                                                }}
                                              >
                                                <HiOutlineSave />
                                              </button>
                                            )}
                                            <button
                                              onClick={e => {
                                                e.preventDefault();
                                                if (confirm(`${t('areYouSureSelection')} ${name} ?`)) handleDelete({ id });
                                              }}
                                            >
                                              <IoTrashOutline />
                                            </button>
                                          </div>
                                        </div>
                                        <Dropdown open={selectionOpen} watchResize={true}>
                                          <div className={styles.dropdown}>
                                            <>
                                              {patterns
                                                .filter((_, i) => i < showAmount)
                                                .map(({ id: pattern, thumbnail, name }, i) => {
                                                  return (
                                                    <div key={i} className={styles.selection}>
                                                      <div className={styles.card}>
                                                        <div>
                                                          <Link to={`/${Path.join('catalog', 'product')}/${pattern}`}>
                                                            <img src={`data:image/png;base64,${thumbnail}`} draggable={false} />
                                                          </Link>
                                                        </div>
                                                        <div>{name}</div>
                                                      </div>
                                                      <div
                                                        className={styles.cross}
                                                        onClick={() => {
                                                          request(`remove_pattern_from_selection/`, {
                                                            method: 'DELETE',
                                                            data: { pattern, selection: id },
                                                          }).then(() => {
                                                            data.patterns.splice(i, 1);
                                                            manualUpdate({ ...data });
                                                          });
                                                        }}
                                                      >
                                                        <RxCross1 />
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </>
                                            <>
                                              {pages
                                                .filter((_, i) => i < showAmount - patterns.length)
                                                .map(({ id: page, name, thumbnail, book }, i) => {
                                                  return (
                                                    <div key={i} className={styles.selection}>
                                                      <div className={styles.card}>
                                                        <div>
                                                          <Link to={`/${Path.join('catalog', 'books')}/${book}/${page}`}>
                                                            <img src={`data:image/png;base64,${thumbnail}`} draggable={false} />
                                                          </Link>
                                                        </div>
                                                        <div>{name}</div>
                                                      </div>
                                                      <div
                                                        className={styles.cross}
                                                        onClick={() => {
                                                          request(`remove_page_from_selection/`, {
                                                            method: 'DELETE',
                                                            data: { page, selection: id },
                                                          }).then(() => {
                                                            data.pages.splice(i, 1);
                                                            manualUpdate({ ...data });
                                                          });
                                                        }}
                                                      >
                                                        <RxCross1 />
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </>
                                            <>
                                              {rebracks
                                                .filter((_, i) => i < showAmount - (patterns.length + pages.length))
                                                .map(({ id: rebrack, name, thumbnail, rebrack_group }, i) => {
                                                  return (
                                                    <div key={i} className={styles.selection}>
                                                      <div className={styles.card}>
                                                        <div>
                                                          <Link to={`/${Path.join('catalog', 'rebracks')}/${rebrack_group}/${rebrack}`}>
                                                            <img src={`data:image/png;base64,${thumbnail}`} draggable={false} />
                                                          </Link>
                                                        </div>
                                                        <div>{name}</div>
                                                      </div>
                                                      <div
                                                        className={styles.cross}
                                                        onClick={() => {
                                                          request(`remove_rebrack_from_selection/`, {
                                                            method: 'DELETE',
                                                            data: { rebrack, selection: id },
                                                          }).then(() => {
                                                            data.rebracks.splice(i, 1);
                                                            manualUpdate({ ...data });
                                                          });
                                                        }}
                                                      >
                                                        <RxCross1 />
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </>
                                          </div>
                                        </Dropdown>
                                      </>
                                    );
                                  }}
                                </Query>
                              </div>
                            );
                          }}
                        </StateFunction>
                      );
                    })}
                </div>
              )}
            </>
          );
        }}
      </CRUD>
    </div>
  );
};
