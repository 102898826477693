import type { FunctionComponent, PropsWithChildren } from 'react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Path, paths } from 'utils/paths';

import style from './index.module.scss';

interface ArianeProps extends PropsWithChildren {
  prevent?: string[];
  lastLink?: boolean;
}

export const Ariane: FunctionComponent<ArianeProps> = ({ children, lastLink, prevent = [] }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const lastLinkExists = lastLink == undefined ? React.Children.count(children) > 0 : lastLink;

  return (
    <div className={style.main}>
      <Link to={`/${paths.home}`}>{t(paths.home.url)}</Link>
      &gt;
      <>
        {pathname
          .split('/')
          .filter(p => p != '' && Path.exists(p) && !prevent.includes(p))
          .map((path, i, arr) => {
            return (
              <Fragment key={i}>
                {i + 1 == arr.length && !lastLinkExists ? (
                  <div>{t(Path.get(path).url)}</div>
                ) : (
                  <Link to={`/${Path.join(...(arr.slice(0, i) as any), path as any)}`}>{t(Path.get(path).url)}</Link>
                )}
                {i + 1 != arr.length && <>&gt;</>}
              </Fragment>
            );
          })}
      </>
      <>
        {children &&
          React.Children.map(children, c => {
            return <>&gt;{c}</>;
          })}
      </>
    </div>
  );
};
