import { useQueryOptions } from "../../components/utils/QueryOptionsProvider";
import { request, requestOptionsMerge, } from "../../utils/api";
import { createCacheKey, getCache } from "../../utils/cache";
import { requestLog } from "../../utils/log";
import { totalProgressInitialiser, } from "../../utils/xhr/progress";
import { useCallback, useEffect, useState } from "react";
/**
 * Hook to interact with the server in a react way.
 *
 * Data to dispose to the user is stored in react stats.
 *
 * Effect hooks are used to know when to update the request
 *
 * @param QueryHookParams
 * @returns QueryReturn
 */
export const useQuery = ({ query, delay, onRead, disable = query == null, cache: queryCache, data, override = false, effect = [], active = true, memoizedLoading = false, ignore = false, ...queryOptions }) => {
    // The default data/load/error triple
    const [dataResolver, setDataResolver] = useState({
        data: undefined,
        loading: totalProgressInitialiser(),
        error: undefined,
        fetching: totalProgressInitialiser(),
    });
    const [refresh, setRefresh] = useState(false);
    const forceRefresh = () => setRefresh(!refresh);
    const manualUpdate = useCallback((data) => setDataResolver({
        ...dataResolver,
        data,
        loading: false,
        error: undefined,
    }), []);
    if (disable)
        return { ...dataResolver, forceRefresh, manualUpdate };
    const options = requestOptionsMerge([useQueryOptions()[0], queryOptions], override);
    const { domain, mode, verbosity, cache: cacheOption, method } = options;
    const cache = queryCache ?? cacheOption;
    const cacheHash = cache != 0 ? createCacheKey(query, data) : "";
    const req = (path, options) => request(domain, path, options);
    const [controller, setController] = useState();
    // Check if the query prop has changed
    useEffect(() => {
        if (!!active && !ignore) {
            requestLog(mode, verbosity, 8, `[read][${method}]`, `${domain}/${query}`, data ?? "");
            controller?.abort();
            const ctrl = new AbortController();
            const cacheData = getCache(cacheHash);
            const timeout = window.setTimeout(() => {
                const { signal, ...others } = options;
                req(query, {
                    signal: ctrl.signal,
                    cache,
                    ...others,
                    data,
                })
                    .then((res) => {
                    if (res != undefined) {
                        setDataResolver({
                            data: res,
                            loading: false,
                            error: undefined,
                            fetching: false,
                        });
                        onRead?.(res);
                    }
                })
                    .catch((err) => {
                    setDataResolver({
                        data: undefined,
                        loading: false,
                        error: err.statusText,
                        fetching: false,
                    });
                });
            }, delay ?? 0);
            setController(ctrl);
            if (!memoizedLoading || dataResolver.data == undefined)
                setDataResolver({
                    data: cacheData,
                    loading: cacheData == undefined,
                    error: undefined,
                    fetching: true,
                });
            return () => {
                ctrl?.abort();
                window.clearTimeout(timeout);
            };
        }
        if (ignore) {
            setDataResolver({
                data: undefined,
                fetching: false,
                loading: false,
                error: undefined,
            });
        }
    }, [query, refresh, data, !!active, ignore, ...effect]);
    return {
        ...dataResolver,
        manualUpdate,
        forceRefresh,
    };
};
