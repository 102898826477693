import type { ButtonHTMLAttributes, ReactNode } from 'react';

import style from './actionButton.module.scss';

interface ActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

const ActionButton = ({ children, ...rest }: ActionButtonProps) => {
  return (
    <button {...rest} className={style.actionButton}>
      {children}
    </button>
  );
};

export default ActionButton;
