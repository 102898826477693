import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { LuFileInput, LuScissorsSquare } from 'react-icons/lu';
import { useParams } from 'react-router-dom';

import { BlackButton } from 'components/utils/Button/BlackButton';

import { axiosInstance as axios } from 'utils/axios';

import ActionButton from './ActionButton';
import styles from './ToReannotateButton.module.scss';

interface ToReannotateButtonProps {
  pageId: number;
  status: number;
  onSuccess: (status: number) => void;
}

const ToReannotateButton = ({ pageId, status, onSuccess }: ToReannotateButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const sendToReAnnotation = async () => {
    try {
      setIsLoading(true);
      const updateAnnotationStatusRes = await axios.put(`/update_annotation_status/?page_id=${pageId}`);
      onSuccess(1);
      enqueueSnackbar(`${t('sendToReannotationSuccessMessage')}`, {
        variant: 'success',
      });
      handleClose();
    } catch (error) {
      enqueueSnackbar('Server error', {
        variant: 'error',
      });
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ActionButton
        onClick={() => {
          setIsConfirmDialogOpen(true);
        }}
        style={{
          pointerEvents: status === 1 ? 'none' : 'inherit',
        }}
      >
        <LuScissorsSquare />
        <div>{`${status === 0 ? t('ToReAnnotate') : t('inProgress')}`}</div>
      </ActionButton>
      <Dialog
        open={isConfirmDialogOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        className={styles.dialogContainer}
      >
        <Box p={2.5}>
          <h2 className={styles.dialogTitle}>{`${t('wronglyAnnotatedHeaderTitle')}`}</h2>
          <p className={styles.dialogContent}>{`${t('WronglyAnnotatedMessageContent')}`}</p>
          <DialogActions>
            <button onClick={() => handleClose()} className={`${styles.dialogButton} ${styles.cancelButton}`}>{`${t('cancel')}`}</button>
            <button
              className={`${styles.dialogButton} ${styles.confirmButton}`}
              onClick={() => {
                sendToReAnnotation();
              }}
            >
              {`${t('continue')}`}
            </button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default ToReannotateButton;
