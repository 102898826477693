import type { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from 'react';

import styles from './index.module.scss';

interface SliderProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  count: number;
  length: number;
}

export const Slider: FunctionComponent<SliderProps> = ({ count, length, className, ...props }) => {
  return (
    <div data-testid='slider' className={`${styles.main} ${className}`} {...props}>
      <div>
        {count.toFixed(2)} / {length}
      </div>
      <div className={styles.slider}>
        <div></div>
        <div style={{ width: `${(count / length) * 100}%` }}></div>
      </div>
    </div>
  );
};
