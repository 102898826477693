import { Skeleton } from '@mui/material';
import type { FormEvent, FunctionComponent } from 'react';
import { FaEdit } from 'react-icons/fa';

import styles from './index.module.scss';

interface TitleProps {
  loading?: boolean;
  edit?: boolean;
  handleEdit?: (edit: boolean) => any;
  handleSubmit?: (e: FormEvent) => any;
  value?: string;
  iconPlacement?: 'left' | 'right';
  size?: string;
}

export const Title: FunctionComponent<TitleProps> = ({
  edit = false,
  value = '',
  handleEdit,
  handleSubmit,
  loading,
  iconPlacement = 'right',
  size = '1.3em',
}) => {
  const editJSX = (
    <div
      className={styles.edit}
      onClick={e => {
        e.stopPropagation();
        handleEdit?.(true);
      }}
    >
      <FaEdit />
    </div>
  );

  return (
    <div className={styles.main} style={{ fontSize: size }}>
      {edit ? (
        <>
          <input
            defaultValue={value ?? ''}
            onClick={e => e.stopPropagation()}
            onMouseDown={e => e.stopPropagation()}
            onKeyDown={e => {
              if (e.key == 'Enter' || e.key == 'Escape') {
                handleEdit?.(false);
                handleSubmit?.(e);
              }
            }}
            autoFocus
          />
        </>
      ) : loading ? (
        <Skeleton />
      ) : (
        <>
          {iconPlacement == 'left' && editJSX}
          <div>{value}</div>
          {iconPlacement == 'right' && editJSX}
        </>
      )}
    </div>
  );
};
