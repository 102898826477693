import { Query } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { UserWithCompany } from 'interfaces/user';

import styles from './index.module.scss';

export const User: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.main}>
      <h2>{t('myAccount')}</h2>
      <Query<UserWithCompany> query='get_my_profile/'>
        {({ data: user, loading, error }) => {
          if (error) {
            navigate('/login');
            return <div>{t('yourAreNotConnected')}</div>;
          }
          if (loading) return <div>Loading...</div>;

          const name = `${user.first_name} ${user.last_name}`.trim();

          return (
            <form>
              <div>
                <label>{t('name')}</label>
                <input value={name || t('noName')} disabled readOnly />
              </div>
              <div>
                <label>Email</label>
                <input value={user.email || t('noEmail')} disabled readOnly />
              </div>
              <div>
                <label>{t('function')}</label>
                <input value={user.function || t('noFunction')} disabled readOnly />
              </div>
              <div>
                <label htmlFor={`phone`}>{t('phone')}</label>
                <input type='tel' value={user.phone_number || t('noPhone')} disabled readOnly />
              </div>
              <div>
                <label>{t('lastLogin')}</label>
                <input type='text' value={user.last_login || t('firstLogin')} disabled readOnly />
              </div>
              <div>
                <label htmlFor={`send_to_textilia`}>{t('ipoToText')}</label>
                <input name={`send_to_textilia`} id={`send_to_textilia`} type='checkbox' checked={user.send_to_textilia} disabled readOnly />
              </div>
              <div>
                <label htmlFor={`api_token`}>{t('apiKey')}</label>
                <input value={user.api_token || t('noAPIKey')} disabled readOnly />
              </div>
              <div>
                <label>{t('company')}</label>
                <input type='text' value={user.company.name || t('noCompanie')} disabled readOnly />
              </div>
            </form>
          );
        }}
      </Query>
    </div>
  );
};
