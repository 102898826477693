import { useTrigger } from '@generalizers/react-events';
import { FunctionComponent, useState } from 'react';
import { IoIosHeart, IoIosHeartEmpty } from 'react-icons/io';

import { SelectionMenu } from 'components/SmartCatalog/Selections/Menu';

import type { ElementType } from 'interfaces/Selection';

import styles from './index.module.scss';

interface HeartIconProps {
  id: number;
  type?: ElementType;
  className?: string;
}

export const HeartIcon: FunctionComponent<HeartIconProps> = ({ id, type, className }) => {
  const [open, triggerOpen] = useTrigger(false);
  const [element, setElement] = useState<Element>();

  return (
    <div className={`${styles.main} ${className}`} onClick={e => e.stopPropagation()}>
      <div onClick={() => triggerOpen()} ref={r => r && setElement(r)}>
        {open ? <IoIosHeart /> : <IoIosHeartEmpty />}
      </div>
      <SelectionMenu element={element} onClose={() => triggerOpen(false)} open={open} id={id} type={type} />
    </div>
  );
};
