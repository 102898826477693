import type { FunctionComponent, ImgHTMLAttributes } from 'react';
import { useState } from 'react';

import styles from './index.module.scss';

interface ImageZoomProps extends ImgHTMLAttributes<HTMLImageElement> {
  zoom?: number;
}

export const ImageZoom: FunctionComponent<ImageZoomProps> = ({ zoom = 1, className, onClick, ...props }) => {
  const [hoverElem, setHoverElem] = useState<HTMLDivElement>();

  const zoomPerc = (zoom - 1) * 100;

  return (
    <div
      data-testid='imageZoom'
      ref={r => r && setHoverElem(r)}
      className={`${styles.main} ${className}`}
      onClick={onClick}
      onMouseMove={({ pageX, clientY }) => {
        if (hoverElem) {
          const div = hoverElem.children[0] as HTMLElement;
          const img = div.children[0] as HTMLElement;

          const { offsetWidth, offsetHeight } = hoverElem;
          const { left: hl, top: ht, width, height } = div.getBoundingClientRect();
          const { clientWidth: x, clientHeight: y } = img;

          img.style.left = (-(((pageX - hl - (width - offsetWidth) / 2) / offsetWidth) * (x - width))).px;
          img.style.top = (-(((clientY - ht - (height - offsetHeight) / 2) / offsetHeight) * (y - height))).px;
        }
      }}
    >
      <div style={{ width: `${100 + zoomPerc * 2}%`, height: `${100 + zoomPerc * 2}%`, top: `${-zoomPerc}%`, left: `${-zoomPerc}%` }}>
        <img {...props} />
      </div>
    </div>
  );
};
