import { useKey } from '@generalizers/react-events';
import { Slider as MUISlider, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ErrorBoundary, Query, useRequest } from '@neovision/react-query';
import { fr } from 'date-fns/locale';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobe, FaGoogle, FaKeyboard } from 'react-icons/fa';

import { Placeholder, Popper, Popup } from 'components/utils';
import { ImageZoom } from 'components/utils/ImageZoom';
import { KeyboardViewer } from 'components/utils/KeyboardViewer';
import { Slider } from 'components/utils/Slider';

import type { ScrapPatterns } from 'interfaces';
import type { PreMatch } from 'interfaces/PreMatch';

import { riter } from 'utils/functions';

import styles from './index.module.scss';

interface Website {
  website: string;
  count: number;
}

export const Results: FunctionComponent = () => {
  const [date, setDate] = useState<Date | null>(new Date(2021, 5, 1));
  const [scrapIndex, setScrapIndex] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [sensibility, setSensibility] = useState<number>(0.3);
  const [skippedPks, setSkippedPks] = useState<number[]>([]);
  const [previousPk, setPreviousPk] = useState<number>();
  const { t } = useTranslation();
  const request = useRequest();

  return (
    <ErrorBoundary>
      <div className={styles.main}>
        <Query<PreMatch[]> query={`get_matching_scrap_images/?distance_max=${sensibility}`}>
          {({ data: preMatches, loading, error, manualUpdate }) => {
            const [sensibilityPlaceholder, setSensibilityPlaceholder] = useState(sensibility);
            const [deletedCount, setDeletedCount] = useState(0);
            const [up, setUp] = useState(false);
            const [allWebs, setAllWebs] = useState(true);
            const [webs, setWebs] = useState<boolean[]>([]);

            const websites = loading
              ? ([] as Website[])
              : preMatches
                  .map(p => p.website)
                  .reduce<Website[]>((prev, curr) => {
                    const e = prev.find(p => p.website == curr);
                    if (e) {
                      e.count++;
                      return prev;
                    }
                    prev.push({ website: curr, count: 1 });
                    return prev;
                  }, []);

            useEffect(() => {
              if (!loading) setWebs(websites.map(() => false));
            }, [loading]);

            const previousScrap = () => {
              if (previousPk) {
                setPreviousPk(undefined);
                request(`reset_validation_matching/`, { method: 'POST', data: { scrap_image_pk: previousPk } }).then(() => {
                  setScrapIndex(scrapIndex - 1);
                  const indexOfSkipped = skippedPks.indexOf(previousPk);
                  if (indexOfSkipped > -1) setSkippedPks(skippedPks.slice(0, skippedPks.length - 1));
                });
              }
            };

            const nextScrap = useCallback(
              (isSkip = false) => {
                const skip = preMatches[scrapIndex].id;
                if (skip != undefined) {
                  setPreviousPk(skip);
                  if (isSkip) setSkippedPks([...skippedPks, skip]);
                }
                setScrapIndex(scrapIndex + 1);
              },
              [preMatches, scrapIndex, skippedPks],
            );

            const noMatch = useCallback((pk: number) => {
              return request(`set_validation_matching/`, { method: 'POST', data: { scrap_image_pk: pk } });
            }, []);

            const filteredPks = loading ? [] : preMatches.filter(pm => allWebs || websites.some((w, i) => webs[i] && w.website == pm.website));

            useKey(
              'down',
              e => {
                if (e.ctrlKey && !loading) {
                  switch (e.key) {
                    case ' ':
                      e.preventDefault();
                      nextScrap(true);
                      break;
                    case 'z':
                      e.preventDefault();
                      previousScrap();
                      break;
                  }
                }
              },
              [filteredPks, scrapIndex, loading, skippedPks],
            );

            useKey(
              'down',
              e => {
                if (e.key == 'Escape') setUp(false);
              },
              [],
            );

            if (error) return <p>{error}</p>;

            //if (!loading && filteredPks.length <= scrapIndex) setScrapIndex(filteredPks.length);

            const scrapPk = filteredPks?.[scrapIndex]?.id;

            return (
              <Fragment>
                <div className={styles.info}>
                  <div>
                    <div className={styles.dates}>
                      <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                          views={['day']}
                          value={date}
                          onChange={newValue => setDate(newValue)}
                          renderInput={params => <TextField {...params} helperText={null} />}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className={styles.sensibility}>
                      <div>
                        {t('sensibility')} : <span>{sensibilityPlaceholder}</span>
                      </div>
                      <MUISlider
                        className={styles.sensibilitySlider}
                        min={0.1}
                        step={0.05}
                        max={0.5}
                        value={sensibilityPlaceholder}
                        onChange={(_, val) => {
                          setSensibilityPlaceholder(val as number);
                        }}
                        onChangeCommitted={(_, val) => {
                          setSensibility(val as number);
                          setPreviousPk(undefined);
                          setSkippedPks([]);
                          setScrapIndex(0);
                        }}
                        aria-labelledby='continuous-slider'
                      />
                    </div>
                    <div>
                      <div>{t('leftToAnalyse')}</div>
                      <div>{loading ? 0 : (filteredPks?.length ?? 0) - scrapIndex}</div>
                    </div>
                  </div>
                  <div className={styles.infoKeyboard}>
                    <FaKeyboard onClick={() => setUp(true)} />
                    <Popup up={up} onClose={() => setUp(false)}>
                      <KeyboardViewer
                        events={[
                          { keys: ['CTRL', t('del')], description: t('noMatch') },
                          { keys: ['CTRL', t('space')], description: t('skip') },
                          { keys: ['⇦'], description: t('selectPrevious') },
                          { keys: ['⇨'], description: t('selectNext') },
                          { keys: ['CTRL', t('enter')], description: t('match') },
                          { keys: ['CTRL', 'Z'], description: t('cancelLastAction') },
                        ]}
                      />
                    </Popup>
                  </div>
                </div>
                <div className={styles.bottom}>
                  <div className={styles.sites}>
                    <div className={styles.title}>{t('websites')}</div>
                    <div>
                      <input
                        type={'checkbox'}
                        checked={allWebs}
                        onChange={() => {
                          if (!allWebs) {
                            setAllWebs(true);
                            setWebs(webs.map(() => false));
                            setScrapIndex(0);
                            setSkippedPks([]);
                            setPreviousPk(undefined);
                          }
                        }}
                      />
                      <div>
                        {t('all')} ({websites.reduce((prev, curr) => prev + curr.count, 0)})
                      </div>
                    </div>
                    {websites.map(({ website, count }, i) => {
                      return (
                        <div key={`websites-${i}`}>
                          <input
                            type={'checkbox'}
                            checked={webs[i] ?? false}
                            onChange={e => {
                              webs[i] = e.target.checked;
                              setWebs([...webs]);
                              if (webs[i]) setAllWebs(false);
                              else if (!webs.some(w => w)) setAllWebs(true);
                              setScrapIndex(0);
                              setSkippedPks([]);
                              setPreviousPk(undefined);
                            }}
                          />
                          <div>
                            {website} ({count})
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {!loading ? (
                    scrapPk != null ? (
                      <Query<ScrapPatterns>
                        query={`get_matches_for_one_image/?scrap_image_pk=${filteredPks?.[scrapIndex].id}&distance_max=${sensibility}`}
                      >
                        {({ data: scrap, loading, error }) => {
                          const [selectedPattern, setSelectedPattern] = useState(0);
                          const [hoverId, setHoverId] = useState<number>();

                          useEffect(() => {
                            setSelectedPattern(0);
                          }, [scrapIndex, sensibility]);

                          useKey(
                            'down',
                            e => {
                              if (!loading) {
                                if (e.ctrlKey) {
                                  switch (e.key) {
                                    case 'Delete':
                                      e.preventDefault();
                                      handleNoMatch();
                                      break;
                                    case 'Enter':
                                      e.preventDefault();
                                      handleMatch();
                                      break;
                                  }
                                } else {
                                  switch (e.key) {
                                    case 'ArrowRight':
                                      e.preventDefault();
                                      if (selectedPattern < scrap.patterns.length - 1) setSelectedPattern(selectedPattern + 1);
                                      break;
                                    case 'ArrowLeft':
                                      e.preventDefault();
                                      if (selectedPattern > 0) setSelectedPattern(selectedPattern - 1);
                                      break;
                                  }
                                }
                              }
                            },
                            [selectedPattern, loading],
                          );

                          const handleMatch = useCallback(() => {
                            request(`set_validation_matching/`, {
                              method: 'POST',
                              data: {
                                scrap_image_pk: scrapPk,
                                valid_pattern_name: scrap.patterns[selectedPattern].name,
                              },
                            })
                              .then(() => nextScrap())
                              .catch(err => console.error(err));
                          }, [scrapPk, selectedPattern, scrap]);

                          const handleNoMatch = useCallback(() => {
                            noMatch(scrapPk)
                              .then(() => nextScrap())
                              .catch(err => console.error(err));
                          }, [scrapPk]);

                          if (error) return <p>{error}</p>;

                          const hasPatterns = scrap?.patterns.length != 0;

                          return (
                            <div className={styles.results}>
                              <div className={styles.resultsHeader}>
                                <div>
                                  <Popper className={styles.popper} poperElement={<div>{t('seeGoogleImage')}</div>}>
                                    <a href={scrap?.google_url ?? ''} target='blank' draggable={false}>
                                      {loading ? (
                                        <Placeholder className={styles.placeholder} />
                                      ) : (
                                        <img src={`data:image/gif;base64,${scrap.scrap}`} draggable={false} />
                                      )}
                                      <div>
                                        <FaGoogle />
                                      </div>
                                    </a>
                                  </Popper>
                                  <Popper className={styles.popper} poperElement={<div>{t('seeWebsite')}</div>}>
                                    <a href={scrap?.product_url ?? ''} target='blank' draggable={false}>
                                      {loading ? (
                                        <Placeholder className={styles.placeholder} />
                                      ) : (
                                        <img src={`data:image/gif;base64,${scrap.scrap_zoom}`} draggable={false} />
                                      )}
                                      <div>
                                        <FaGlobe />
                                      </div>
                                    </a>
                                  </Popper>
                                </div>
                                <div className={styles.buttons}>
                                  <button disabled={!!loading || !hasPatterns} onClick={handleMatch}>
                                    {t('match')}
                                  </button>
                                  <button disabled={!!loading || !hasPatterns} onClick={handleNoMatch}>
                                    {t('noMatch')}
                                  </button>
                                  <button onClick={() => nextScrap(true)}>{t('skip')}</button>
                                  <button disabled={!!loading || previousPk == undefined} onClick={previousScrap}>
                                    {t('back')}
                                  </button>
                                </div>
                              </div>
                              <div className={styles.resultsContent}>
                                {loading ? (
                                  riter(
                                    10,
                                    <div>
                                      <label>
                                        <Placeholder className={styles.placeholder} />
                                      </label>
                                    </div>,
                                  )
                                ) : scrap?.patterns.length > 0 ? (
                                  scrap.patterns.map((pattern, i) => {
                                    return (
                                      <div key={`pattern-${i}`} onMouseEnter={() => setHoverId(pattern.id)} onMouseLeave={() => setHoverId(undefined)}>
                                        <input type='radio' id={pattern.name} name={'matchInput'} checked={selectedPattern == i} readOnly={true} />
                                        <label htmlFor={pattern.name}>
                                          <img src={`data:image/gif;base64,${pattern.thumbnail}`} draggable={false} />
                                        </label>
                                        {hoverId == pattern.id && (
                                          <ImageZoom
                                            className={styles.imageZoom}
                                            src={`data:image/gif;base64,${pattern.thumbnail}`}
                                            draggable={false}
                                            onClick={() => setSelectedPattern(i)}
                                            zoom={1.1}
                                          />
                                        )}
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div>{t('noMatches')}</div>
                                )}
                              </div>
                            </div>
                          );
                        }}
                      </Query>
                    ) : (
                      <div className={styles.empty}>
                        {deletedCount != 0 ? (
                          <div className={styles.deleted}>
                            <div>{t('deletedElements')}</div>
                            <Slider count={deletedCount} length={skippedPks.length} />
                          </div>
                        ) : (
                          <>
                            <h3>{t('noElementToAnalyse')}</h3>
                            {skippedPks.length > 0 && (
                              <div>
                                <button
                                  onClick={() => {
                                    manualUpdate(preMatches.map((p, i) => ({ id: skippedPks[i], website: p.website })));
                                    setSkippedPks([]);
                                    setScrapIndex(0);
                                  }}
                                >
                                  {t('seePassedElements')}
                                </button>
                                <button
                                  onClick={() => {
                                    if (confirm(t('sureWantToDeletePassed'))) {
                                      const promises = skippedPks.map(skippedPk => noMatch(skippedPk).then(() => setDeletedCount(count => count + 1)));
                                      // All promises have resolved
                                      Promise.all(promises)
                                        .then(() => {
                                          manualUpdate([]);
                                          setScrapIndex(0);
                                          setSkippedPks([]);
                                          setDeletedCount(0);
                                        })
                                        .catch(() => enqueueSnackbar(t('errorDeletingListOfElements'), { variant: 'error' }));
                                    }
                                  }}
                                >
                                  {t('deletePassedElements')}
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )
                  ) : (
                    <Placeholder className={styles.placeholder} />
                  )}
                </div>
              </Fragment>
            );
          }}
        </Query>
      </div>
    </ErrorBoundary>
  );
};
