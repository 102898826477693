import styles from '../index.module.scss';
import { Query } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Placeholder } from 'components/utils';

import type { Pattern as PatternInterface } from 'interfaces';

import { paths } from 'utils/paths';
import { IMAGE_NB_BY_PAGE_PATTERN } from 'utils/settings';

const showAmountLimit = 1000;

interface InfinitProps {
  patternAmount: number;
}

export const Infinit: FunctionComponent<InfinitProps> = ({ patternAmount: size }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState<HTMLDivElement>();

  const maxPage = size / IMAGE_NB_BY_PAGE_PATTERN;
  const loadedAmount = page * IMAGE_NB_BY_PAGE_PATTERN;

  const handleObserve = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting === true && loadedAmount <= showAmountLimit) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserve, { threshold: [0] });
    if (pageLimit && page < maxPage) {
      observer.observe(pageLimit);
    }

    return () => {
      observer.disconnect();
    };
  }, [pageLimit, page]);

  return (
    <Fragment>
      {[...new Array(page).keys()]
        .map(p => p + 1)
        .map((page, i) => {
          return (
            <Query<PatternInterface[]> key={`scrollPage-${i}`} query={`get_patterns_data/?page_number=${page}&images_by_page=${IMAGE_NB_BY_PAGE_PATTERN}`}>
              {({ data: patterns, loading, error }) => {
                if (error) return <p>{error}</p>;

                return (
                  <Fragment>
                    {loading
                      ? [...new Array(IMAGE_NB_BY_PAGE_PATTERN)].map((_, i) => {
                          return <Placeholder key={`pattern-${i}`} className={styles.placeholder} />;
                        })
                      : patterns?.map((pattern, i) => {
                          return (
                            <Link
                              className={styles.pattern}
                              key={`pattern-${i}`}
                              to={`/${paths.lockeoDetection}/${paths.management}/${paths.patterns}/${pattern.id}`}
                              draggable={false}
                            >
                              <div>
                                <img src={`data:image/gif;base64,${pattern.thumbnail}`} draggable={false} />
                              </div>
                              <div>
                                <p>{pattern.name}</p>
                              </div>
                            </Link>
                          );
                        })}
                  </Fragment>
                );
              }}
            </Query>
          );
        })}
      <div ref={r => r && setPageLimit(r)} style={{ position: 'absolute', bottom: '0' }} />
      {loadedAmount > showAmountLimit && (
        <>
          <br />
          <h3 style={{ margin: '20px', width: '100%', textAlign: 'center' }}>{t('loadingPatternsLimit')}</h3>
        </>
      )}
    </Fragment>
  );
};
