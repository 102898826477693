import { useMouse, useTrigger } from '@generalizers/react-events';
import { Box, Textarea } from '@mui/joy';
import { CircularProgress, Skeleton, Typography } from '@mui/material';
import { Query, useRequest } from '@neovision/react-query';
import saveAs from 'file-saver';
import { useSnackbar } from 'notistack';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit } from 'react-icons/fa';
import { FiShare2 } from 'react-icons/fi';
import { HiOutlineSave } from 'react-icons/hi';
import { RxCross1 } from 'react-icons/rx';
import { Link, useParams } from 'react-router-dom';

import { Ariane } from 'components/SmartCatalog/Ariane';
import { Title } from 'components/SmartCatalog/utils/Title';
import { BlackButton } from 'components/utils/Button/BlackButton';
import { SolutionItem } from 'components/utils/SolutionItem';

import type { Selection, SelectionElements } from 'interfaces/Selection';
import type { ShareType } from 'interfaces/ShareType';

import { Path, paths } from 'utils/paths';

import styles from './index.module.scss';

export const SelectionPage: FunctionComponent = () => {
  const { id } = useParams();
  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Query<Selection> query={`api/selections/${id}`} loader={true} cache={0}>
      {({ data, manualUpdate }) => {
        const { name, created_at, modified_at, desc_max_length } = data;
        const [edit, triggerEdit] = useTrigger();
        const [description, setDescription] = useState(data.description ?? '');
        const [editDescription, triggerEditDescription] = useTrigger();
        const { t } = useTranslation();
        const [share, triggerShare] = useTrigger();
        const [download, triggerDownload] = useTrigger();

        const handleSubmit = e => {
          request(`api/selections/${id}/`, { method: 'PATCH', data: { id, name: e.target.value } })
            .then(() => {
              triggerEdit(false);
              manualUpdate({ ...data, name: e.target.value });
            })
            .catch(() => {});
        };

        useMouse(
          'down',
          () => {
            triggerEdit(false);
            handleSetDescription();
          },
          [description],
        );

        const handleSetDescription = () => {
          if (data.description == description) {
            triggerEditDescription(false);
          } else
            request(`api/selections/${id}/`, { method: 'PATCH', data: { id, description } })
              .then(() => {
                triggerEditDescription(false);
                manualUpdate({ ...data, description } as any);
              })
              .catch(() => {});
        };

        return (
          <div className={styles.main}>
            <div className={styles.ariane}>
              <Ariane>
                <div>{name}</div>
              </Ariane>
            </div>
            <div className={styles.mainContent}>
              <div className={styles.left}>
                <div className={styles.metadata}>
                  <Title value={name} edit={edit} handleSubmit={handleSubmit} handleEdit={triggerEdit} size='1.5em' />
                  <div className={styles.fields}>
                    <div>
                      <label>{t('creationDate')} : </label>
                      <div>{created_at}</div>
                    </div>
                    <div>
                      <label>{t('modifiedAt')} : </label>
                      <div>{modified_at}</div>
                    </div>
                    <div className={styles.description} onMouseDown={e => e.stopPropagation()}>
                      <div>
                        <div>Description :</div>
                        <div className={styles.descriptionTrigger}>
                          {editDescription ? (
                            <button onMouseDown={() => handleSetDescription()}>Save</button>
                          ) : (
                            <FaEdit onMouseDown={() => triggerEditDescription(true)} />
                          )}
                        </div>
                      </div>
                      {editDescription ? (
                        <Textarea
                          // type='text'
                          className={styles.other}
                          variant='soft'
                          name={'description'}
                          minRows={3}
                          value={description}
                          onChange={e => setDescription((e.target as any).value)}
                          slotProps={{
                            textarea: {
                              style: {
                                overflow: 'auto',
                                height: '100%',
                              },
                            },
                          }}
                          endDecorator={
                            <Typography sx={{ ml: 'auto' }}>
                              {description.length} / {desc_max_length}
                            </Typography>
                          }
                        />
                      ) : (
                        <div className={styles.descriptionText}>
                          {description.split('\n').map((desc, i) => (
                            <div key={i}>{desc}</div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <hr />
                <div className={styles.leftUtils}>
                  {share ? (
                    <CircularProgress style={{ width: 20, height: 20 }} />
                  ) : (
                    <BlackButton
                      onMouseDown={() => {
                        triggerShare(true);
                        request<ShareType>(`get_selection_zip_url?selection=${id}`)
                          .then(({ url }) => {
                            navigator.clipboard.writeText(url).then(() => enqueueSnackbar(t('copiedShareLink'), { variant: 'success' }));
                          })
                          .finally(() => triggerShare(false));
                      }}
                    >
                      <FiShare2 />
                      <div>{t('share')}</div>
                    </BlackButton>
                  )}
                  {download ? (
                    <CircularProgress style={{ width: 20, height: 20 }} />
                  ) : (
                    <BlackButton
                      onMouseDown={() => {
                        triggerDownload(true);
                        request(`get_selection_zip?selection=${id}`, {
                          responseType: 'blob',
                        })
                          .then(r => {
                            saveAs(r, `${name}.zip`);
                          })
                          .finally(() => triggerDownload(false));
                      }}
                    >
                      <HiOutlineSave />
                      <div>{t('download')}</div>
                    </BlackButton>
                  )}
                </div>
              </div>
              <div className={styles.right}>
                <Query<SelectionElements> query={`get_selections_elements/?selection=${id}`}>
                  {({ data, loading, manualUpdate }) => {
                    const { pages, patterns, rebracks } = data ?? {};

                    if (loading) return <Skeleton variant='rectangular' />;

                    const elements = [
                      { name: 'pattern', elements: patterns },
                      { name: 'page', elements: pages },
                      { name: 'rebrack', elements: rebracks },
                    ];

                    return (
                      <div className={styles.dropdown}>
                        <div className={styles.elements} style={{ gridTemplateColumns: `repeat(4, 1fr)` }}>
                          {elements.map(({ name, elements }, i) => {
                            return (
                              <Fragment key={i}>
                                {elements.map((element, j) => {
                                  const { id: elemId } = element;

                                  return (
                                    <div key={elemId} className={styles.element}>
                                      <SolutionItem
                                        url={`get_${name}_from_id?id=${elemId}&thumbnail=true`}
                                        active={!!elemId}
                                        type={name as any}
                                        link={({ id }) => {
                                          if (name == 'rebrack') return `/${Path.join('catalog', 'rebracks')}/${element.rebrack_group}/${id}`;
                                          else if (name == 'page') return `/${Path.join('catalog', 'books')}/${element.book}/${id}`;
                                          return `/${Path.join('catalog', 'product')}/${id}`;
                                        }}
                                        buttonLink={paths.catalog.toString()}
                                      />

                                      <div
                                        className={styles.cross}
                                        onMouseDown={() => {
                                          request(`remove_${name}_from_selection/`, {
                                            method: 'DELETE',
                                            data: { [name]: elemId, selection: id },
                                          }).then(() => {
                                            elements.splice(j, 1);
                                            manualUpdate({ ...data });
                                          });
                                        }}
                                      >
                                        <RxCross1 />
                                      </div>
                                    </div>
                                  );
                                })}
                              </Fragment>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }}
                </Query>
              </div>
            </div>
          </div>
        );
      }}
    </Query>
  );
};
