import type { FunctionComponent } from 'react';

import styles from './index.module.scss';

interface PlaceholderProps {
  speed?: number;
  className?: string;
  colorStart?: string;
  colorEnd?: string;
}

export const Placeholder: FunctionComponent<PlaceholderProps> = ({ speed = 1, className, colorStart = '#f0f0f0', colorEnd = '#e0e0e0' }) => {
  return (
    <div data-testid='placeholder' className={`${styles.main} ${className ?? ''}`}>
      <svg width={`100%`} height={`100%`} viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio={'none'}>
        <rect fill='url(#linear-gradient)' x='0' y='0' width='100' height='100'></rect>
        <defs>
          <linearGradient id='linear-gradient' x1={'0%'} y1={'0%'} x2={'100%'} y2={'0%'}>
            <stop offset={'0%'} stopColor={colorStart}>
              <animate attributeName='offset' values={`-2; -2; 1`} dur={`${1 / speed}s`} keyTimes={'0; 0.70; 1'} repeatCount='indefinite' />
            </stop>
            <stop offset={'50%'} stopColor={colorEnd}>
              <animate attributeName='offset' values={`0; 0; 2`} dur={`${1 / speed}s`} keyTimes={'0; 0.70; 1'} repeatCount='indefinite' />
            </stop>
            <stop offset={'100%'} stopColor={colorStart}>
              <animate attributeName='offset' values={`0; 0; 3`} dur={`${1 / speed}s`} keyTimes={'0; 0.90; 1'} repeatCount='indefinite' />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
