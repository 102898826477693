import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Crop as CropBox, PixelCrop } from 'react-image-crop';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { imgPreview } from 'components/SmartCatalog/Crop/imgPreview';

import magnifyingGlass from 'assets/images/magnifyingGlass.svg';

import style from './index.module.scss';

interface CropProps {
  file: File;
  onCrop?: (file: File) => any;
  onExit?: () => any;
}

export const Crop: FunctionComponent<CropProps> = ({ file, onCrop, onExit }) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState<CropBox>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [objectUrl] = useState(URL.createObjectURL(file));
  const imgRef = useRef<HTMLImageElement>(null);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop({ width: width - 20, height: height - 20, x: 10, y: 10, unit: 'px' } as any);
  };

  const search = () => {
    if (imgRef.current && completedCrop) {
      imgPreview(imgRef.current, completedCrop).then(image => {
        onCrop?.(image);
      });
    }
  };

  return (
    <div className={style.main}>
      <div>{t('zoneOfInterest')}</div>
      <div className={style.crop}>
        <div className={style.image}>
          <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={c => setCompletedCrop(c)}>
            <img ref={imgRef} src={objectUrl} onLoad={onImageLoad} draggable={false} />
          </ReactCrop>
        </div>
        <button className={style.close} onClick={onExit}>
          X
        </button>
      </div>
      <button className={style.search} onClick={search}>
        <img src={magnifyingGlass} draggable={false} />
        <div>{t('submitSearch')}</div>
      </button>
    </div>
  );
};
