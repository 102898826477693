import type { ButtonHTMLAttributes, DetailedHTMLProps, FunctionComponent } from 'react';

import { useSize } from 'components/SmartCatalog/contexts';

import style from './index.module.scss';

export type Size = 'small' | 'medium' | 'big';

const sizeFromStr = (s: Size) => {
  if (s == 'small') return 8;
  else if (s == 'medium') return 11;
  else if (s == 'big') return 14;
  return 0;
};

interface SizeIconProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  size: Size;
}

export const SizeIcon: FunctionComponent<SizeIconProps> = ({ size: wantedSize, className, ...props }) => {
  const [size, setSize] = useSize();
  const s = sizeFromStr(wantedSize);
  return (
    <button className={style.main} {...props} style={{ boxShadow: `0 0 0 ${wantedSize == size ? 1.5 : 0}px black` }} onClick={() => setSize(wantedSize)}>
      <div style={{ width: s, height: s }} />
    </button>
  );
};
