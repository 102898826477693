import { useMouse, useTrigger } from '@generalizers/react-events';
import { CircularProgress, Skeleton, TextField } from '@mui/material';
import { CRUD, CRUDAuto, Query, StateFunction, asFormTypes, useRequest } from '@neovision/react-query';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPen } from 'react-icons/fa';
import { FiShare2 } from 'react-icons/fi';
import { HiOutlineSave } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Ariane } from 'components/SmartCatalog/Ariane';
import { maxBookDate, minBookDate } from 'components/SmartCatalog/Catalog';
import { Title } from 'components/SmartCatalog/utils/Title';
import { BlackButton } from 'components/utils/Button/BlackButton';
import { HeartIcon } from 'components/utils/HeartIcon';
import { Popper } from 'components/utils/Popper';
import { SolutionItem } from 'components/utils/SolutionItem';

import type { EntityWithThumbnail, Metadata, Page as PageInterface, ShareType } from 'interfaces';

import { downloadThumbnail } from 'utils/download';
import { paths } from 'utils/paths';

import styles from './index.module.scss';

export const Page: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, page } = useParams();
  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const bookForm = asFormTypes({
    id: { pk: true, visible: false },
    name: { visible: false },
    year: {
      name: t('bookYear'),
      type: 'number',
      min: minBookDate,
      max: maxBookDate,
    },
    collection: {},
    cloth_type: {
      name: t('fabricType'),
    },
    location: {
      name: t('location'),
      className: styles.sub,
      sub: {
        site_location: { name: t('site') },
        building_location: { name: t('building') },
        room_location: { name: t('room') },
        shelf_location: { name: t('shelf') },
      },
    },
    other: {
      name: t('other'),
      render({ fName, oName, value, setValue }) {
        return (
          <div>
            <label htmlFor={fName}>{oName}</label>
            <TextField
              className={styles.other}
              variant='filled'
              name={fName}
              multiline
              value={value}
              onChange={e => setValue(e.target.value)}
              inputProps={{
                id: fName,
              }}
            />
          </div>
        );
      },
    },
  });

  return (
    <CRUDAuto<Metadata>
      type={bookForm}
      endpoints={{
        read: `api/metadata/${id}`,
        create: `api/metadata/`,
        delete: `api/metadata/${id}`,
        update: {
          endpoint: `api/metadata/${id}`,
          method: 'PATCH',
        },
      }}
      parameterType='none'
    >
      {({
        forms: { EntriesForm },
        data: {
          read: { data: book },
        },
      }) => {
        const [editTitle, setEditTitle] = useState(false);
        const [popup, setPopup] = useState([false, false, false]);

        useMouse('click', () => popup.some(v => v) && setPopup([...popup.fill(false)]), [popup]);
        useMouse('click', () => setEditTitle(false), []);

        const handlePopup = (n: number) => e => {
          e.stopPropagation();
          const v = !popup[n];
          popup.fill(false);
          popup[n] = v;
          setPopup([...popup]);
        };

        return (
          <Query<PageInterface[]> query={`api/pages/?filters=${encodeURIComponent(JSON.stringify({ books: [id] }))}`}>
            {({ data: pages = [], loading: pagesLoading }) => {
              const handlePageNum = (n?: number) => e => {
                let nn = n;
                if (nn == undefined) nn = parseInt(e.target.value) ?? 1;
                if (pages && nn > 0 && nn <= pages.length + 1) navigate(`/${paths.catalog}/${paths.books}/${id}/${pages[nn - 1].id}`, { replace: true });
              };

              const pageNum = pagesLoading ? 1 : pages.findIndex(p => `${p.id}` == page) + 1;

              const minShow = pageNum > 1;
              const maxShow = pageNum < pages.length;

              return (
                <CRUD<EntityWithThumbnail>
                  endpoints={{
                    create: `api/pages`,
                    read: `get_page_from_id?id=${pages[pageNum - 1]?.id}`,
                    delete: `api/pages`,
                    update: `api/pages`,
                  }}
                  active={!pagesLoading}
                >
                  {({ read: { data: page, loading: pageLoading }, handleUpdate }) => {
                    const pageId = pages[pageNum - 1]?.id;

                    const [download, setDownload] = useState(false);
                    const [share, setShare] = useState(false);

                    return (
                      <div className={styles.main}>
                        <Ariane prevent={[paths.books.url]}>
                          <Link to={`/${paths.catalog.url}/book/${book.id}`}>{book?.name}</Link>
                          <div>{page?.name}</div>
                        </Ariane>
                        <div>
                          <div className={styles.left}>
                            <div className={styles.page}>
                              <div className={styles.pageArr} style={{ opacity: minShow ? 1 : 0, pointerEvents: minShow ? 'auto' : 'none' }}>
                                <button style={{ transform: 'rotate(-90deg)' }} onClick={handlePageNum(pageNum - 1)}>
                                  ⌃
                                </button>
                              </div>
                              <div className={styles.pageImg}>
                                {pageLoading ? <Skeleton variant='rectangular' /> : <img src={`data:image/png;base64,${page.thumbnail}`} />}
                                <HeartIcon className={styles.selection} id={pageId} type={'page'} />
                              </div>
                              <div className={styles.pageArr} style={{ opacity: maxShow ? 1 : 0, pointerEvents: maxShow ? 'auto' : 'none' }}>
                                {pageNum <= pages.length + 1 && (
                                  <button style={{ transform: 'rotate(90deg)' }} onClick={handlePageNum(pageNum + 1)}>
                                    ⌃
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className={styles.pageNum}>
                              <div>Page :</div>
                              <div>
                                <TextField
                                  className={styles.pageNumField}
                                  type='number'
                                  InputProps={{ inputProps: { min: 1, max: pages.length } }}
                                  variant='standard'
                                  value={pageNum}
                                  onChange={handlePageNum()}
                                />
                                <div>
                                  {t('on')} {pages.length}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.right}>
                            <Title
                              edit={editTitle}
                              value={page?.name}
                              handleEdit={edit => {
                                setEditTitle(edit);
                              }}
                              handleSubmit={e => {
                                handleUpdate({ id: page.id, name: (e?.target as any).value }, { method: 'PATCH' });
                              }}
                            />
                            <EntriesForm>
                              {({ UpdateForm, data: book }) => {
                                return (
                                  <div className={styles.name}>
                                    <div>{t('book')} :</div>
                                    <Link to={`/${paths.catalog}/book/${book.id}`}>{book.name}</Link>
                                    <button className={styles.metadata} onClick={handlePopup(2)}>
                                      <IoIosInformationCircleOutline />
                                    </button>
                                    <StateFunction>
                                      {() => {
                                        const [edit, triggerEdit] = useTrigger();

                                        useMouse('click', () => triggerEdit(false), []);

                                        return (
                                          <div
                                            className={styles.form}
                                            style={{ opacity: popup[2] ? 1 : 0, pointerEvents: popup[2] ? 'all' : 'none' }}
                                            onClick={e => e.stopPropagation()}
                                          >
                                            <UpdateForm
                                              edit={edit}
                                              attributes={{ onSubmit: handlePopup(2) }}
                                              options={{ updatable: <button type='submit'>{t('update')}</button> }}
                                            />
                                            <FaPen onClick={() => triggerEdit()} />
                                          </div>
                                        );
                                      }}
                                    </StateFunction>
                                    <hr />
                                  </div>
                                );
                              }}
                            </EntriesForm>
                            <div className={styles.patterns} style={{ gridTemplateColumns: `repeat(3, 1fr)` }}>
                              <Query<number[]> query={`get_pattern_ids_from_page?page_id=${pageId}`} active={!pagesLoading} effect={[pageLoading]}>
                                {({ data: patternIds = new Array(9).fill(undefined) }) => {
                                  return (
                                    <>
                                      {patternIds.map((patternId, i) => {
                                        return (
                                          <SolutionItem
                                            key={i}
                                            url={`get_pattern_from_id?id=${patternId}&thumbnail=true`}
                                            active={!!patternId}
                                            link={({ id }) => `/${paths.catalog}/${paths.product}/${id}`}
                                            buttonLink={paths.catalog.toString()}
                                          />
                                        );
                                      })}
                                    </>
                                  );
                                }}
                              </Query>
                            </div>
                            <hr />
                            <div className={styles.share}>
                              <Popper
                                poperElement={
                                  <div className={styles.popper}>
                                    <div>{t('share')}</div>
                                    <div className={styles.section}>
                                      <div>Page</div>
                                      <BlackButton
                                        onClick={e => {
                                          handlePopup(0)(e);
                                          request<ShareType>(`get_page_zip_url?page_id=${pageId}`).then(({ url }) => {
                                            navigator.clipboard.writeText(url).then(() => enqueueSnackbar(t('copiedShareLink'), { variant: 'success' }));
                                          });
                                        }}
                                      >
                                        <FiShare2 />
                                        <div>{t('sharePage')}</div>
                                      </BlackButton>
                                      <BlackButton
                                        onClick={e => {
                                          handlePopup(0)(e);
                                          setShare(true);
                                          request<ShareType>(`get_pattern_zip_url_from_page/?page_id=${pageId}`)
                                            .then(({ url }) => {
                                              navigator.clipboard.writeText(url).then(() => enqueueSnackbar(t('copiedShareLink'), { variant: 'success' }));
                                            })
                                            .finally(() => setShare(false));
                                        }}
                                      >
                                        <FiShare2 />
                                        <div>{t('shareAllPatternsPage')}</div>
                                      </BlackButton>
                                    </div>
                                    <div className={styles.section}>
                                      <div>{t('book')}</div>
                                      <BlackButton
                                        onClick={e => {
                                          handlePopup(0)(e);
                                          setShare(true);
                                          request<ShareType>(`get_page_zip_url_from_book?book_id=${book.id}`)
                                            .then(({ url }) => {
                                              navigator.clipboard.writeText(url).then(() => enqueueSnackbar(t('copiedShareLink'), { variant: 'success' }));
                                            })
                                            .finally(() => setShare(false));
                                        }}
                                      >
                                        <FiShare2 />
                                        <div>{t('shareBookPages')}</div>
                                      </BlackButton>
                                      <BlackButton
                                        onClick={e => {
                                          handlePopup(0)(e);
                                          if (!download) {
                                            setShare(true);
                                            request<ShareType>(`get_pattern_zip_url_from_book?book_id=${book.id}`)
                                              .then(({ url }) => {
                                                navigator.clipboard
                                                  .writeText(url)
                                                  .then(() => enqueueSnackbar(t('copiedShareLink'), { variant: 'success' }));
                                              })
                                              .finally(() => setShare(false));
                                          }
                                        }}
                                      >
                                        <FiShare2 />
                                        <div>{t('shareBookPatterns')}</div>
                                      </BlackButton>
                                    </div>
                                  </div>
                                }
                                activation={'click'}
                                indicator={'transparent'}
                                up={popup[0]}
                                onClick={handlePopup(0)}
                              >
                                <BlackButton className={styles.shareButton}>
                                  <FiShare2 />
                                  <div>{t('share')}</div>
                                  {share && <CircularProgress style={{ width: '20px', height: '20px' }} />}
                                </BlackButton>
                              </Popper>
                              <Popper
                                poperElement={
                                  <div className={styles.popper}>
                                    <div>{t('download')}</div>
                                    <div className={styles.section}>
                                      <div>Page</div>
                                      <BlackButton
                                        onClick={e => {
                                          handlePopup(1)(e);
                                          downloadThumbnail(page.thumbnail, page?.name);
                                        }}
                                      >
                                        <HiOutlineSave />
                                        <div>{t('downloadPage')}</div>
                                      </BlackButton>
                                      <BlackButton
                                        onClick={e => {
                                          handlePopup(1)(e);
                                          if (pageId != undefined && !download) {
                                            setDownload(true);
                                            request(`get_pattern_zip_from_page?page_id=${pageId}`, {
                                              responseType: 'blob',
                                            })
                                              .then(r => {
                                                saveAs(r, page?.name + '_motifs.zip');
                                              })
                                              .finally(() => setDownload(false));
                                          }
                                        }}
                                      >
                                        <HiOutlineSave />
                                        <div>{t('downloadAllPatternsPage')}</div>
                                      </BlackButton>
                                    </div>
                                    <div className={styles.section}>
                                      <div>{t('book')}</div>
                                      <BlackButton
                                        onClick={e => {
                                          handlePopup(1)(e);
                                          setDownload(true);
                                          request(`get_page_zip_from_book?book_id=${book.id}`, {
                                            responseType: 'blob',
                                          })
                                            .then(r => {
                                              saveAs(r, book?.name + '_pages.zip');
                                            })
                                            .finally(() => setDownload(false));
                                        }}
                                      >
                                        <HiOutlineSave />
                                        <div>{t('downloadBookPages')}</div>
                                      </BlackButton>
                                      <BlackButton
                                        onClick={e => {
                                          handlePopup(1)(e);
                                          if (!download) {
                                            setDownload(true);
                                            request(`get_pattern_zip_from_book?book_id=${book.id}`, {
                                              responseType: 'blob',
                                            })
                                              .then(r => {
                                                saveAs(r, book?.name + '_motifs.zip');
                                              })
                                              .finally(() => setDownload(false));
                                          }
                                        }}
                                      >
                                        <HiOutlineSave />
                                        <div>{t('downloadBookPatterns')}</div>
                                      </BlackButton>
                                    </div>
                                  </div>
                                }
                                activation={'click'}
                                indicator={'transparent'}
                                up={popup[1]}
                                onClick={handlePopup(1)}
                              >
                                <BlackButton className={styles.shareButton}>
                                  <HiOutlineSave />
                                  <div>{t('download')}</div>
                                  {download && <CircularProgress style={{ width: '20px', height: '20px' }} />}
                                </BlackButton>
                              </Popper>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </CRUD>
              );
            }}
          </Query>
        );
      }}
    </CRUDAuto>
  );
};
