import { Pagination } from '@mui/material';
import { Query } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes } from 'react-router-dom';

import Counterfeit from 'components/CounterfeitDetection/Manage/Counterfeit';
import { Placeholder } from 'components/utils';

import type { MatchCounterfeit } from 'interfaces';

import { riter } from 'utils/functions';
import { paths } from 'utils/paths';
import { IMAGE_NB_BY_PAGE_COUNTERFEIT } from 'utils/settings';

import styles from './index.module.scss';

export const CounterfeitList: FunctionComponent = () => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(1);

  return (
    <Routes>
      <Route path={`:id`} element={<Counterfeit />} />
      <Route
        path={`/*`}
        element={
          <div className={styles.main}>
            <h1>{t('counterfeitsList')}</h1>
            <Query<number> query={`get_counterfeit_number/`}>
              {({ data: matchCount, loading, error }) => {
                if (loading) return <Placeholder className={styles.placeholder} />;
                if (error) return <p>{error}</p>;

                const pageCount = Math.ceil(matchCount / IMAGE_NB_BY_PAGE_COUNTERFEIT);

                return (
                  <div>
                    <Pagination count={pageCount} page={page} color='standard' onChange={(_, page) => setPage(page)} />
                    <div className={styles.counterfeits}>
                      <Query<MatchCounterfeit[]> query={`get_counterfeits/?page_number=${page}&counterfeit_nb=${IMAGE_NB_BY_PAGE_COUNTERFEIT}`}>
                        {({ data: matches, loading, error }) => {
                          if (error) return <p>{error}</p>;

                          return (
                            <React.Fragment>
                              {loading
                                ? riter(IMAGE_NB_BY_PAGE_COUNTERFEIT, <Placeholder className={styles.placeholder} />)
                                : matches?.map((match, i) => {
                                    return (
                                      <div className={styles.counterfeit} key={`counterfeit-${i}`}>
                                        <Link to={`/${paths.lockeoDetection}/${paths.management}/${paths.counterfeits}/${match.id}`} draggable={false}>
                                          <img src={`data:image/gif;base64,${match.scrap.scrap}`} draggable={false} />
                                        </Link>
                                        <div>
                                          <div>
                                            <p>{t('sellerWebsite')}</p>
                                            <p>{match.scrap.product_website.replace(/https?:\/{2}(www.)?(\w+)\.(\w+)/, '$2').toUpperCase()}</p>
                                          </div>
                                          <div>
                                            <p>URL</p>
                                            <p>{match.scrap.product_website || t('noUrl')}</p>
                                          </div>
                                          <div>
                                            <p>{t('counterfeitApprovedDate')}</p>
                                            <p>{match.counterfeit_date || t('noUrl')}</p>
                                          </div>
                                          <div>
                                            <p>{t('counterfeitApprovedBy')} </p>
                                            <p>{match.counterfeit_user || t('noUrl')}</p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                            </React.Fragment>
                          );
                        }}
                      </Query>
                    </div>
                    <Pagination count={pageCount} page={page} color='standard' onChange={(_, page) => setPage(page)} />
                  </div>
                );
              }}
            </Query>
          </div>
        }
      />
    </Routes>
  );
};
