import { Auth } from 'aws-amplify';
import axios, { AxiosError } from 'axios';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
});

axiosInstance.interceptors.request.use(
  async config => {
    try {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      config.headers['Authorization'] = `Bearer ${token}`;
    } catch (error) {}
    return config;
  },
  async (error: AxiosError) => {
    return Promise.reject(error);
  },
);
