import { Query } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes } from 'react-router-dom';

import { CounterfeitList } from 'components/CounterfeitDetection/Manage/Counterfeit/List';
import { PatternList } from 'components/CounterfeitDetection/Manage/Pattern/List';

import type { MatchCounterfeit, Pattern, Stats } from 'interfaces';

import { paths } from 'utils/paths';

import styles from './index.module.scss';

export const Manage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route path='/patterns/*' element={<PatternList />} />
      <Route path='/counterfeits/*' element={<CounterfeitList />} />
      <Route
        path='/*'
        element={
          <div className={styles.main}>
            <div>
              <h2>{t('lastPatternsAdded')}</h2>
              <div className={styles.patterns}>
                <Query<Pattern[]> query={`get_patterns_data/?page_number=1&images_by_page=4`}>
                  {({ data: patterns, loading, error }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>{error}</p>;

                    return (
                      <div>
                        {patterns &&
                          patterns.map((pattern, i) => {
                            return (
                              <Link
                                to={`/${paths.lockeoDetection}/${paths.management}/${paths.patterns}/${pattern.id}`}
                                key={`pattern-${i}`}
                                draggable={false}
                              >
                                <img src={`data:image/gif;base64,${pattern.thumbnail}`} alt={`Pattern ${i}`} draggable={false} />
                                <div className={styles.ref}>
                                  <p>{pattern.name}</p>
                                  <p>{pattern.uploaded_at}</p>
                                </div>
                              </Link>
                            );
                          })}
                      </div>
                    );
                  }}
                </Query>
                <div className={styles.manage}>
                  <Link to={`/${paths.lockeoDetection}/${paths.management}/patterns`}>{t('manage')}</Link>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <h2>{t('approvedCounterfeits')}</h2>
              <div className={styles.counterfeits}>
                <Query<MatchCounterfeit[]> query={`get_counterfeits/?counterfeit_nb=4`}>
                  {({ data: matchCounterfeits, loading, error }) => {
                    if (loading) return <p>Loading...</p>;
                    if (error) return <p>{error}</p>;

                    return (
                      <div>
                        {matchCounterfeits && matchCounterfeits.length > 0
                          ? matchCounterfeits.map((matchCounterfeit, i) => {
                              return (
                                <Link
                                  to={`/${paths.lockeoDetection}/${paths.management}/${paths.counterfeits}/${matchCounterfeit.id}`}
                                  key={`pattern-${i}`}
                                  draggable={false}
                                >
                                  <img src={`data:image/gif;base64,${matchCounterfeit.scrap.scrap}`} alt={`Pattern ${i}`} draggable={false} />
                                  <div className={styles.ref}>
                                    <p>{matchCounterfeit.scrap.product_website}</p>
                                    <p>{matchCounterfeit.counterfeit_date}</p>
                                  </div>
                                </Link>
                              );
                            })
                          : t('noCounterfeits')}
                      </div>
                    );
                  }}
                </Query>
                <div className={styles.manage}>
                  <Link to={`/${paths.lockeoDetection}/${paths.management}/counterfeits`}>{t('manage')}</Link>
                </div>
              </div>
              <hr />
            </div>
            <div>
              <div className={styles.stats}>
                <h2>{t('globalStats')}</h2>
                <div>{t('fromTo')}</div>
              </div>
              <Query<Stats> query={`get_statistics/`}>
                {({ data: v }) => {
                  return (
                    v && (
                      <div className={styles.table}>
                        <div>
                          <p>{t('numberOfPatternsAdded')}</p>
                          <p>{v.pattern_number}</p>
                        </div>
                        <div>
                          <p>{t('numberOfMatchesDetected')}</p>
                          <p>{v.matches_found_number}</p>
                        </div>
                        <div>
                          <p>{t('numberOfApprovedMatches')}</p>
                          <p>{v.match_valid_number}</p>
                        </div>
                        <div>
                          <p>{t('numberOfIncorrectMatches')}</p>
                          <p>{v.match_wrong_number}</p>
                        </div>
                        <div>
                          <p>{t('numberOfApprovedCounterfeits')}</p>
                          <p>{v.counterfeit_valid_number}</p>
                        </div>
                        <div>
                          <p>{t('averageSensibilityForApprovedCounterfeits')}</p>
                          <p>{Math.round(v.avg_valid_conterfeit_sensibility * 100) / 100}</p>
                        </div>
                        <div>
                          <p>{t('numberOfSearches')}</p>
                          <p>{v.search_number}</p>
                        </div>
                      </div>
                    )
                  );
                }}
              </Query>
              <div>
                <button>Export PDF</button>
              </div>
            </div>
          </div>
        }
      />
    </Routes>
  );
};
