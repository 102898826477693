import '@generalizers/prototype-expansion';
import '@neovision/react-query';
import { ErrorBoundary } from '@neovision/react-query';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';

import { App } from 'components/App';

import 'utils/aws-config';

import './i18n';

const root = createRoot(document.getElementById('root')!);

root.render(
  <ErrorBoundary detail={process.env.REACT_APP_MODE == 'development'}>
    <Router>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </Router>
  </ErrorBoundary>,
);
