import { request, requestOptionsMerge } from '../../../../utils/api';
import { setCache } from '../../../../utils/cache';
import { isFormEvent } from '../../../../utils/form';
import { requestLog } from '../../../../utils/log';
import { getFormData } from '../../../../utils/util';
export const createRequest = ({ endpoint: { endpoint, mode, verbosity, domain, headers, dateFormat, ...eRest }, manualUpdate, onCompleted: onCreated, forceRefresh, cacheKey, data, }) => {
    return (e, params = {}) => {
        const { method = 'POST', onRejected } = requestOptionsMerge([
            eRest,
            params,
        ]);
        let formData;
        if (isFormEvent(e)) {
            e.preventDefault();
            formData = getFormData(e.target, { dateFormat });
        }
        else
            formData = e;
        requestLog(mode, verbosity, 1, `[create][${method}]`, `${domain}/${endpoint}/`, formData);
        return request(domain, `${endpoint}/`, {
            data: formData,
            method,
            headers,
            mode,
            onRejected,
        }).then((created) => {
            const newData = [...data, created];
            if (data)
                manualUpdate?.(newData);
            setCache(cacheKey, newData);
            onCreated?.() && forceRefresh?.();
            return created;
        });
    };
};
