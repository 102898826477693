import { useKey } from '@generalizers/react-events';
import { Query } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes } from 'react-router-dom';

import { Pattern } from 'components/CounterfeitDetection/Manage/Pattern';
import { Infinit } from 'components/CounterfeitDetection/Manage/Pattern/List/Infinit';
import { Placeholder } from 'components/utils';
import { Book } from 'components/utils/Book';

import type { Pattern as PatternInterface } from 'interfaces/pattern';

import { paths } from 'utils/paths';
import { IMAGE_NB_BY_PAGE_PATTERN } from 'utils/settings';

import styles from './index.module.scss';

const localStoragePatternListPreference = 'patternListPreference';

export const PatternList: FunctionComponent = () => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(1);
  const [isBook, setIsBook] = useState(localStorage.getItem(localStoragePatternListPreference) == 'book' ?? false);

  return (
    <div className={styles.main}>
      <Routes>
        <Route path={`/:id`} element={<Pattern />} />
        <Route
          path={`/*`}
          element={
            <>
              <div className={styles.mainHeader}>
                <h1>{t('addedPatternsList')}</h1>
                <div>
                  <p>{t('patternLayout')}</p>
                  <div>
                    <button
                      className={!isBook ? styles.mainHeaderActive : undefined}
                      onClick={() => {
                        setIsBook(false);
                        localStorage.setItem(localStoragePatternListPreference, 'list');
                      }}
                    >
                      {t('quickList')}
                    </button>
                    <button
                      className={isBook ? styles.mainHeaderActive : undefined}
                      onClick={() => {
                        setIsBook(true);
                        localStorage.setItem(localStoragePatternListPreference, 'book');
                      }}
                    >
                      {t('book')}
                    </button>
                  </div>
                </div>
              </div>
              <div className={styles.mainContent}>
                <Query<number> query={`get_pattern_data_number/`}>
                  {({ data: patternCount, loading, error }) => {
                    const pageCount = Math.ceil((patternCount != 0 ? patternCount : IMAGE_NB_BY_PAGE_PATTERN) / IMAGE_NB_BY_PAGE_PATTERN);

                    useKey(
                      'down',
                      e => {
                        if (e.key == 'ArrowLeft') setPage(page - 1 > 1 ? page - 2 : 1);
                        if (e.key == 'ArrowRight') setPage(page + 1 < pageCount ? page + 2 : page);
                      },
                      [patternCount, page],
                    );

                    if (loading) return <Placeholder className={styles.placeholder} />;
                    if (error) return <p>{error}</p>;

                    return (
                      <>
                        {isBook ? (
                          <Book
                            className={styles.book}
                            pages={pageCount}
                            page={page}
                            handleSetPage={page => setPage(page)}
                            pageShow={page => {
                              return (
                                <div className={styles.patternsInBook}>
                                  <Query<PatternInterface[]> query={`get_patterns_data/?page_number=${page}&images_by_page=${IMAGE_NB_BY_PAGE_PATTERN}`}>
                                    {({ data: patterns, loading, error }) => {
                                      if (error) return <p>{error}</p>;

                                      const nb = page == pageCount ? patternCount - IMAGE_NB_BY_PAGE_PATTERN * (pageCount - 1) : IMAGE_NB_BY_PAGE_PATTERN;

                                      return (
                                        <div>
                                          <div>{page % 2 == 1 ? `${patternCount} ${t('results').toLocaleLowerCase()}` : ''}</div>
                                          <div>
                                            {loading
                                              ? [...new Array(nb)].map((_, i) => {
                                                  return (
                                                    <Placeholder
                                                      className={`${styles.patternsInBookItem} ${styles.patternsInBookItemPlaceholder}`}
                                                      key={`pattern-${i}`}
                                                      colorStart='rgba(80, 40, 36, 0.2)'
                                                      colorEnd='rgba(80, 83, 36, 0.6)'
                                                    />
                                                  );
                                                })
                                              : patterns?.map((pattern, i) => {
                                                  return (
                                                    <Link
                                                      className={styles.patternsInBookItem}
                                                      key={`pattern-${i}`}
                                                      to={`/${paths.lockeoDetection}/${paths.management}/${paths.patterns}/${pattern.id}`}
                                                      draggable={false}
                                                    >
                                                      <div>
                                                        <img src={`data:image/gif;base64,${pattern.thumbnail}`} draggable={false} />
                                                      </div>
                                                    </Link>
                                                  );
                                                })}
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Query>
                                </div>
                              );
                            }}
                          />
                        ) : (
                          <div className={styles.patterns}>
                            <Infinit patternAmount={patternCount} />
                          </div>
                        )}
                      </>
                    );
                  }}
                </Query>
              </div>
            </>
          }
        />
      </Routes>
    </div>
  );
};
