import { useKey, useMouse, useTrigger } from '@generalizers/react-events';
import { Fade, FormControl, MenuItem, Popper, Select } from '@mui/material';
import { StateFunction } from '@neovision/react-query';
import { Auth } from 'aws-amplify';
import i18next from 'i18next';
import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosHeartEmpty } from 'react-icons/io';
import { IoCloudUploadOutline, IoHomeOutline } from 'react-icons/io5';
import { SlSettings } from 'react-icons/sl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from 'states/user';

import { Header as CounterfeitDetectionHeader } from 'components/CounterfeitDetection/Header';
import { VisualSearch } from 'components/SmartCatalog/VisualSearch';
import { defaultDelay, useFilters, useView, useVisualSearch } from 'components/SmartCatalog/contexts';
import { Popup } from 'components/utils';
import { NamedTriggerButton } from 'components/utils/NamedTriggerButton';
import { Search } from 'components/utils/Search';
import { VSIcon } from 'components/utils/VSIcon';

import { isChristmas, useDebounce } from 'utils/functions';
import { Path, paths } from 'utils/paths';

import Logo from 'assets/images/Logo.png';
import LogoChristmas from 'assets/images/LogoChristmas.png';
import lockeoDetection from 'assets/images/lockeoDetection.png';
import smartCatalog from 'assets/images/smartCatalog.png';

import style from './index.module.scss';

interface HeaderProps {
  specification?: boolean;
  onVisualSearch?: (f: File) => any;
}

export const Header: FunctionComponent<HeaderProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useUser();
  const [up, setUp] = useState(false);
  const [file, setFile] = useState<File>();
  const [_, setVisualSearch] = useVisualSearch();
  const [filters, setFilters] = useFilters();
  const [text, setText] = useState('');
  const [___, setView] = useView();

  const [__, firstPath, secondPath] = location.pathname.split('/');

  useDebounce(
    () => {
      if (filters.text != text) {
        setFilters({ ...filters, text });
        setView('pattern');
        navigate(`/${paths.catalog}`);
      }
    },
    defaultDelay,
    [text],
  );

  useEffect(() => {
    setText(filters.text ?? '');
  }, [filters.text]);

  const handleSearch = (f: File) => {
    navigate(`/${paths.catalog}`);
    setUp(false);
    setVisualSearch(f);
    setFile(undefined);
  };

  let isDetection = false,
    isCatalog = false;
  if (user && Path.exists(firstPath)) {
    const p = Path.get(firstPath);
    isDetection = p == paths.lockeoDetection;
    isCatalog = p == paths.catalog;
  }

  const l = i18next.resolvedLanguage.toUpperCase();

  return (
    <header>
      <div className={style.main}>
        {isCatalog ? (
          <Link className={`${style.headerImg} ${style.headerImgCatalog}`} to={paths.catalog.toString()} draggable={false}>
            <img src={smartCatalog} alt='Catalog logo' draggable={false} />
          </Link>
        ) : (
          <Link className={style.headerImg} to={'https://textilia.fr/'} draggable={false}>
            <img src={isChristmas() ? LogoChristmas : Logo} alt='TextilIA logo' draggable={false} />
          </Link>
        )}
        <div className={style.center}>
          {isDetection ? (
            <img src={lockeoDetection} alt='Lockeo logo' draggable={false} />
          ) : (
            isCatalog && (
              <div className={style.search}>
                <Search placeholder={t('searchForPattern')} value={text} onChange={e => setText(e.target.value)} />
                <button className={style.visual} onClick={() => setUp(true)}>
                  <VSIcon className={style.visualIcon} />
                  <div className={style.visualPopup}>
                    <div>{t('visualSearchDesc')}</div>
                    <div />
                  </div>
                </button>
                <Popup className={style.popup} up={up} onClose={() => (setUp(false), setFile(undefined))}>
                  <VisualSearch onSearch={handleSearch} onExit={() => setFile(undefined)} file={file} onFileChange={f => setFile(f)} />
                </Popup>
              </div>
            )
          )}
        </div>
        <div className={style.nav}>
          <div>
            {user && (
              <>
                {user.is_staff && isDetection && (
                  <Link to={`/${paths.lockeoDetection}/${paths.settings}`} className={secondPath == paths.settings.url ? style.selected : undefined}>
                    <SlSettings />
                  </Link>
                )}
                {isCatalog && (
                  <>
                    {user.is_staff && (
                      <Link
                        className={secondPath == paths.digitalization.url ? style.selected : undefined}
                        to={`/${paths.catalog}/${paths.digitalization}`}
                      >
                        <IoCloudUploadOutline />
                      </Link>
                    )}
                    <Link className={secondPath == paths.selections.url ? style.selected : undefined} to={`/${paths.catalog}/${paths.selections}`}>
                      <IoIosHeartEmpty />
                    </Link>
                  </>
                )}

                <Link to={`/${paths.home}`} className={firstPath == paths.home.url ? style.selected : undefined}>
                  <IoHomeOutline />
                </Link>
              </>
            )}
            {user && (
              <StateFunction>
                {() => {
                  const [anchor, setAnchor] = useState<HTMLButtonElement>();
                  const [open, triggerOpen] = useTrigger();

                  useKey('down', ({ key }) => key == 'Escape' && triggerOpen(false));
                  useMouse('click', () => triggerOpen(false));

                  useEffect(() => {
                    triggerOpen(false);
                  }, [location.pathname]);

                  return (
                    <>
                      <button
                        className={style.userCircle}
                        ref={r => r && setAnchor(r)}
                        onClick={e => {
                          e.stopPropagation();
                          triggerOpen();
                        }}
                      >
                        {user.first_name.charAt(0)}
                        {user.last_name.charAt(0)}
                      </button>
                      {anchor && (
                        <Popper
                          open={true}
                          anchorEl={anchor}
                          placement={'bottom-end'}
                          sx={{ zIndex: 1000, pointerEvents: open ? 'all' : 'none' }}
                          modifiers={[
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 10],
                              },
                            },
                          ]}
                          onClick={e => e.stopPropagation()}
                        >
                          <Fade in={!!open}>
                            <div className={style.menu}>
                              {user.is_staff && (
                                <>
                                  <Link to={`/${paths.admin}`} className={firstPath == paths.admin.url ? style.selected : undefined}>
                                    {t('adminAccess')}
                                  </Link>
                                  <hr />
                                </>
                              )}
                              <Link to={`/${paths.me}`} className={firstPath == paths.me.url ? style.selected : undefined}>
                                {t('myAccount')}
                              </Link>
                              <hr />
                              <NamedTriggerButton left={'EN'} right={'FR'} onChange={lang => i18next.changeLanguage(lang.toLocaleLowerCase())} />
                              <hr />
                              <button
                                onClick={() => {
                                  if (window.confirm(t('sureDisconnect'))) {
                                    Auth.signOut().then(() => {
                                      setUser(undefined);
                                      navigate('/login');
                                    });
                                  }
                                }}
                              >
                                {t('logout')}
                              </button>
                            </div>
                          </Fade>
                        </Popper>
                      )}
                    </>
                  );
                }}
              </StateFunction>
            )}
          </div>
        </div>
      </div>
      {isDetection && <CounterfeitDetectionHeader />}
    </header>
  );
};
