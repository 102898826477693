import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Ariane } from 'components/SmartCatalog/Ariane';

import { paths } from 'utils/paths';

import button from 'scss/components/_button.module.scss';

import styles from './index.module.scss';

export const Header: FunctionComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [_, firstPath, secondPath] = location.pathname.split('/');

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        {firstPath == paths.lockeoDetection.url &&
          [paths.results, paths.matches, paths.management].map((path, i) => {
            return (
              <Link
                key={`path-${i}`}
                className={secondPath == path.url ? button['button-selected'] : button['button-primary']}
                to={`/${paths.lockeoDetection}/${path.url}`}
              >
                {t(path.url)}
              </Link>
            );
          })}
      </div>
      <div className={styles.ariane}>
        <Ariane />
      </div>
    </div>
  );
};
