import { Alert, Skeleton } from '@mui/material';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { usePage } from 'components/SmartCatalog/contexts';

import { PAGE_MAX_ELEMENTS } from 'utils/settings';

import styles from './index.module.scss';

interface ListFooterProps {
  count?: number;
  length: number;
  item: string;
}

export const ListFooter: FunctionComponent<ListFooterProps> = ({ length, count, item }) => {
  const { t } = useTranslation();
  const [page, setPage] = usePage();

  return (
    <div className={styles.main}>
      {length != 0 ? (
        count != undefined ? (
          <>
            <div className={styles.count}>
              {item} {t('from').toLocaleLowerCase()} {PAGE_MAX_ELEMENTS * (page - 1) + 1} {t('to').toLocaleLowerCase()}{' '}
              {PAGE_MAX_ELEMENTS * (page - 1) + length} {t('on')} {count}.
            </div>
            <div className={styles.pages}>
              {page != 1 && (
                <button
                  className={styles.left}
                  onClick={() => {
                    setPage(page - 1);
                    window.scrollTo(0, 0);
                  }}
                >{`\u25C4`}</button>
              )}
              <>
                {[...new Array(Math.ceil(count / PAGE_MAX_ELEMENTS)).keys()]
                  .map(n => n + 1)
                  .slice(page - 4 > 0 ? page - 5 : 0, page + 4)
                  .map((n, i) => (
                    <button
                      key={i}
                      className={page == n ? styles.current : ''}
                      onClick={() => {
                        setPage(n);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {n}
                    </button>
                  ))}
              </>
              {page != Math.ceil(count / PAGE_MAX_ELEMENTS) && (
                <button
                  className={styles.right}
                  onClick={() => {
                    setPage(page + 1);
                    window.scrollTo(0, 0);
                  }}
                >{`\u25BA`}</button>
              )}
            </div>
          </>
        ) : (
          <Skeleton variant='rectangular' />
        )
      ) : (
        <Alert severity='warning'>{t('noPatterns')}</Alert>
      )}
    </div>
  );
};
