import { useFilters } from 'components/SmartCatalog/contexts';

import type { NamedEntity } from 'interfaces/common';

export const showAmount = <T extends NamedEntity>(
  metadata: T[],
  search: string,
  key: 'books' | 'rebracks',
  showMore: number,
  firstLimit: number,
  secondLimit: number,
): [T[], boolean, number] => {
  const [filters] = useFilters();

  const shown = metadata
    .sort((b1, b2) => {
      if (filters[key].includes(b1.id)) {
        if (filters[key].includes(b2.id)) return b1.name.localeCompare(b2.name);
        return -1;
      } else if (filters[key].includes(b2.id)) return 1;
      return b1.name.localeCompare(b2.name);
    })
    .filter(b => search == '' || b.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));

  return [shown, showMore > 0, showMore == 0 ? firstLimit : secondLimit];
};
