import accountStyles from '../index.module.scss';
import type { CognitoUser } from '@aws-amplify/auth';
import { useRequest } from '@neovision/react-query';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from 'states/user';

import { passwordValidation } from 'components/App/Account';
import { Temporary } from 'components/App/Account/Register/Temporary';

import type { UserWithCompany } from 'interfaces';

import form from 'scss/components/_form.module.scss';

import styles from './index.module.scss';

const Register: FunctionComponent = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [fakeUser, setFakeUser] = useState<CognitoUser>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [_, setUser] = useUser();

  const request = useRequest();

  const handleChangePassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const error = (e: any) => {
      enqueueSnackbar(t('impossiblePasswordChange'), { variant: 'error' });
      console.error(e.message);
    };
    Auth.completeNewPassword(fakeUser, password)
      .then(_ => {
        enqueueSnackbar(t('passwordChangeSuccess'), { variant: 'success' });
        const email = localStorage.getItem('userEmail');
        email &&
          Auth.signIn(email, password)
            .then(_ => {
              request<UserWithCompany>(`get_my_profile/`)
                .then(profile => {
                  setUser(profile);
                })
                .catch(e => {
                  enqueueSnackbar(t('errorOccured'), { variant: 'error' });
                  console.error('An error occured', e);
                });
            })
            .catch(error);
      })
      .catch(error);
  };

  useEffect(() => {
    !pathname.includes('/register/challenge/') && fakeUser == undefined && navigate(`/register/challenge/${localStorage.getItem('userEmail')}`);
  }, []);

  return (
    <Routes>
      <Route path='/challenge/:email' element={<Temporary setFakeUser={setFakeUser} />} />
      <Route
        path='/changePassword'
        element={
          <div className={`${accountStyles.main} ${styles.main}`}>
            <form className={form.account} onSubmit={handleChangePassword}>
              <h2>{t('enterNewPassword')}</h2>
              <div>
                <label htmlFor='password'>{t('newPassword')}*</label>
                <input
                  placeholder={t('yourNewPassword')}
                  type='password'
                  name='password'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  title={passwordValidation.title}
                  pattern={passwordValidation.pattern}
                  required
                />
              </div>
              <button type='submit'>{t('changePassword')}</button>
            </form>
          </div>
        }
      />
    </Routes>
  );
};

export default Register;
