import { Query } from '@neovision/react-query';
import hash from 'object-hash';
import type { FunctionComponent } from 'react';
import { useMemo } from 'react';

import { ListFooter } from 'components/SmartCatalog/Catalog/ListFooter';
import { useFiltersImages, usePage, useSize } from 'components/SmartCatalog/contexts';
import type { Size } from 'components/utils/SizeIcon';
import { SolutionItem } from 'components/utils/SolutionItem';

import type { Page } from 'interfaces/page';

import { paths } from 'utils/paths';
import { PAGE_MAX_ELEMENTS } from 'utils/settings';

import styles from './index.module.scss';

export const PageList: FunctionComponent = () => {
  const [[filters]] = useFiltersImages();
  const [page] = usePage();
  const [size] = useSize();

  const col = size == 'small' ? 7 : size == 'medium' ? 5 : 3;

  const { dateBegin, dateEnd, books } = filters;

  const formData = useMemo(() => {
    return {
      filter: {
        dateBegin: dateBegin.value,
        dateEnd: dateEnd.value,
        books,
      },
      params: { page_number: page, images_by_page: PAGE_MAX_ELEMENTS },
    };
  }, [hash(filters), page]);

  return (
    <div className={styles.main}>
      <Query<{ ids: number[]; count: number }> query={`get_pages/?data=${encodeURIComponent(JSON.stringify(formData))}`} cache={0}>
        {({ data, loading }) => {
          const { ids, count } = data ?? {};

          const pages = ids ?? [...new Array(PAGE_MAX_ELEMENTS)];

          return (
            <>
              <div className={styles.pages} style={{ gridTemplateColumns: `repeat(${col}, 1fr)` }}>
                {pages.map((pageId, i) => {
                  return (
                    <SolutionItem
                      key={i}
                      url={`get_page_from_id?id=${pageId}&thumbnail=true`}
                      active={!loading}
                      link={({ book }: Page) => `/${paths.catalog}/${paths.books}/${book}/${pageId}`}
                      type={'page'}
                      uniqueLoad={i == 0}
                    />
                  );
                })}
              </div>
              <ListFooter length={pages.length} count={count} item={'Pages'} />
            </>
          );
        }}
      </Query>
    </div>
  );
};
