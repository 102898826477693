import type { FunctionComponent } from 'react';

import styles from './index.module.scss';

interface KeyboardViewerProps {
  events: KeyEvent[];
}

interface KeyEvent {
  keys: string[];
  description: string;
}

export const KeyboardViewer: FunctionComponent<KeyboardViewerProps> = ({ events }) => {
  return (
    <div data-testid='keyboardViewer' className={styles.main} onMouseDown={e => e.stopPropagation()}>
      {events.map((event, i) => {
        return (
          <div key={`keyboard-viewer-${i}`}>
            <div className={styles.mainKeys}>
              {event.keys
                .map((key, i) => {
                  return (
                    <div key={`key-${i}`} className={styles.mainKeysKey}>
                      {key}
                    </div>
                  );
                })
                .reduce<JSX.Element[]>((prev, curr, i, arr) => {
                  prev.push(curr);
                  if (i < arr.length - 1)
                    prev.push(
                      <div key={`key-concat-${i}`} className={styles.mainKeysConcat}>
                        +
                      </div>,
                    );
                  return prev;
                }, [])}
            </div>
            <hr />
            <div className={styles.mainDescription}>{event.description}</div>
          </div>
        );
      })}
    </div>
  );
};
