import { Amplify } from 'aws-amplify';

const { REACT_APP_REGION: region, REACT_APP_USER_POOL_ID: userPoolId, REACT_APP_CLIENT_ID: clientId } = process.env;

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region,
    userPoolId,
    userPoolWebClientId: clientId,
    responseType: 'token',
    oauth: {
      clientId,
      responseType: 'token',
    },
    cookieStorage: {
      domain: window.location.hostname,
      path: '/',
      expires: 1,
      secure: true,
      sameSite: 'none',
    },
  },
});
