import accountStyles from '../../index.module.scss';
import styles from '../index.module.scss';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { passwordValidation } from 'components/App/Account';

import form from 'scss/components/_form.module.scss';

export const ResetPasswordCode: FunctionComponent = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { username } = useParams();

  const handleForgotPasswordCode = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Auth.forgotPasswordSubmit(username as string, code, password)
      .then(() => {
        navigate('/login');
      })
      .catch((e: any) => {
        enqueueSnackbar(`${e.message} : ${t('reinitializationImpossible')}`);
        console.error('Reinitialisation error', e);
      });
  };

  return (
    <div className={`${accountStyles.main} ${styles.main}`}>
      <form className={form.account} onSubmit={handleForgotPasswordCode}>
        <h2>{t('reinitializeMyPassword')}</h2>
        <p>{t('emailCode')}</p>
        <div>
          <label htmlFor='code'>Code*</label>
          <input placeholder='Code' type='text' name='code' value={code} onChange={e => setCode(e.target.value)} required />
        </div>
        <div>
          <label htmlFor='password'>{t('newPassword')}*</label>
          <input
            placeholder={t('newPassword')}
            type='password'
            name='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            pattern={passwordValidation.pattern}
            title={passwordValidation.title}
            required
          />
        </div>
        <button type='submit'>{t('validateNewPassword')}</button>
      </form>
    </div>
  );
};
