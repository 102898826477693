// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d5RPe_lCX1GTlKEHxaYe{display:flex;flex-direction:column;min-width:400px;width:30%}.d5RPe_lCX1GTlKEHxaYe button[type=submit]{margin:5px}.znJisBNerm257WNRT0bg{display:flex;justify-content:space-between;margin:5px}.wAFXdOXwGvdalhpc2sUP{display:flex;flex-direction:column;justify-content:space-between;margin-left:10px}.lI5QAkKRk4IgRTyd_Co_{width:auto;min-width:auto}`, "",{"version":3,"sources":["webpack://./src/scss/form.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,SAAA,CAEA,0CACE,UAAA,CAIJ,sBACE,YAAA,CACA,6BAAA,CACA,UAAA,CAEA,sBACE,YAAA,CACA,qBAAA,CACA,6BAAA,CACA,gBAAA,CAIJ,sBACE,UAAA,CACA,cAAA","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n  min-width: 400px;\n  width: 30%;\n\n  button[type='submit'] {\n    margin: 5px;\n  }\n}\n\n.section {\n  display: flex;\n  justify-content: space-between;\n  margin: 5px;\n\n  &Deep {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    margin-left: 10px;\n  }\n}\n\n.silent {\n  width: auto;\n  min-width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `d5RPe_lCX1GTlKEHxaYe`,
	"section": `znJisBNerm257WNRT0bg`,
	"sectionDeep": `wAFXdOXwGvdalhpc2sUP`,
	"silent": `lI5QAkKRk4IgRTyd_Co_`
};
export default ___CSS_LOADER_EXPORT___;
