import { useMouse } from '@generalizers/react-events';
import type { FunctionComponent, HTMLProps } from 'react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { FaBookOpen, FaCube } from 'react-icons/fa';

import { Paper } from './Paper';
import styles from './index.module.scss';

interface BookProps extends HTMLProps<HTMLDivElement> {
  pages: number;
  page?: number;
  optimize?: boolean;
  pageShow?: (page: number) => JSX.Element;
  handleSetPage?: (page: number) => any;
}

interface PageData {
  randSkewEffect: number;
}

const optimizationSkew = 4;
const maxDegreeWhenOptimizing = 10;
const heightMovementControl = 5;
const widthMovementControl = 10;
const optimizeAt = 30;
const shownPapersAmount = 5;

/**
 * The Book component will render a book and optimize it if it is needed.
 * It will only render a few pages at a time if optimization is activated
 * There is a difference between a "page" and a "paper". Paper refers to
 * the actual page face/backface and the page only refers to a single face.
 */
export const Book: FunctionComponent<BookProps> = ({
  pages,
  page,
  optimize = pages > optimizeAt,
  pageShow,
  handleSetPage,
  className,
  style,
  ...props
}) => {
  const [paper, setPaper] = useState(1);
  const [bookRef, setBookRef] = useState<HTMLDivElement>();
  const [useMovement, setUseMovement] = useState(!optimize && (localStorage.getItem('useMovement') ?? '1') == '1');
  const [use3D, setUse3D] = useState(!optimize && (localStorage.getItem('use3D') ?? '1') == '1');
  const [optimizedArray, setOptimizedArray] = useState<number[]>([]);
  const smooth = 1;
  const maxPageDeg = optimize ? maxDegreeWhenOptimizing : pages < 2 ? 2 : pages / 2;
  const papers = Math.round(Math.round(pages / 2) == pages / 2 ? pages / 2 + 1 : pages / 2);

  const pageArray = useMemo(() => {
    return [...new Array(optimize ? optimizationSkew : papers)].map<PageData>(_ => {
      const randSkewEffect = (Math.random() - 0.5) * 2;
      return { randSkewEffect };
    });
  }, [pages]);

  useEffect(() => {
    if (page) {
      setPaper(Math.round(page / 2));
    }
  }, [page]);

  let paperArray: number[] = [];

  useEffect(() => {
    if (bookRef) {
      if (useMovement) bookRef.style.transition = 'none';
      else {
        bookRef.style.transform = `rotateX(${use3D ? 5 : 0}deg) rotateY(0deg)`;
        bookRef.style.transition = '1s';
      }
    }
  }, [bookRef, useMovement]);

  if (!optimize && useMovement) {
    useMouse(
      'move',
      e => {
        e.stopPropagation();

        if (bookRef) {
          const w = window.innerWidth;
          const h = window.innerHeight;
          const x = -((h / 2 - e.clientY) / h) * heightMovementControl * smooth + (use3D ? 5 : 0);
          const y = ((w / 2 - e.clientX) / w) * widthMovementControl * smooth;
          bookRef.style.transform = `rotateX(${x}deg) rotateY(${y}deg)`;
        }
      },
      [bookRef],
    );
  } else {
    paperArray = [...new Array(shownPapersAmount).keys()].map(v => paper - 2 + v).filter(v => v > 0);
    useEffect(() => {
      paperArray.forEach(v => {
        if (!optimizedArray.includes(v)) optimizedArray.push(v);
      });
      setOptimizedArray([...optimizedArray]);
    }, [paper]);
  }

  const handleLeft = () => {
    const p = paper > 1 ? paper - 1 : 1;
    setPaper(p);
    handleSetPage?.(p * 2 - 1);
  };

  const handleRight = () => {
    const p = paper * 2 < pages ? paper + 1 : Math.round((pages - 1) / 2);
    setPaper(p);
    handleSetPage?.(p * 2 - 1);
  };

  return (
    <div {...props} className={`${styles.main} ${className}`} style={{ ...style, perspective: use3D ? '2000px' : '10000px' }}>
      <div className={styles.mainInside} ref={r => r && setBookRef(r)}>
        <div>
          <div></div>
          <div></div>
        </div>
        <div>
          <div>
            {!optimize ? (
              pageArray.map(({ randSkewEffect }, i) => {
                const isPassed = i <= paper - 1;
                const pageDeg = (i / pages) * maxPageDeg;

                return (
                  <Paper
                    key={`page-${i}`}
                    className={styles.mainPage}
                    current={paper}
                    page={i + 1}
                    startPage={1}
                    pageShow={pageShow}
                    handleLeft={handleLeft}
                    handleRight={handleRight}
                    lastPage={pages}
                    style={{
                      transform: `${!optimize ? `skewY(${randSkewEffect}deg)` : ''} ${
                        isPassed ? `rotateY(${pageDeg}deg)` : `rotateY(${180 - (maxPageDeg - pageDeg)}deg)`
                      }`,
                    }}
                  />
                );
              })
            ) : (
              <>
                {(() => {
                  const genPages = (isPassed: boolean) => {
                    return pageArray.map(({ randSkewEffect }, i) => {
                      const pageDeg = (i / pages) * maxPageDeg;

                      return (
                        <Paper
                          className={styles.mainPage}
                          key={`page-${isPassed ? 'true' : 'false'}-${i}`}
                          page={0}
                          startPage={1}
                          style={{
                            transform: `skewY(${randSkewEffect}deg) ${
                              isPassed ? `rotateY(${pageDeg}deg)` : `rotateY(${180 - (maxPageDeg - pageDeg)}deg)`
                            }`,
                          }}
                        />
                      );
                    });
                  };

                  const handleTransitionEnd = () => {
                    const max = Math.max(...paperArray);
                    setOptimizedArray(optimizedArray.filter(p => p <= max));
                  };

                  return (
                    <>
                      {genPages(true)}
                      {optimizedArray.map((v, i) => {
                        if (!paperArray.includes(v)) return <Fragment key={`page-${i}`} />;

                        const ratio = v / papers;
                        const deg = 11 + ratio;
                        return (
                          <Paper
                            key={`page-${i}`}
                            className={styles.mainPage}
                            page={v}
                            style={{
                              transform: `rotateY(${v <= paper ? `${deg}deg` : `${169 - (1 - ratio)}deg`})`,
                            }}
                            current={paper}
                            pageShow={pageShow}
                            onTransitionEnd={handleTransitionEnd}
                            handleLeft={handleLeft}
                            handleRight={handleRight}
                            lastPage={pages}
                          />
                        );
                      })}
                      {genPages(false)}
                    </>
                  );
                })()}
              </>
            )}
          </div>
          <div>
            {!optimize &&
              (() => {
                const svg = (id: number) => {
                  return (
                    <svg viewBox='1.532 1.62561 9.468 5.374'>
                      <defs>
                        <path id={`path${id}`} d='M1.553 2.671Q6 1 11 2L11 7Q6 6 1.827 6.574 3 5 4.5 4 3.5 3 1.532 2.671' />
                        <clipPath id={`clip${id}`}>
                          <use xlinkHref={`#path${id}`} />
                        </clipPath>
                        <linearGradient id={`grad${id}`}>
                          <stop offset='0%' stopColor='#786123' />
                          <stop offset='100%' stopColor='#685113' />
                        </linearGradient>
                      </defs>
                      <g width={'100%'}>
                        <use xlinkHref={`#path${id}`} stroke={`url(#grad${id})`} clipPath={`url(#clip${id})`} fill='#685113' />
                      </g>
                    </svg>
                  );
                };

                return (
                  <>
                    <div className={styles.mainInsideConfig}>
                      <>{svg(1)}</>
                      <div
                        onClick={() => {
                          setUseMovement(!useMovement);
                          localStorage.setItem('useMovement', !useMovement ? '1' : '0');
                        }}
                      >
                        <FaBookOpen style={{ color: useMovement ? 'white' : 'grey' }} />
                      </div>
                    </div>
                    <div className={styles.mainInsideConfig}>
                      <>{svg(2)}</>
                      <div
                        onClick={() => {
                          setUse3D(!use3D);
                          localStorage.setItem('use3D', !use3D ? '1' : '0');
                        }}
                      >
                        <FaCube style={{ color: use3D ? 'white' : 'grey' }} />
                      </div>
                    </div>
                  </>
                );
              })()}
          </div>
        </div>
      </div>
    </div>
  );
};
