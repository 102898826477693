// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SIApxQzQlZp0L1gN0_QW{display:inline-block;background-color:#5f0000;border-radius:5px;border:1px solid red;color:red;padding:5px;margin:5px;transition:.3s;justify-self:center}.SIApxQzQlZp0L1gN0_QW>div{font-family:Arial}.SIApxQzQlZp0L1gN0_QW>hr{margin:10px 0;width:70%;height:2px;background-color:red}`, "",{"version":3,"sources":["webpack://./src/components/utils/ErrorBoundary/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,wBAAA,CACA,iBAAA,CACA,oBAAA,CACA,SAAA,CACA,WAAA,CACA,UAAA,CACA,cAAA,CACA,mBAAA,CAEA,0BACE,iBAAA,CAGF,yBACE,aAAA,CACA,SAAA,CACA,UAAA,CACA,oBAAA","sourcesContent":[".main {\n  display: inline-block;\n  background-color: rgb(95, 0, 0);\n  border-radius: 5px;\n  border: 1px solid red;\n  color: red;\n  padding: 5px;\n  margin: 5px;\n  transition: 0.3s;\n  justify-self: center;\n\n  > div {\n    font-family: Arial;\n  }\n\n  > hr {\n    margin: 10px 0;\n    width: 70%;\n    height: 2px;\n    background-color: red;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `SIApxQzQlZp0L1gN0_QW`
};
export default ___CSS_LOADER_EXPORT___;
