import { useMouse, useTrigger } from '@generalizers/react-events';
import { CircularProgress, Skeleton, TextField } from '@mui/material';
import { CRUD, CRUDAuto, Query, StateFunction, asFormTypes, useRequest } from '@neovision/react-query';
import saveAs from 'file-saver';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPen } from 'react-icons/fa';
import { FiShare2 } from 'react-icons/fi';
import { HiOutlineSave } from 'react-icons/hi';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { Link, useParams } from 'react-router-dom';

import { Ariane } from 'components/SmartCatalog/Ariane';
import { maxBookDate, minBookDate } from 'components/SmartCatalog/Catalog';
import { Title } from 'components/SmartCatalog/utils/Title';
import { BlackButton } from 'components/utils/Button/BlackButton';
import { HeartIcon } from 'components/utils/HeartIcon';
import { Popper } from 'components/utils/Popper';
import { SolutionItem } from 'components/utils/SolutionItem';

import type { EntityWithThumbnail, Metadata, ShareType } from 'interfaces';

import { downloadThumbnail } from 'utils/download';
import { paths } from 'utils/paths';

import styles from './index.module.scss';

const LOADING_REBRACKS = 10;

export const Rebrack: FunctionComponent = () => {
  const { t } = useTranslation();
  const { id, rebrack } = useParams();
  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  const metadataForm = asFormTypes({
    id: { pk: true, visible: false },
    name: { visible: false },
    year: {
      name: t('rebrackYear'),
      type: 'number',
      min: minBookDate,
      max: maxBookDate,
    },
    collection: {},
    cloth_type: {
      name: t('fabricType'),
    },
    location: {
      name: t('location'),
      className: styles.sub,
      sub: {
        site_location: { name: t('site') },
        building_location: { name: t('building') },
        room_location: { name: t('room') },
        shelf_location: { name: t('shelf') },
      },
    },
    other: {
      name: t('other'),
      render({ fName, oName, value, setValue }) {
        return (
          <div>
            <label htmlFor={fName}>{oName}</label>
            <TextField
              className={styles.other}
              variant='filled'
              name={fName}
              multiline
              maxRows={6}
              value={value}
              onChange={e => setValue(e.target.value)}
              inputProps={{
                id: fName,
              }}
            />
          </div>
        );
      },
    },
  });

  return (
    <CRUDAuto<Metadata>
      type={metadataForm}
      endpoints={{
        read: `api/metadata/${id}`,
        create: `api/metadata/`,
        delete: `api/metadata/${id}`,
        update: {
          endpoint: `api/metadata/${id}`,
          method: 'PATCH',
        },
      }}
      parameterType='none'
    >
      {({
        forms: { EntriesForm },
        data: {
          read: { loading: metadatasLoading },
        },
      }) => {
        const [editTitle, setEditTitle] = useState(false);
        const [popup, setPopup] = useState([false, false, false]);

        useMouse('click', () => popup.some(v => v) && setPopup([...popup.fill(false)]), [popup]);
        useMouse('click', () => setEditTitle(false), []);

        const handlePopup = (n: number) => e => {
          e.stopPropagation();
          const v = !popup[n];
          popup.fill(false);
          popup[n] = v;
          setPopup([...popup]);
        };

        return (
          <CRUD<EntityWithThumbnail>
            endpoints={{
              create: `api/rebracks`,
              read: `get_rebrack_from_id?id=${rebrack}`,
              delete: `api/rebracks`,
              update: `api/rebracks`,
            }}
            active={!metadatasLoading}
          >
            {({ read: { data: rebrack, loading: rebrackLoading }, handleUpdate }) => {
              const [download, setDownload] = useState(false);
              const [share, setShare] = useState(false);

              const { id: rebrackId, name = '', thumbnail } = rebrack ?? {};

              return (
                <div className={styles.main}>
                  <Ariane prevent={[paths.rebracks.url]}>
                    <Link to={`/${paths.catalog.url}/rebrack/${rebrackId}`}>{name}</Link>
                  </Ariane>
                  <div>
                    <div className={styles.left}>
                      <div className={styles.page}>
                        <div className={styles.pageImg}>
                          {rebrackLoading ? <Skeleton variant='rectangular' /> : <img src={`data:image/png;base64,${thumbnail}`} />}
                          <HeartIcon className={styles.selection} id={rebrackId} type={'rebrack'} />
                        </div>
                      </div>
                    </div>
                    <div className={styles.right}>
                      <Title
                        edit={editTitle}
                        value={name}
                        handleEdit={edit => {
                          setEditTitle(edit);
                        }}
                        handleSubmit={e => {
                          handleUpdate({ id: rebrack?.id, name: (e?.target as any).value }, { method: 'PATCH' });
                        }}
                      />
                      <EntriesForm>
                        {({ UpdateForm }) => {
                          return (
                            <div className={styles.name}>
                              <div>Rebrack :</div>
                              <Link to={`/${paths.catalog}/rebrack/${rebrackId}`}>{name}</Link>
                              <button className={styles.metadata} onClick={handlePopup(2)}>
                                <IoIosInformationCircleOutline />
                              </button>
                              <StateFunction>
                                {() => {
                                  const [edit, triggerEdit] = useTrigger();

                                  useMouse('click', () => triggerEdit(false), []);

                                  return (
                                    <div
                                      className={styles.form}
                                      style={{ opacity: popup[2] ? 1 : 0, pointerEvents: popup[2] ? 'all' : 'none' }}
                                      onClick={e => e.stopPropagation()}
                                    >
                                      <UpdateForm
                                        edit={edit}
                                        attributes={{ onSubmit: handlePopup(2) }}
                                        options={{ updatable: <button type='submit'>{t('update')}</button> }}
                                      />
                                      <FaPen onClick={() => triggerEdit()} />
                                    </div>
                                  );
                                }}
                              </StateFunction>
                              <hr />
                            </div>
                          );
                        }}
                      </EntriesForm>
                      <div className={styles.patterns} style={{ gridTemplateColumns: `repeat(3, 1fr)` }}>
                        <Query<number[]> query={`get_pattern_ids_from_rebrack?rebrack_id=${rebrackId}`} active={!rebrackLoading} effect={[rebrackLoading]}>
                          {({ data: patternIds = new Array(LOADING_REBRACKS).fill(undefined) }) => {
                            return (
                              <>
                                {patternIds.map((patternId, i) => {
                                  return (
                                    <SolutionItem
                                      key={i}
                                      url={`get_pattern_from_id?id=${patternId}&thumbnail=true`}
                                      active={!!patternId}
                                      link={({ id }) => `/${paths.catalog}/${paths.product}/${id}`}
                                      buttonLink={paths.catalog.toString()}
                                    />
                                  );
                                })}
                              </>
                            );
                          }}
                        </Query>
                      </div>
                      <hr />
                      <div className={styles.share}>
                        <Popper
                          poperElement={
                            <div className={styles.popper}>
                              <div>{t('share')}</div>
                              <BlackButton
                                onClick={e => {
                                  handlePopup(0)(e);
                                  request<ShareType>(`get_rebrack_zip_url?rebrack_id=${rebrackId}`).then(({ url }) => {
                                    navigator.clipboard.writeText(url).then(() => enqueueSnackbar(t('copiedShareLink'), { variant: 'success' }));
                                  });
                                }}
                              >
                                <FiShare2 />
                                <div>{t('shareRebrack')}</div>
                              </BlackButton>
                              <BlackButton
                                onClick={e => {
                                  handlePopup(0)(e);
                                  setShare(true);
                                  request<ShareType>(`get_pattern_zip_url_from_rebrack/?rebrack_id=${rebrackId}`)
                                    .then(({ url }) => {
                                      navigator.clipboard.writeText(url).then(() => enqueueSnackbar(t('copiedShareLink'), { variant: 'success' }));
                                    })
                                    .finally(() => setShare(false));
                                }}
                              >
                                <FiShare2 />
                                <div>{t('shareRebrackPatterns')}</div>
                              </BlackButton>
                            </div>
                          }
                          activation={'click'}
                          indicator={'transparent'}
                          up={popup[0]}
                          onClick={handlePopup(0)}
                        >
                          <BlackButton className={styles.shareButton}>
                            <FiShare2 />
                            <div>{t('share')}</div>
                          </BlackButton>
                        </Popper>
                        <Popper
                          poperElement={
                            <div className={styles.popper}>
                              <div>{t('download')}</div>
                              <div className={styles.section}>
                                <BlackButton
                                  onClick={e => {
                                    handlePopup(1)(e);
                                    downloadThumbnail(rebrack.thumbnail, rebrack?.name);
                                  }}
                                >
                                  <HiOutlineSave />
                                  <div>{t('downloadRebrack')}</div>
                                </BlackButton>
                                <BlackButton
                                  onClick={e => {
                                    handlePopup(1)(e);
                                    if (rebrackId != undefined && !download) {
                                      setDownload(true);
                                      request(`get_pattern_zip_from_rebrack?rebrack_id=${rebrackId}`, {
                                        responseType: 'blob',
                                      })
                                        .then(r => {
                                          saveAs(r, rebrack?.name + '_motifs.zip');
                                        })
                                        .finally(() => setDownload(false));
                                    }
                                  }}
                                >
                                  <HiOutlineSave />
                                  <div>{t('downloadAllPatternsRebrack')}</div>
                                </BlackButton>
                              </div>
                            </div>
                          }
                          activation={'click'}
                          indicator={'transparent'}
                          up={popup[1]}
                          onClick={handlePopup(1)}
                        >
                          <BlackButton className={styles.shareButton}>
                            <HiOutlineSave />
                            <div>{t('download')}</div>
                            {download && <CircularProgress style={{ width: '20px', height: '20px' }} />}
                          </BlackButton>
                        </Popper>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </CRUD>
        );
      }}
    </CRUDAuto>
  );
};
