import { useMouse } from '@generalizers/react-events';
import { Skeleton } from '@mui/material';
import { Query, useRequest } from '@neovision/react-query';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiRefresh, BiZoomIn, BiZoomOut } from 'react-icons/bi';
import { FiShare2 } from 'react-icons/fi';
import { HiOutlineEye, HiOutlineSave } from 'react-icons/hi';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { LuContrast } from 'react-icons/lu';
import { Link, useParams } from 'react-router-dom';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { Title } from 'components/SmartCatalog/utils/Title';
import { BlackButton } from 'components/utils/Button/BlackButton';
import { HeartIcon } from 'components/utils/HeartIcon';
import { VSIcon } from 'components/utils/VSIcon';

import type { EntityWithThumbnail, ExtractedPattern, Metadata, ShareType } from 'interfaces';
import type { Pattern } from 'interfaces/pattern/Pattern';
import { PatternType } from 'interfaces/pattern/Pattern';

import { formatDate } from 'utils/date';
import { downloadThumbnail } from 'utils/download';
import { riter } from 'utils/functions';
import { paths } from 'utils/paths';

import ActionButton from './Components/ActionButton';
import PatternFile from './Components/PatternFile';
import ToReannotateButton from './Components/ToReannotateButton';
import style from './index.module.scss';

const REBRACKS_PER_PAGE = 4;
const REBRACK_LIMIT = REBRACKS_PER_PAGE * 4;

const isStandardPattern = (type: PatternType) => [PatternType.DetectionPattern, PatternType.Pattern].includes(type);

export const Product: FunctionComponent = () => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const XScaleValue = useRef<HTMLDivElement | null>(null);
  const YScaleValue = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();
  const { id } = useParams();
  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Query<ExtractedPattern> query={`get_pattern_from_id?id=${id}`}>
      {({ data: product, loading: patternLoading, manualUpdate }) => {
        const [edit, setEdit] = useState(false);
        const [open, setOpen] = useState(false);

        const [rulerMode, setRulerMode] = useState<string>('light');

        const toggleRulerMode = () => {
          rulerMode === 'light' ? setRulerMode('dark') : setRulerMode('light');
        };

        const {
          id: productId,
          name,
          thumbnail,
          uploaded_at,
          metadata,
          page: pageId,
          rebrack: rebrackId,
          type,
          uploaded_files: files,
          height_in_cm: heightInCm,
          width_in_cm: widthInCm,
        } = product ?? {};

        const size = Math.max(widthInCm, heightInCm);
        const rulerSizeInCm = (size * 30) / 100;
        const displayRulers = (heightInCm != null && heightInCm != 0) || (widthInCm != null && widthInCm != 0);

        useMouse('click', () => {
          setEdit(false);
          setOpen(false);
        });

        return (
          <Query<Metadata | undefined> query={`api/metadata/${metadata}`} active={!patternLoading} ignore={isStandardPattern(type)}>
            {({ data: metadata, loading: metadataLoading }) => {
              const {
                id: metadataId,
                name: metadataName,
                building_location,
                cloth_type,
                collection,
                other,
                room_location,
                shelf_location,
                site_location,
                year,
              } = metadata ?? {};

              const metadataComp = (site_location || building_location || room_location || shelf_location) && (
                <div>
                  <label>Localisation : </label>
                  {site_location && (
                    <div>
                      <label>{t('site')} : </label>
                      <div>{site_location}</div>
                    </div>
                  )}
                  {building_location && (
                    <div>
                      <label>{t('building')} : </label>
                      <div>{building_location}</div>
                    </div>
                  )}
                  {room_location && (
                    <div>
                      <label>{t('room')} : </label>
                      <div>{room_location}</div>
                    </div>
                  )}
                  {shelf_location && (
                    <div>
                      <label>{t('shelf')} : </label>
                      <div>{shelf_location}</div>
                    </div>
                  )}
                </div>
              );

              const metadataTypeComp = (
                <>
                  {year && (
                    <div>
                      <label>{t('year')} : </label>
                      <div>{year}</div>
                    </div>
                  )}
                  {collection && (
                    <div>
                      <label>Collection : </label>
                      <div>{collection}</div>
                    </div>
                  )}
                  {cloth_type && (
                    <div>
                      <label>{t('fabricType')} : </label>
                      <div>{cloth_type}</div>
                    </div>
                  )}
                </>
              );

              return (
                <Query<EntityWithThumbnail | undefined>
                  query={`get_${type == PatternType.PagePattern ? 'page' : 'rebrack'}_from_id?id=${type == PatternType.PagePattern ? pageId : rebrackId}`}
                  active={!metadataLoading && !patternLoading}
                  ignore={isStandardPattern(type)}
                >
                  {({ data: pageOrRebrack, loading, manualUpdate: pageOrRebrackManualUpdate }) => {
                    return (
                      <div className={style.main}>
                        <div className={style.left}>
                          <div className={style.pattern}>
                            {patternLoading ? (
                              <Skeleton variant='rectangular' />
                            ) : (
                              <div className={style.image}>
                                {displayRulers && (
                                  <div>
                                    <div className={`${style.Yscale} ${rulerMode === 'dark' && style.darkBg}`}></div>
                                    <div className={`${style.YscaleValue} ${rulerMode === 'dark' && style.dark}`} ref={YScaleValue}>
                                      {rulerSizeInCm.toFixed(1)} cm
                                    </div>
                                  </div>
                                )}
                                <div className={style.contrastToggleIcon}>
                                  <LuContrast onClick={() => toggleRulerMode()} className={`${rulerMode === 'dark' && style.dark}`} />
                                </div>
                                {displayRulers && (
                                  <div>
                                    <div className={`${style.Xscale} ${rulerMode === 'dark' && style.darkBg}`}></div>
                                    <div className={`${style.XscaleValue} ${rulerMode === 'dark' && style.dark}`} ref={XScaleValue}>
                                      {rulerSizeInCm.toFixed(1)} cm
                                    </div>
                                  </div>
                                )}
                                <TransformWrapper
                                  onTransformed={e => {
                                    if (YScaleValue.current && XScaleValue.current) {
                                      const scale = rulerSizeInCm / e.state.scale;

                                      YScaleValue.current.innerText = `${scale.toFixed(1)} cm`;
                                      XScaleValue.current.innerText = `${scale.toFixed(1)} cm`;
                                    }
                                  }}
                                  ref={transformComponentRef}
                                >
                                  {({ resetTransform, zoomIn, zoomOut }) => {
                                    return (
                                      <Fragment>
                                        <div className={style.tools}>
                                          <BiRefresh className={`${style.icon} ${rulerMode === 'dark' && style.dark}`} onClick={() => resetTransform()} />
                                          <BiZoomIn className={`${style.icon} ${rulerMode === 'dark' && style.dark}`} onClick={() => zoomIn()} />
                                          <BiZoomOut className={`${style.icon} ${rulerMode === 'dark' && style.dark}`} onClick={() => zoomOut()} />
                                        </div>
                                        <TransformComponent wrapperClass={style.zoom}>
                                          <img src={`data:image/png;base64,${thumbnail}`} draggable={false} />
                                        </TransformComponent>
                                      </Fragment>
                                    );
                                  }}
                                </TransformWrapper>
                                <div className={style.patternHover}>
                                  <Link to={`/${paths.catalog}/search/${productId}`}>
                                    <VSIcon />
                                  </Link>
                                </div>
                                <div className={style.patternSelection}>
                                  <HeartIcon id={productId} />
                                </div>
                              </div>
                            )}
                          </div>
                          {type == PatternType.RebrackPattern && (
                            <Query<number[]> query={`get_pattern_ids_from_rebrack?rebrack_id=${rebrackId}`}>
                              {({ data: patternIds, loading: rebrackIdsLoading }) => {
                                const ids = (patternIds ?? [...new Array(4)])
                                  .filter(p => p == undefined || (id != undefined && p != parseInt(id)))
                                  .filter((_, i) => i < REBRACK_LIMIT);
                                const [page, setPage] = useState(0);

                                const max = Math.ceil(ids.length / 4);

                                const handlePage = (n: number) => () => {
                                  if (n >= 0 && n < max) setPage(n);
                                };

                                return (
                                  <div className={style.similar}>
                                    <div className={style.arr}>
                                      {page != 0 && (
                                        <div onClick={handlePage(page - 1)}>
                                          <IoIosArrowBack />
                                        </div>
                                      )}
                                    </div>
                                    <div className={style.patterns}>
                                      {ids
                                        .reduce<number[][]>((prev, curr, i) => {
                                          // Create a mini page at the bottom split into REBRACKs_PER_PAGE
                                          const p = Math.floor(i / REBRACKS_PER_PAGE);
                                          if (prev.length == p) prev.push([curr]);
                                          else prev[p].push(curr);
                                          return prev;
                                        }, [])
                                        .map((patternIdBatch, i) => {
                                          return (
                                            <div
                                              key={i}
                                              className={style.patternBatch}
                                              style={{ transform: `translateX(${i < page ? '-100%' : i > page ? '100%' : '0'})` }}
                                            >
                                              {patternIdBatch.map((patternId, j) => {
                                                return (
                                                  <Query<Pattern>
                                                    key={j}
                                                    query={`get_pattern_from_id?id=${patternId}&thumbnail=true`}
                                                    active={!rebrackIdsLoading}
                                                  >
                                                    {({ data: pattern, loading: rebrackPatternLoading }) => {
                                                      return (
                                                        <div>
                                                          <div>
                                                            {rebrackPatternLoading ? (
                                                              <Skeleton variant='rectangular' />
                                                            ) : (
                                                              <Link to={`/${paths.catalog}/${paths.product}/${pattern.id}`}>
                                                                <img src={`data:image/png;base64,${pattern.thumbnail}`} draggable={false} />
                                                              </Link>
                                                            )}
                                                          </div>
                                                        </div>
                                                      );
                                                    }}
                                                  </Query>
                                                );
                                              })}
                                            </div>
                                          );
                                        })}
                                    </div>
                                    <div className={style.arr}>
                                      {page != max - 1 && (
                                        <div onClick={handlePage(page + 1)}>
                                          <IoIosArrowForward />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              }}
                            </Query>
                          )}
                        </div>
                        <div className={style.right}>
                          <div className={style.rightTexts}>
                            {patternLoading ? (
                              riter(7, <Skeleton variant='rectangular' sx={{ margin: 2 }} />)
                            ) : (
                              <>
                                <Title
                                  value={name}
                                  edit={edit}
                                  handleEdit={edit => {
                                    setEdit(edit);
                                  }}
                                  handleSubmit={e => {
                                    const name = e.target['value'];
                                    request('rename_pattern/', { method: 'PATCH', data: { id: productId, name } }).then(() =>
                                      manualUpdate({ ...product, name }),
                                    );
                                  }}
                                />
                                <hr />
                                <div className={style.rightInfo}>
                                  <div>
                                    <label>{type == PatternType.PagePattern ? t('book') : isStandardPattern(type) ? t('pattern') : 'Rebrack'} : </label>
                                    <div>
                                      <label>{t('name')} : </label>
                                      <div>
                                        {isStandardPattern(type) ? (
                                          <div>{name}</div>
                                        ) : (
                                          <Link
                                            to={`/${paths.catalog}/${type == PatternType.PagePattern ? 'book' : 'rebracks'}/${
                                              type == PatternType.RebrackPattern ? `${metadataId}/${rebrackId}` : metadataId
                                            }`}
                                          >
                                            {type == PatternType.RebrackPattern ? pageOrRebrack?.name : metadataName}
                                          </Link>
                                        )}
                                        {type == PatternType.RebrackPattern && (
                                          <button
                                            onClick={e => {
                                              e.stopPropagation();
                                              setOpen(true);
                                            }}
                                          >
                                            <HiOutlineEye />
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                    {type == PatternType.PagePattern && (
                                      <div>
                                        <label>Page : </label>
                                        <div>
                                          {!loading && <Link to={`/catalog/books/${metadata?.id}/${pageOrRebrack?.id}`}>{pageOrRebrack?.name}</Link>}
                                          <button
                                            onClick={e => {
                                              e.stopPropagation();
                                              setOpen(true);
                                            }}
                                          >
                                            <HiOutlineEye />
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                    {!isStandardPattern(type) && metadataTypeComp}
                                  </div>
                                  {!isStandardPattern(type) && metadataComp}
                                  <div className={style.pageImg} style={{ opacity: open ? 1 : 0, pointerEvents: open ? 'all' : 'none' }}>
                                    {!loading && !isStandardPattern(type) && <img src={`data:image/png;base64,${pageOrRebrack?.thumbnail}`} />}
                                  </div>
                                </div>
                                {uploaded_at && (
                                  <div>
                                    <label>{t('uploadDate')} : </label>
                                    <div>{formatDate(uploaded_at)}</div>
                                  </div>
                                )}
                                <PatternFile
                                  files={files}
                                  onDelete={deletedFile => {
                                    const newFiles = files.filter(file => file.id !== deletedFile.id);
                                    manualUpdate({ ...product, uploaded_files: newFiles });
                                  }}
                                  onUpload={file => manualUpdate({ ...product, uploaded_files: [...files, file] })}
                                />
                              </>
                            )}
                          </div>
                          <hr />
                          <div className={style.rightUtils}>
                            <ActionButton
                              onClick={() => {
                                request<ShareType>(`get_pattern_zip_url?pattern_id=${productId}`).then(({ url }) => {
                                  navigator.clipboard.writeText(url).then(() => enqueueSnackbar(t('copiedShareLink'), { variant: 'success' }));
                                });
                              }}
                            >
                              <FiShare2 />
                              <div>{t('share')}</div>
                            </ActionButton>
                            <ActionButton
                              onClick={() => {
                                downloadThumbnail(thumbnail, name, PatternType.PagePattern == type ? '.png' : '.jpg');
                              }}
                            >
                              <HiOutlineSave />
                              <div>{t('download')}</div>
                            </ActionButton>
                            {type == PatternType.PagePattern && pageId && pageOrRebrack?.annotation_status !== undefined && (
                              <ToReannotateButton
                                onSuccess={status => pageOrRebrackManualUpdate({ ...pageOrRebrack, annotation_status: status })}
                                status={pageOrRebrack?.annotation_status}
                                pageId={pageId}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};
