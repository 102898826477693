import { Admin } from 'components/App/Account/Admin';
import { Login } from 'components/App/Account/Login';
import { ResetPassword } from 'components/App/Account/ResetPassword';
import { User } from 'components/App/Account/User';

export const passwordValidation = {
  pattern: '(?=^.{10,}$)(?![.\\n])(?=.*[!@#$%^&*()+\\-=\\[\\]{};\':"\\|<>\\/?])(?=.*\\d)(?=.*[A-Z])(?=.*[a-z]).*$',
  title: 'Au moins 1 caractère spécial, une minuscule, une majuscule et un nombre. La taille doit être de 10 caractères ou plus',
};

export { Admin, User, Login, ResetPassword };
