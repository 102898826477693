import type { FunctionComponent, ReactNode } from 'react';
import { isValidElement } from 'react';

import styles from './index.module.scss';

type PopupChildrenFunction = (up: boolean) => ReactNode;

interface PopupProps {
  up: boolean;
  onClose: () => void;
  children: ReactNode | PopupChildrenFunction;
  className?: string;
}

export const Popup: FunctionComponent<PopupProps> = ({ up, onClose, children, className }) => {
  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div
      data-testid='popup'
      className={`${styles.main} ${up ? styles.mainUp : styles.mainDown} ${className}`}
      onMouseDown={handleClose}
      style={{ opacity: up ? 1 : 0 }}
    >
      {isValidElement(children) ? children : (children as PopupChildrenFunction)(up)}
    </div>
  );
};
