import { useKey, useMouse } from '@generalizers/react-events';
import { Popover, Skeleton, debounce } from '@mui/material';
import { CRUD, useRequest } from '@neovision/react-query';
import { FunctionComponent, useCallback } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegPlusSquare } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { BlackButton } from 'components/utils/Button/BlackButton';

import type { ElementType, SelectionIsIn } from 'interfaces/Selection';

import { paths } from 'utils/paths';

import { ReactComponent as SearchIcon } from 'assets/images/magnifyingGlass.svg';

import styles from './index.module.scss';

interface MenuProps {
  element?: Element;
  open: boolean;
  onClose: () => any;
  type?: ElementType;
  id: number;
}

export const SelectionMenu: FunctionComponent<MenuProps> = ({ element, onClose, open, id, type = 'pattern' }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [newSelection, setNewSelection] = useState<string>();
  const [stayUp, setStayUp] = useState(false);
  const request = useRequest();
  const [search, setSearch] = useState('');

  const close = useCallback(
    (force = false) =>
      () => {
        if (force || !stayUp) {
          setNewSelection(undefined);
          onClose();
          setStayUp(false);
        }
      },
    [stayUp],
  );

  const closeDebounce = useCallback(
    debounce(() => {
      if (newSelection == undefined) close()();
    }, 300),
    [newSelection],
  );

  useMouse('down', close(true));

  return (
    <Popover
      open={open}
      anchorEl={element}
      onClose={close()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      style={{ pointerEvents: 'none' }}
      onKeyDown={e => ((e.key == 'Enter' && newSelection == undefined) || e.key == 'Escape') && close(true)()}
    >
      <CRUD<SelectionIsIn[]>
        endpoints={{
          read: `get_selections_and_${type}_is_in/?${type}=${id}`,
          create: 'api/selections',
          delete: 'api/selections',
          update: 'api/selections',
        }}
        cache={0}
      >
        {({ read, handleCreate, manualUpdate }) => {
          const selections = read.data ?? new Array(10).fill(undefined);
          const isInProp = `${type}_is_in`;

          return (
            <>
              <div
                className={styles.main}
                style={{ pointerEvents: 'all' }}
                onMouseDown={e => {
                  e.stopPropagation();
                  setNewSelection(undefined);
                  setStayUp(true);
                }}
                onMouseLeave={closeDebounce}
              >
                <div className={styles.title}>{t('saveInYourSelections')}</div>
                <div className={styles.list}>
                  {selections
                    .filter(s => search == '' || s?.name.toLowerCase().includes(search.toLowerCase()))
                    .sort((s1, s2) => {
                      if (s1 == undefined || s2 == undefined) return 0;
                      if (s1[isInProp]) {
                        if (s2[isInProp]) return s1.name.localeCompare(s2.name);
                        return -1;
                      } else if (s2[isInProp]) return 1;
                      return s1.name.localeCompare(s2.name);
                    })
                    .map((selection, i) => {
                      return (
                        <div key={i}>
                          {!selection ? (
                            <Skeleton variant='rectangular' />
                          ) : (
                            <div className={styles.selection}>
                              <input
                                id={`selection-${i}`}
                                type={'checkbox'}
                                checked={selection[isInProp] ?? false}
                                onChange={_ => {
                                  request(`${selection[isInProp] ? `remove_${type}_from_selection/` : `add_${type}_to_selection/`}`, {
                                    method: selection[isInProp] ? 'DELETE' : 'PUT',
                                    data: { selection: selection.id, [type]: id },
                                  })
                                    .then(() => {
                                      const index = selections.findIndex(s => s == selection);
                                      selections[index][isInProp] = !selection[isInProp];
                                      manualUpdate([...selections]);
                                    })
                                    .catch();
                                }}
                              />
                              <label htmlFor={`selection-${i}`}>{selection.name}</label>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
                <div className={styles.searchSelection}>
                  <input placeholder={t('searchSelection')} value={search} onChange={e => setSearch(e.target.value)} />
                  <SearchIcon />
                </div>
                <div className={styles.add}>
                  {newSelection != undefined ? (
                    <form
                      onMouseDown={e => e.stopPropagation()}
                      onSubmit={e => {
                        handleCreate(e).then(selection => {
                          request(`add_${type}_to_selection/`, {
                            method: 'PUT',
                            data: { selection: selection.id, [type]: id },
                          }).then(() => {
                            setNewSelection(undefined);
                            manualUpdate([...selections, { id: selection.id, name: selection.name, [isInProp]: true }]);
                          });
                        });
                      }}
                    >
                      <input name={`name`} autoFocus={true} />
                    </form>
                  ) : (
                    <BlackButton onClick={() => setNewSelection('')}>
                      <FaRegPlusSquare />
                      <div>{t('createNewSelection')}</div>
                    </BlackButton>
                  )}
                </div>
                <BlackButton className={styles.selections} onClick={() => navigate(`/${paths.catalog}/${paths.selections}`)}>
                  {t('accessMySelections')}
                </BlackButton>
              </div>
            </>
          );
        }}
      </CRUD>
    </Popover>
  );
};
