import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import form from '../../../../scss/form.module.scss';
export const getDeleteForm = ({ data, handleDelete, className: classNameParam, pkName, value, ...params }) => {
    return ({ className: classNameProp, children, ...props }) => {
        const { attributes, options = {} } = Object.merge(params, props);
        const { deletable = _jsx("button", { type: "submit", children: "Delete" }) } = options;
        return (_jsxs("form", { ...attributes, className: `${form.silent} ${attributes?.className}`, onSubmit: (e) => {
                attributes?.onSubmit?.(e);
                handleDelete?.(e, { name: pkName });
            }, children: [_jsx("input", { id: pkName, name: pkName, defaultValue: value, style: { display: 'none' } }), _jsx(_Fragment, { children: children ||
                        ((typeof deletable != 'boolean' || deletable) && deletable) })] }));
    };
};
