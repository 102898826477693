import { createContext, useContext } from 'react';

import type { UserWithCompany } from 'interfaces';

const userContext = createContext<[UserWithCompany | undefined, (user?: UserWithCompany) => void]>([undefined, () => {}]);

export const useUser = () => {
  const context = useContext(userContext);

  if (!context) throw new Error(`useUser must be used within a UserProvider`);
  return context;
};

export const UserProvider = userContext.Provider;
