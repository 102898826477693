import type { ButtonHTMLAttributes, FunctionComponent } from 'react';

import styles from './index.module.scss';

type BlackButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const BlackButton: FunctionComponent<BlackButtonProps> = ({ children, className, ...props }) => {
  return (
    <button {...props} className={`${styles.main} ${className}`}>
      {children}
    </button>
  );
};
