import type { StandardLonghandProperties } from '../../../../node_modules/csstype/index';
import { useMouse } from '@generalizers/react-events';
import type { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import styles from './index.module.scss';

interface PopperProps extends HTMLAttributes<HTMLDivElement> {
  poperElement: JSX.Element;
  children: ReactNode;
  activation?: 'hover' | 'click';
  indicator?: StandardLonghandProperties['color'];
  className?: string;
  up?: boolean;
  placement?: 'top' | 'bottom';
  offset?: number;
}

export const Popper: FunctionComponent<PopperProps> = ({
  children,
  poperElement,
  className,
  activation = 'hover',
  indicator = '#000',
  up = false,
  onMouseOver,
  onMouseLeave,
  onClick,
  offset = 5,
  placement = 'top',
  ...props
}) => {
  const [isUp, setIsUp] = useState(up);

  const handleActivate = (type: PopperProps['activation'], activate: boolean) => {
    activation == type && setIsUp(activate);
  };

  useMouse('click', () => handleActivate('click', false), [activation]);

  useEffect(() => setIsUp(up), [up]);

  const css = Object.merge<any>({ [`${placement}`]: (0).px }, isUp ? { opacity: 1 } : { pointerEvents: 'none', opacity: 0 });

  return (
    <div data-testid='popper' className={styles.main}>
      <div
        onMouseOver={e => {
          handleActivate('hover', true);
          onMouseOver?.(e);
        }}
        onMouseLeave={e => {
          onMouseLeave?.(e);
          handleActivate('hover', false);
        }}
        onClick={e => {
          e.stopPropagation();
          handleActivate('click', !isUp);
          onClick?.(e);
        }}
        {...props}
      >
        {children}
      </div>
      <div style={css}>
        <div
          style={{
            backgroundColor: indicator,
            border: indicator == 'transparent' ? '0 solid transparent' : '',
            pointerEvents: indicator == 'transparent' ? 'none' : 'all',
          }}
        ></div>
        <div
          className={className}
          onClick={e => {
            e.stopPropagation();
          }}
          style={{ transform: placement == 'top' ? 'translateY(-100%)' : undefined }}
        >
          {poperElement}
        </div>
      </div>
    </div>
  );
};
