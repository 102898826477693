import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import form from '../../../scss/form.module.scss';
import { createFormObjectRecursive, extractInsert, } from '../../../utils/form';
import { requestWarn } from '../../../utils/log';
import { useEffect, useState } from 'react';
export const Form = ({ formObject, formObjectOptions, idValue, keys, pkName, typeKey, dataLine, data, fName = idValue ? `${typeKey}-${idValue}` : typeKey, type, }) => {
    const lineTypeKey = dataLine?.[typeKey];
    const [value, _setValue] = useState(lineTypeKey ?? '');
    const { className, name, visible = true, pk, select, sub, render, onValueChanged, ...other } = formObject;
    const { method, formOptions = {} } = formObjectOptions;
    const { insert } = formOptions;
    const { before = [], after = [] } = insert ?? {};
    const { required } = other;
    const oName = name ?? typeKey ?? fName;
    const setValue = (v) => {
        _setValue(v);
        onValueChanged?.(v);
    };
    useEffect(() => {
        setValue(lineTypeKey ?? '');
    }, [dataLine, typeKey]);
    if (render && method != 'read')
        return (_jsx(_Fragment, { children: render({
                ...formObject,
                oName,
                fName,
                value,
                setValue,
                ...formObjectOptions,
            }) }));
    if ((type == 'create' && sub) ||
        (type == 'update' && !keys.includes(typeKey))) {
        if (type == 'update' && !sub) {
            requestWarn('development', 0, 0, `type key "${typeKey}" doesn't exist on received data`, dataLine);
            return _jsx(_Fragment, {});
        }
        return (_jsxs("div", { className: `${className}`, style: { display: 'flex', flexDirection: 'column' }, children: [_jsx("div", { className: `${form.section}`, children: _jsx("label", { children: oName }) }), _jsx("div", { ...other, className: `${form.sectionDeep}`, children: createFormObjectRecursive(sub, { ...formObjectOptions, noSubmit: true }, data, pkName) })] }));
    }
    if (type == 'create') {
        if (pk)
            return _jsx(_Fragment, {});
        return (_jsxs(_Fragment, { children: [extractInsert(before, fName), _jsxs("div", { className: `${form.section} ${className}`, children: [_jsxs("label", { htmlFor: fName, children: [oName?.capitalize(), " ", required && '*'] }), select ? (_jsx("select", { id: fName, name: fName, children: select.options.map((option, i) => {
                                return (_jsx("option", { value: option.value ?? option.text, children: option.text ?? option.value }, i));
                            }) })) : (_jsx("input", { ...other, id: fName, name: fName }))] }), extractInsert(after, fName)] }));
    }
    if (!visible)
        return (_jsx("input", { ...other, className: className, style: { display: 'none' }, id: `${fName}`, name: `${fName}`, defaultValue: value }));
    return (_jsxs(_Fragment, { children: [extractInsert(before, typeKey), _jsxs("div", { className: `${form.section} ${className}`, children: [_jsxs("label", { htmlFor: fName, children: [oName.capitalize(), " ", required && '*'] }), _jsx(_Fragment, { children: method == 'read' ? (_jsx("div", { children: value })) : select ? (_jsx("select", { id: fName, name: fName, defaultValue: value, children: select.options.map((option, i) => {
                                return (_jsx("option", { value: option.value ?? option.text, children: option.text ?? option.value }, i));
                            }) })) : (_jsx("input", { ...other, id: `${fName}`, name: `${fName}`, value: value, onChange: (e) => setValue(e.target.value) })) })] }), extractInsert(after, typeKey)] }));
};
