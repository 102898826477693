import { useQueryOptions } from '../../components/utils/QueryOptionsProvider';
import { request, } from '../../utils/api';
import { useEffect, useMemo, useState } from 'react';
export const useRequest = (rRest = {
    method: 'GET',
}, dependencies) => {
    const [ignore, setIgnore] = useState(true);
    const [controller, setController] = useState(new AbortController());
    const [{ loader, ...qRest }] = useQueryOptions();
    useEffect(() => {
        if (ignore)
            return () => {
                setIgnore(false);
            };
        return () => {
            controller.abort('The user aborted a request.');
            setController(new AbortController());
        };
    }, [controller, ignore]);
    return useMemo(() => {
        const merged = Object.merge(rRest, qRest, {
            onRejected: (rej) => {
                rRest.onRejected?.(rej);
                qRest.onRejected?.(rej);
            },
            headers: () => {
                return Promise.all([rRest.headers?.(), qRest.headers?.()]).then(([t1 = {}, t2 = {}]) => Object.merge({}, t1, t2));
            },
        });
        return (path, options = { method: 'GET' }) => {
            const { domain, signal, ...returnMerged } = Object.merge(merged, options, {
                onRejected: (rej) => {
                    options.onRejected?.(rej);
                    merged.onRejected?.(rej);
                },
                headers: () => {
                    return Promise.all([options.headers?.(), merged.headers?.()]).then(([t1 = {}, t2 = {}]) => Object.merge({}, t1, t2));
                },
            });
            return request(domain, path, {
                ...returnMerged,
                signal: signal ?? controller?.signal,
            });
        };
    }, [...(dependencies ?? []), controller]);
};
