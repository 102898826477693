import admin from '../index.module.scss';
import type { CRUDObject } from '@neovision/react-query';
import { CRUD, Query } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCopy } from 'react-icons/fa';

import { insert } from 'components/App/Account/Admin';
import { Popup } from 'components/utils';
import { Dropdown } from 'components/utils/Dropdown';

import type { Company, UserWithCompanyName } from 'interfaces';
import type { UserToken } from 'interfaces/user/UserToken';

import { isActive, isCompanyActive, phonePattern } from 'utils/checks';
import { formatDate } from 'utils/date';

import styles from './index.module.scss';

interface CompanyBarProps {
  handleSelect: () => any;
  companies: Company[];
  company: Company;
  crud: CRUDObject<Company[]>;
  i: number;
  selected?: number;
  userRequests: number;
  updateRequest: () => any;
}

export const CompanyBar: FunctionComponent<CompanyBarProps> = ({ handleSelect, company, companies, crud, selected, i, updateRequest, userRequests }) => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<number>();
  const [popupCompany, setPopupCompany] = useState<boolean>(false);
  const [detection, setDetection] = useState(company?.detection_subscription_end);
  const [protection, setProtection] = useState(company?.protection_subscription_end);
  const [catalog, setCatalog] = useState(company?.catalog_subscription_end);
  const [popupAddUser, setPopupAddUser] = useState<boolean>(false);
  const [edit, setEdit] = useState<number>();

  const handleEditCompany = (e: React.MouseEvent<HTMLElement>, edit: number) => {
    e.stopPropagation();
    setEdit(edit);
    setPopupCompany(true);
  };

  const companyActive = isCompanyActive(company);

  return (
    <div>
      <div className={styles.main} onClick={() => handleSelect()}>
        <div>
          <h3>{company.name.toUpperCase()}</h3>
          <div>
            {!companyActive && (
              <button
                className={styles.inactive}
                type='submit'
                onClick={e => {
                  e.stopPropagation();
                  if (confirm(`${t('deleteTheCompany')} ${company.name} ?`)) crud.handleDelete({ id: company.id });
                }}
              >
                {t('delete')}
              </button>
            )}
            <button
              onClick={e => {
                handleEditCompany(e, company.id);
              }}
            >
              {t('change')}
            </button>
          </div>
        </div>
        <Popup up={edit == company.id && popupCompany} onClose={() => setPopupCompany(false)}>
          <form
            className={styles.popup}
            onMouseDown={e => e.stopPropagation()}
            onClick={e => e.stopPropagation()}
            onSubmit={e => crud.handleUpdate(e, { name: 'id' }).then(() => setPopupCompany(false))}
          >
            <h2>{`${t('updateCompany')} ${company.name}`}</h2>
            <input name={'id'} type={'number'} value={company.id} style={{ display: 'none' }} readOnly />
            <input name={'name'} type={'hidden'} value={company.name} />
            {insert({ name: 'address', title: t('address'), defaultValue: company.address, required: true })}
            {insert({ name: 'siret', title: 'Siret', defaultValue: company.siret, required: true })}
            <div className={admin.formSubscribtion}>
              <label>{t('subscription')}</label>
              <div>
                <label htmlFor={'protection_subscription_end'}>
                  <input type={'checkbox'} readOnly checked={isActive(protection)} />
                  <div>{t('lockeoProtectionSolution')}</div>
                </label>
                <div>
                  <div>{t('until')}</div>
                  <input
                    name={'protection_subscription_end'}
                    id={'protection_subscription_end'}
                    type='date'
                    value={(protection && formatDate(protection)) ?? ''}
                    onChange={e => setProtection(new Date(e.target.value))}
                  />
                </div>
              </div>
              <div>
                <label htmlFor={'detection_subscription_end'}>
                  <input type={'checkbox'} readOnly checked={isActive(detection)} />
                  <div>{t('lockeoDetectionSolution')}</div>
                </label>
                <div>
                  <div>{t('until')}</div>
                  <input
                    name={'detection_subscription_end'}
                    id={'detection_subscription_end'}
                    type='date'
                    value={(detection && formatDate(detection)) ?? ''}
                    onChange={e => setDetection(new Date(e.target.value))}
                  />
                </div>
              </div>
              <div>
                <label htmlFor={'catalog_subscription_end'}>
                  <input type={'checkbox'} readOnly checked={isActive(catalog)} />
                  <div>{t('smartCatalogSolution')}</div>
                </label>
                <div>
                  <div>{t('until')}</div>
                  <input
                    name={'catalog_subscription_end'}
                    id={'catalog_subscription_end'}
                    type='date'
                    value={(catalog && formatDate(catalog)) ?? ''}
                    onChange={e => setCatalog(new Date(e.target.value))}
                  />
                </div>
              </div>
            </div>
            <div>
              <label htmlFor={'cvat_project_id'}>{t('patternExtractionId')}</label>
              <input name={'cvat_project_id'} type='number' min={1} defaultValue={company.cvat_project_id} />
            </div>
            <button type='submit'>{t('validate')}</button>
          </form>
        </Popup>
        <div className={companyActive ? styles.active : styles.inactive}></div>
      </div>
      <Dropdown open={selected == i} watchResize={true}>
        <div className={styles.companyBox}>
          <div className={styles.companyInfo}>
            <div>
              <h3>{t('address')}</h3>
              <p>{company.address || t('noAddress')}</p>
            </div>
            <div>
              <h3>siret</h3>
              <p>{company.siret || t('noSiret')}</p>
            </div>
            <div>
              <h3>{t('subscription')}</h3>
              <div>
                <div>
                  <p>Lockeo Protection :</p>
                  <p>{(company.protection_subscription_end && formatDate(company.protection_subscription_end)) ?? t('notSubscribed')}</p>
                </div>
                <div>
                  <p>{t('lockeoDetection')} :</p>
                  <p>{(company.detection_subscription_end && formatDate(company.detection_subscription_end)) ?? t('notSubscribed')}</p>
                </div>
                <div>
                  <p>{t('smartCatalog')} :</p>
                  <p>{(company.catalog_subscription_end && formatDate(company.catalog_subscription_end)) ?? t('notSubscribed')}</p>
                </div>
              </div>
            </div>
          </div>
          <h3>{t('users')}</h3>
          <CRUD
            endpoints={{
              read: `api/users?company=${encodeURIComponent(company.name)}`,
              create: `api/users`,
              update: `api/users`,
              delete: `api/users`,
            }}
            onCreated={() => setPopupAddUser(false)}
            effect={[userRequests]}
          >
            {(userCrud: CRUDObject<UserWithCompanyName[]>) => {
              const { data: users, loading } = userCrud.read;

              if (loading) return <div>Loading...</div>;

              return (
                <Fragment>
                  <div className={styles.companyUsers}>
                    {users.map((user, j: number) => {
                      return (
                        <div key={user.id}>
                          <p>
                            {`${user.first_name} ${user.last_name}${user.function && ` - ${user.function}`}${user.email && ` - ${user.email}`}${
                              user.phone_number && ` ${user.phone_number}`
                            }`}
                          </p>
                          <Query<UserToken> query={`get_api_token?user_id=${user.id}`}>
                            {({ data, loading }) => {
                              if (loading) return <div>Loading...</div>;
                              const { token } = data;

                              return (
                                <div className={styles.companyUsersToken}>
                                  <div>
                                    <p>Token</p>
                                    <p>{token}</p>
                                  </div>
                                  <div onClick={() => navigator.clipboard.writeText(token)}>
                                    <FaCopy />
                                  </div>
                                </div>
                              );
                            }}
                          </Query>
                          <button onClick={() => setSelectedUser(j)}>{t('change')}</button>
                          <Popup up={selected == i && selectedUser == j} onClose={() => setSelectedUser(undefined)}>
                            <form
                              onSubmit={e => {
                                userCrud.handleUpdate(e, { name: 'id', method: 'PUT' }).then(() => {
                                  setSelectedUser(undefined);
                                  updateRequest();
                                });
                              }}
                              onMouseDown={e => e.stopPropagation()}
                            >
                              <input name='id' type={`number`} value={user.id} style={{ display: 'none' }} readOnly />
                              <h2>
                                {t('change')} {user.first_name} {user.last_name}
                              </h2>
                              {insert({
                                name: 'first_name',
                                title: t('firstName'),
                                required: true,
                                defaultValue: user.first_name,
                                readOnly: true,
                              })}
                              {insert({
                                name: 'last_name',
                                title: t('name'),
                                required: true,
                                defaultValue: user.last_name,
                                readOnly: true,
                              })}
                              {insert({
                                name: 'email',
                                title: 'Email',
                                type: 'email',
                                required: true,
                                defaultValue: user.email,
                              })}
                              {insert({ name: 'function', title: 'Fonction', defaultValue: user.function })}
                              {insert({
                                name: 'phone_number',
                                title: t('phoneNumber'),
                                type: 'tel',
                                defaultValue: user.phone_number,
                                pattern: phonePattern,
                              })}
                              {insert({
                                name: 'send_to_textilia',
                                title: t('ipoToText'),
                                type: 'checkbox',
                                defaultChecked: user.send_to_textilia,
                                disabled: true,
                              })}
                              <div>
                                <label htmlFor={`company`}>{t('company')}</label>
                                <select name={`company`} id={`company`} defaultValue={company.name}>
                                  {companies.map(company => (
                                    <option key={company.id} defaultValue={company.name}>
                                      {company.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <button type='submit'>{t('change')}</button>
                            </form>
                          </Popup>
                          <button
                            className={styles.companyUsersDelete}
                            onClick={() => {
                              if (confirm(`${t('areYouSureDelete')} ${user.first_name} ${user.last_name} ?`))
                                userCrud.handleDelete({ id: user.id }, { name: 'id' });
                            }}
                          >
                            {t('delete')}
                          </button>
                        </div>
                      );
                    })}
                  </div>
                  <button onClick={() => setPopupAddUser(true)}>{t('add')}</button>
                  <Popup up={selected == i && popupAddUser} onClose={() => setPopupAddUser(false)}>
                    <form onSubmit={userCrud.handleCreate} onMouseDown={e => e.stopPropagation()}>
                      <h2>
                        {t('add')} {t('aNewUser')}
                      </h2>
                      <input type='hidden' name={`company`} value={company.name} />
                      {insert({ name: 'first_name', title: t('firstName'), required: true })}
                      {insert({ name: 'last_name', title: t('name'), required: true })}
                      {insert({ name: 'email', title: 'Email', type: 'email', required: true })}
                      {insert({ name: 'function', title: t('function') })}
                      {insert({ name: 'phone_number', title: t('phoneNumber'), type: 'tel', pattern: phonePattern })}
                      {insert({
                        name: 'send_to_textilia',
                        title: t('ipoToText'),
                        type: 'checkbox',
                        defaultChecked: true,
                      })}
                      <button type='submit'>{t('add')}</button>
                    </form>
                  </Popup>
                </Fragment>
              );
            }}
          </CRUD>
        </div>
      </Dropdown>
    </div>
  );
};
