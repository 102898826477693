import type { FunctionComponent, InputHTMLAttributes } from 'react';

import magnifyingGlass from 'assets/images/magnifyingGlass.svg';

import styles from './index.module.scss';

type SearchProps = InputHTMLAttributes<HTMLInputElement>;

export const Search: FunctionComponent<SearchProps> = props => {
  return (
    <div className={styles.main}>
      <input {...props} />
      <img src={magnifyingGlass} draggable={false} />
    </div>
  );
};
