import { Chip, Tooltip } from '@mui/material';
import { Query } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { getDefaultFilters, useFilters } from 'components/SmartCatalog/contexts';

import type Metadata from 'interfaces/Metadata';

import style from './index.module.scss';

export const ActiveFilters: FunctionComponent = () => {
  const { t } = useTranslation();
  const [filters, setFilters] = useFilters();

  const { text, all_books, all_rebracks, dateBegin, dateEnd, colors, drawings, rebracks, books, patterns } = filters;

  const handleFilters = () => setFilters({ ...filters });

  const handleRemoveWithKey =
    (key, obj: any = filters, value: any = undefined) =>
    () => {
      obj[key] = value;
      handleFilters();
    };

  const clickDeleteHandleRemove = (key, obj: any = filters, value: any = undefined) => ({
    onClick: handleRemoveWithKey(key, obj, value),
    onDelete: handleRemoveWithKey(key, obj, value),
  });

  const activeFilters = [
    text,
    all_books,
    all_rebracks,
    dateBegin.value,
    dateEnd.value,
    ...[...colors, ...drawings].map(c => c.checked),
    rebracks.length,
    books.length,
    patterns.length
  ].filter(v => v);

  const filtersNum = activeFilters.length;

  const reset = () => setFilters(getDefaultFilters({ sort: filters.sort }));

  return (
    <div className={style.main}>
      {filtersNum > 1 && <Chip label={<div className={style.chip}>{t('removeAllFilters')}</div>} clickable onClick={reset} onDelete={reset} />}
      {filters.patterns.length > 0 && <Chip label={<div className={style.chip}>{t('removeAllPatterns')}</div>} clickable {...clickDeleteHandleRemove('patterns', filters, [])} />}
      {filters.text && (
        <Chip
          label={
            <div className={style.chip}>
              {t('search')} '<i>{filters.text}</i>'
            </div>
          }
          clickable
          {...clickDeleteHandleRemove('text', filters, '')}
        />
      )}
      {['all_books', 'all_rebracks'].map((key, i) => {
        return (
          <Fragment key={i}>
            {filters[key] && <Chip className={style.chip} label={t(key)} clickable {...clickDeleteHandleRemove(key, filters, false)} />}
          </Fragment>
        );
      })}
      {(
        [
          ['books', filters.books],
          ['rebracks', filters.rebracks],
        ] as [string, number[]][]
      ).map(([key, metadataIds], i) => {
        return (
          <Query<Metadata[]>
            key={i}
            query={`api/${i == 0 ? `metadata/?filters=${encodeURIComponent(JSON.stringify({ type: 'book' }))}` : 'rebracks'}`}
            memoizedLoading
          >
            {({ data: metadatas, loading }) => {
              if (loading) return <></>;

              const realMetadatas = metadatas.filter(m => metadataIds.includes(m.id));

              const { length } = realMetadatas;

              const label = length > 1 ? `${length} ${t(key)}` : length == 1 ? `'${realMetadatas[0].name}'` : '';

              return (
                <>
                  {realMetadatas.length > 0 && (
                    <Tooltip
                      title={
                        <div className={style.chip}>
                          {realMetadatas.map((r, i) => (
                            <div key={i}>{r.name}</div>
                          ))}
                        </div>
                      }
                      placement='bottom'
                    >
                      <Chip className={style.chip} label={label} clickable {...clickDeleteHandleRemove(key, filters, [])} />
                    </Tooltip>
                  )}
                </>
              );
            }}
          </Query>
        );
      })}
      {[dateBegin, dateEnd]
        .filter(d => d.value)
        .map((date, i) => {
          return <Chip key={i} label={`${t(date.name)} : ${date.value}`} clickable {...clickDeleteHandleRemove('value', date)} />;
        })}
      {[colors, drawings].map((checkables, i) => {
        const filtered = checkables.filter(c => c.checked);

        return (
          <Fragment key={i}>
            {filtered.length > 0 &&
              filtered.map((checkable, i) => {
                const { name } = checkable;
                return <Chip key={i} className={style.chip} label={t(name)} clickable {...clickDeleteHandleRemove('checked', checkable, false)} />;
              })}
          </Fragment>
        );
      })}
    </div>
  );
};
