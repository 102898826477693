import type { CRUDObject } from '@neovision/react-query';
import { CRUD } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyBar } from 'components/App/Account/Admin/CompanyBar';
import { Placeholder, Popup } from 'components/utils';

import type { Company } from 'interfaces';

import { isActive } from 'utils/checks';

import styles from './index.module.scss';

interface FormType {
  name: string;
  type?: string;
  title?: string;
  required?: boolean;
  readOnly?: boolean;
  defaultValue?: string;
  pattern?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
}

export const insert = ({
  name,
  type = 'text',
  required = false,
  title = name,
  defaultValue,
  defaultChecked,
  readOnly = false,
  pattern,
  disabled = false,
}: FormType) => {
  return (
    <div>
      <label htmlFor={name}>
        {title}
        {required && '*'}
      </label>
      <input
        id={name}
        name={name}
        type={type}
        required={required}
        defaultValue={defaultValue}
        defaultChecked={defaultChecked}
        readOnly={readOnly}
        pattern={pattern}
        disabled={disabled}
      />
    </div>
  );
};

export const Admin: FunctionComponent = () => {
  const { t } = useTranslation();
  const [selectedCompany, setSelectedCompany] = useState<number>();

  const [popupAddCompany, setPopupAddCompany] = useState<boolean>(false);

  const handlePopupAddCompanyClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setPopupAddCompany(true);
  };

  const handleSelectCompany = (i: number) => {
    selectedCompany == i ? setSelectedCompany(undefined) : setSelectedCompany(i);
  };

  return (
    <div className={styles.main}>
      <CRUD endpoints={`api/companies`} onCreated={() => setPopupAddCompany(false)} dateFormat='iso'>
        {(companyCrud: CRUDObject<Company[]>) => {
          const [detection, setDetection] = useState<string>();
          const [protection, setProtection] = useState<string>();
          const [catalog, setCatalog] = useState<string>();
          const [userRequests, setUserRequests] = useState(0);
          const updateUserRequests = () => setUserRequests(userRequests + 1);

          const { data: companies, loading } = companyCrud.read;

          if (companies) companies.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

          return (
            <>
              <div>
                <h1>{t('companies')}</h1>
              </div>
              <div>
                <div className={styles.configButtons}>
                  <button onClick={handlePopupAddCompanyClose}>{t('add')}</button>
                  <Popup up={popupAddCompany} onClose={() => setPopupAddCompany(false)}>
                    <form onMouseDown={e => e.stopPropagation()} onSubmit={companyCrud.handleCreate}>
                      <h2>{t('addACompany')}</h2>
                      {insert({ name: 'name', required: true, title: t('name') })}
                      {insert({ name: 'address', required: true, title: t('address') })}
                      {insert({ name: 'siret', required: true, title: 'Siret' })}
                      <div className={styles.formSubscribtion}>
                        <label>{t('subscription')}</label>
                        <div>
                          <label htmlFor={'protection_subscription_end'}>
                            <input type={'checkbox'} readOnly checked={isActive(protection)} />
                            <div>{t('lockeoProtectionSolution')}</div>
                          </label>
                          <div>
                            <div>{t('until')}</div>
                            <input
                              name={'protection_subscription_end'}
                              id={'protection_subscription_end'}
                              type='date'
                              value={protection ?? ''}
                              onChange={e => setProtection(e.target.value)}
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor={'detection_subscription_end'}>
                            <input type={'checkbox'} readOnly checked={isActive(detection)} />
                            <div>{t('lockeoDetectionSolution')}</div>
                          </label>
                          <div>
                            <div>{t('until')}</div>
                            <input
                              name={'detection_subscription_end'}
                              id={'detection_subscription_end'}
                              type='date'
                              value={detection ?? ''}
                              onChange={e => setDetection(e.target.value)}
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor={'catalog_subscription_end'}>
                            <input type={'checkbox'} readOnly checked={isActive(catalog)} />
                            <div>{t('smartCatalogSolution')}</div>
                          </label>
                          <div>
                            <div>{t('until')}</div>
                            <input
                              name={'catalog_subscription_end'}
                              id={'catalog_subscription_end'}
                              type='date'
                              value={catalog ?? ''}
                              onChange={e => setCatalog(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <label htmlFor={'cvat_project_id'}>{t('patternExtractionId')}</label>
                        <input name={'cvat_project_id'} type='number' min={1} />
                      </div>
                      <button type='submit'>Valider</button>
                    </form>
                  </Popup>
                </div>
                <div className={styles.companies}>
                  {(loading ? [...new Array(5).keys()].map(() => undefined) : companies).map((company, i) => {
                    return (
                      <Fragment key={i}>
                        {!company ? (
                          <Placeholder className={styles.placeholder} />
                        ) : (
                          <CompanyBar
                            company={company}
                            handleSelect={() => handleSelectCompany(i)}
                            crud={companyCrud}
                            i={i}
                            selected={selectedCompany}
                            companies={companies}
                            userRequests={userRequests}
                            updateRequest={updateUserRequests}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </>
          );
        }}
      </CRUD>
    </div>
  );
};
