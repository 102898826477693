import { Skeleton } from '@mui/material';
import { Query } from '@neovision/react-query';
import { FunctionComponent, useEffect } from 'react';
import { useState } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { RawSolutionItem, RawSolutionItemProps } from 'components/utils/SolutionItem/Raw';

import type { ElementType } from 'interfaces/Selection';
import { Pattern, parseRebrack } from 'interfaces/pattern/Pattern';
import { PatternType } from 'interfaces/pattern/Pattern';

import { paths } from 'utils/paths';

import bubbles from 'assets/images/3bubbles.gif';

import styles from './index.module.scss';

export interface SolutionItemProps {
  url?: string;
  link: (item: any) => string;
  active: boolean;
  buttonLink?: string;
  type?: ElementType;
  customLoader?: JSX.Element;
  includeSimilar?: boolean;
  uniqueLoad?: boolean;
  index?: number;
}

const loader = <Skeleton className={styles.placeholder} variant={'rectangular'} />;

const REBRACK_LIMIT = 5;

export const SolutionItem: FunctionComponent<SolutionItemProps> = props => {
  const { url, active, link, customLoader, includeSimilar = false, uniqueLoad = false, index = 0 } = props;
  const navigate = useNavigate();

  return (
    <Query<Pattern | undefined> query={url ?? ''} ignore={!active} delay={index * 5}>
      {({ data: item }) => {
        const [page, setPage] = useState(0);
        const [itemsProps, setItemProps] = useState<RawSolutionItemProps[]>([]);

        useEffect(() => {
          if (item) {
            const arr: RawSolutionItemProps[] = [{ ...props, id: item.id, pattern: item }];
            if (includeSimilar && item?.type == PatternType.RebrackPattern) {
              parseRebrack(item);
              arr.push(
                ...item.rebrack_siblings
                  .filter((_, i) => i < REBRACK_LIMIT)
                  .map(id => {
                    return {
                      ...props,
                      id,
                      url: `get_pattern_from_id?id=${id}&thumbnail=true`,
                      link: ({ id }) => `/${paths.catalog}/${paths.product}/${id}`,
                    };
                  }),
              );
            }

            setItemProps(arr);
          }
        }, [item]);

        return (
          <div className={styles.pattern} draggable={false} onClick={() => item && navigate(link(item))}>
            {item ? (
              <div>
                <div className={styles.items}>
                  {itemsProps.map((itemProps, i) => (
                    <div key={itemProps.id} style={{ left: page == i ? 0 : i < page ? '-100%' : '100%' }}>
                      <RawSolutionItem {...itemProps} active={page == i} />
                    </div>
                  ))}
                </div>
                <div className={styles.arrows}>
                  <div>
                    {page > 0 && (
                      <HiChevronLeft
                        onClick={e => {
                          e.stopPropagation();
                          const p = page - 1;
                          setPage(p < 0 ? 0 : p);
                        }}
                      />
                    )}
                  </div>
                  <div>
                    {page < itemsProps.length - 1 && (
                      <HiChevronRight
                        onClick={e => {
                          e.stopPropagation();
                          const p = page + 1;
                          setPage(p < itemsProps.length - 1 ? p : itemsProps.length - 1);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.loader}>{uniqueLoad ? <img src={bubbles} /> : customLoader || loader}</div>
            )}
          </div>
        );
      }}
    </Query>
  );
};
