import { createCacheKey, setCache } from "./cache";
import { requestError, requestWarn } from "./log";
import { buildHeader, restructureData } from "./util";
import { XHRFetch } from "./xhr";
/**
 * Merge request options together. The right most objects in the array override the elements to the left
 * @param options RequestOptions
 * @param override weither we should override the data or try to merge it. For example a function in the params might want to still be called. Thus we would need to merge it with our new one
 * @returns T
 *
 * @example
 * requestOptionsMerge({method: "GET"}, {method: "POST"})
 * // {method: "POST"}
 * const options = requestOptionsMerge(
 *    {onRejected: () => console.log("hey")},
 *    {onRejected: () => console.log("bye")}
 * )
 * options.onRejected()
 * // hey
 * // bye
 */
export const requestOptionsMerge = (options, override = false) => {
    if (override)
        return Object.merge(...options);
    return Object.merge(...options, {
        onRejected: (rej) => options.forEach((o) => o.onRejected?.(rej)),
    });
};
/**
 * The underlying request object used to iteract with the server
 * @param domain Domain name used
 * @param path The path after the domain where the data can be retrieved
 * @param options The request options
 * @returns A Promise with the data
 *
 * @example
 * request("https://google.fr", "/apî/users/1", {method:"GET"})
 */
export const request = function (domain, path, options = {
    method: "GET",
}) {
    const { method = "GET", headers, mode = "production", onRejected, data, cache, ...rest } = options;
    const { body, contentType } = restructureData(data);
    const req = (headers) => {
        const endpoint = `${domain}/${path}`;
        const cacheHash = cache != 0 ? createCacheKey(path, data) : "";
        return XHRFetch(endpoint, {
            headers: buildHeader(headers, contentType),
            method,
            body,
            ...rest,
        })
            .then((res) => {
            setCache(cacheHash, res, cache);
            return res;
        })
            .catch((err) => {
            if (err.name == "AbortError")
                requestWarn(mode, 0, 0, err.message);
            else {
                requestError(method, endpoint, `${err.status} ${err.statusText}`);
                onRejected?.(err);
                throw err;
            }
        });
    };
    return headers ? headers().then(req) : req({});
};
/**
 * A way to know what to do with the LoaderOptions being passed
 *
 * No loader means no autoloading. If loader is set to be a boolean, then autoloading is active. Autoloading is active if explicitly set to true
 *
 * @param loader
 * @returns LoaderOptions
 */
export const parseLoader = (loader) => {
    if (loader == undefined)
        return {};
    if (typeof loader == "boolean")
        return { autoload: true };
    return loader;
};
