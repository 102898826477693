import styles from '../index.module.scss';
import { useRequest } from '@neovision/react-query';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useUser } from 'states/user';

import type { UserWithCompany } from 'interfaces';

import form from 'scss/components/_form.module.scss';

import textiliaWhiteLeft from 'assets/images/textiliaWhiteLeft.svg';
import textiliaWhiteRight from 'assets/images/textiliaWhiteRight.svg';

export const Login: FunctionComponent = () => {
  const [username, setUsername] = useState(localStorage.getItem('userEmail') ?? '');
  const [password, setPassword] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [_, setUser] = useUser();

  const request = useRequest();

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    localStorage.setItem('userEmail', username);
    Auth.signIn(username, password)
      .then(() => {
        request<UserWithCompany>(`get_my_profile/`)
          .then(profile => setUser(profile))
          .catch(e => {
            enqueueSnackbar(t('errorOccured'), { variant: 'error' });
            console.error('An error occured', e);
          });
      })
      .catch((e: any) => {
        enqueueSnackbar(t('loginOrPassword'), { variant: 'error' });
        console.error('Login', e);
      });
  };

  return (
    <div className={styles.main}>
      <form className={form.account} onSubmit={handleLogin}>
        <div className={form.images}>
          <img src={textiliaWhiteLeft} />
          <img src={textiliaWhiteRight} />
        </div>
        <div className={form.title}>{t('connectTitle')}</div>
        <div className={form.connect}>
          <div>
            <input type='text' name='username' value={username} onChange={e => setUsername(e.target.value)} placeholder={t('emailAddress')} required />
          </div>
          <div>
            <input type='password' name='password' value={password} onChange={e => setPassword(e.target.value)} placeholder={t('password')} required />
          </div>
          <Link to={'/resetPassword'}>*{t('forgotPassword')}</Link>
          <div className={form.connections}>
            <button type='submit'>{t('connect')}</button>
            <div className={form.or}>
              <hr />
              {t('or')}
              <hr />
            </div>
            <button disabled>{t('createAccount')}</button>
          </div>
        </div>
      </form>
    </div>
  );
};
