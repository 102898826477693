import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useUser } from 'states/user';

import { Settings } from 'components/App/Account/Admin/Settings';
import { Manage } from 'components/CounterfeitDetection/Manage';
import { Pattern } from 'components/CounterfeitDetection/Manage/Pattern';
import { Matches } from 'components/CounterfeitDetection/Matches';
import { Results } from 'components/CounterfeitDetection/Results';

import { paths } from 'utils/paths';

export const CounterfeitDetection: FunctionComponent = () => {
  const [user] = useUser();
  const { t } = useTranslation();

  document.title = t('lockeoDetection');

  return (
    <Routes>
      {user && (
        <>
          <Route path={`/${paths.results}`} element={<Results />} />
          <Route path={`/${paths.matches}`} element={<Matches />} />
          <Route path={`/${paths.management}/*`} element={<Manage />} />
          <Route path={`/${paths.patterns}/:id`} element={<Pattern />} />
          {user.is_staff && <Route path={`/${paths.settings}`} element={<Settings />} />}
          <Route path={`/*`} element={<Navigate to={`${paths.results}`} />} />
        </>
      )}
    </Routes>
  );
};
