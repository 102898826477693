import styles from '../index.module.scss';
import { useMouse, useTrigger } from '@generalizers/react-events';
import { Button, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { Query, StateFunction, useRequest } from '@neovision/react-query';
import hash from 'object-hash';
import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiDownArrowAlt, BiRefresh, BiUpArrowAlt } from 'react-icons/bi';
import { HiOutlineEye } from 'react-icons/hi';
import { IoChevronUp, IoOptionsOutline } from 'react-icons/io5';
import { RxCross1 } from 'react-icons/rx';
import { useNavigate, useParams } from 'react-router-dom';

import { Ariane } from 'components/SmartCatalog/Ariane';
import { ActiveFilters } from 'components/SmartCatalog/Catalog/ActiveFilters';
import { BookFilter } from 'components/SmartCatalog/Catalog/BookFilter';
import { PageList } from 'components/SmartCatalog/Catalog/Items/Pages/List';
import { PatternList } from 'components/SmartCatalog/Catalog/Items/Patterns/List';
import { RebrackList } from 'components/SmartCatalog/Catalog/Items/Rebracks/List';
import { showAmount as useShowAmount } from 'components/SmartCatalog/Catalog/metadataSelections';
import type { View } from 'components/SmartCatalog/contexts';
import { defaultDelay } from 'components/SmartCatalog/contexts';
import { getDefaultFilters, useFiltersImages, usePage, useView, useVisualSearch } from 'components/SmartCatalog/contexts';
import { Popup } from 'components/utils';
import { BlackButton } from 'components/utils/Button/BlackButton';
import { Dropdown } from 'components/utils/Dropdown';
import { Size, SizeIcon } from 'components/utils/SizeIcon';

import type { Metadata, Pattern } from 'interfaces';
import type { Rebrack } from 'interfaces/Rebrack';

import { dropdownTransforms } from 'utils/dropdown';
import { pickTextColorBasedOnBgColor, useDebounce } from 'utils/functions';
import { paths } from 'utils/paths';

import PatternsDropdown from './Dropdowns/PatternsDropdown';

const maxShownBooksFilter = 5;
const limitShowMetadataFilter = 15;
const maxShownRebrackFilter = maxShownBooksFilter;
export const minBookDate = 1700;
export const maxBookDate = new Date().getFullYear() + 1;

export const isValidBookDate = (n: number) => {
  return n >= minBookDate && n <= maxBookDate;
};

const validateDates = (begin: number = minBookDate, end: number = maxBookDate) => {
  const validBegin = isValidBookDate(begin) && begin <= (end ?? maxBookDate);
  const validEnd = isValidBookDate(end) && end >= (begin ?? minBookDate);
  return validBegin && validEnd;
};

const sortByMap = {
  id: 'date',
  name: 'name',
  random: 'random',
};

export const Catalog: FunctionComponent = () => {
  const { searchPatternId, bookId, rebrackId } = useParams();
  const { t } = useTranslation();
  const [dateBegin, setDateBegin] = useState<number>();
  const [dateEnd, setDateEnd] = useState<number>();
  const [[filters, setFilters], [imageSrc, setImageSrc]] = useFiltersImages();
  const [image, setImage] = useVisualSearch();
  const [page, setPage] = usePage();
  const [selectedAllPatterns, setSelectedAllPatterns] = useState(false);
  const [view, _setView] = useView();

  const navigate = useNavigate();
  const request = useRequest();

  const handleUpdateFilters = () => setFilters({ ...filters });
  useDebounce(handleUpdateFilters, defaultDelay, [dateBegin, dateEnd, rebrackId, bookId]);

  useEffect(() => {
    setDateBegin(filters.dateBegin.value);
    setDateEnd(filters.dateEnd.value);
  }, [filters.dateBegin.value, filters.dateEnd.value]);

  useEffect(() => {
    if (image) {
      const fr = new FileReader();
      fr.addEventListener('load', () => {
        if (fr.result) {
          setImageSrc(fr.result.toString());
        }
      });
      fr.readAsDataURL(image);
    }
  }, [image]);

  useEffect(() => {
    if (imageSrc == undefined) filters.pattern_id = undefined;
    else filters.text = undefined;
    handleUpdateFilters();
  }, [imageSrc]);

  useEffect(() => {
    if (filters.text) clearImage();
  }, [filters.text]);

  useEffect(() => {
    if (searchPatternId != undefined) {
      setView('pattern');
      setCatalog();
      filters.pattern_id = parseInt(searchPatternId);
      request<Pattern>(`get_pattern_from_id?id=${searchPatternId}&thumbnail=true`).then(p => {
        setImageSrc(`data:image/gif;base64,${p?.thumbnail}`);
        setImage(undefined);
      });
      handleUpdateFilters();
    }
  }, [searchPatternId]);

  useEffect(() => {
    if (image !== undefined || imageSrc !== undefined || searchPatternId !== undefined) {
      setPage(1);
    }
  }, [image, imageSrc, searchPatternId, hash(filters)]);

  const setView = (v: View) => {
    if (v != 'pattern') {
      setImageSrc(undefined);
      setImage(undefined);
    }
    if (v != view) navigate(`/${paths.catalog}/`, { replace: true });
    localStorage.setItem('catalogView', v);
    _setView(v);
  };

  const setCatalog = () => navigate(`/${paths.catalog}`, { replace: true });

  const correctFrom = !!(dateBegin == undefined || (dateBegin && isValidBookDate(dateBegin) && dateBegin <= (filters.dateEnd.value ?? maxBookDate)));
  const correctTo = !!(dateEnd == undefined || (dateEnd && isValidBookDate(dateEnd) && dateEnd >= (filters.dateBegin.value ?? minBookDate)));

  const hasBookFilters = filters.all_books || filters.books.length > 0;
  const hasRebrackFilters = filters.all_rebracks || filters.rebracks.length > 0;

  const clearImage = () => {
    setImage(undefined);
    setImageSrc(undefined);
  };

  useEffect(() => {
    if (view != 'pattern') {
      if (
        (hasBookFilters && !hasRebrackFilters && view != 'page') ||
        (hasRebrackFilters && !hasBookFilters && view != 'rebrack') ||
        (!hasRebrackFilters && !hasBookFilters) ||
        (hasBookFilters && hasRebrackFilters)
      )
        setView('pattern');
    }
  }, [hasBookFilters, hasRebrackFilters, view]);

  useEffect(() => {
    if (bookId) {
      const book = parseInt(bookId);
      setFilters(getDefaultFilters({ books: [book] }));
      setCatalog();
    }
  }, [bookId]);

  useEffect(() => {
    if (rebrackId) {
      const rebrack = parseInt(rebrackId);
      setFilters(getDefaultFilters({ rebracks: [rebrack] }));
      setCatalog();
    }
  }, [rebrackId]);

  const activeDrawings = filters.drawings.filter(d => d.checked);
  const activeColors = filters.colors.filter(d => d.checked);

  return (
    <>
      <div className={styles.header}>
        <Ariane />
        <div className={styles.display}>
          {hasBookFilters != hasRebrackFilters && (
            <div className={styles.view}>
              <button className={view == 'pattern' ? styles.selected : ''} onClick={() => setView('pattern')}>
                {t('pattern')}
              </button>
              {hasBookFilters && !hasRebrackFilters && (
                <button className={view == 'page' ? styles.selected : ''} onClick={() => setView('page')}>
                  Page
                </button>
              )}
              {hasRebrackFilters && !hasBookFilters && (
                <button className={view == 'rebrack' ? styles.selected : ''} onClick={() => setView('rebrack')}>
                  Rebrack
                </button>
              )}
            </div>
          )}
          <div className={styles.view}>
            <SizeIcon size='small' />
            <SizeIcon size='medium' />
            <SizeIcon size='big' />
          </div>
        </div>
      </div>
      <div className={styles.pageSection}>
        <div className={styles.pageSectionLeft}>
          <div className={styles.bar}>
            {view == 'pattern' && (
              <div className={styles.sortBy}>
                <div>{t('sortBy')}</div>
                <div className={styles.view}>
                  <div>
                    <Select
                      className={styles.button}
                      variant='standard'
                      sx={{
                        m: '0px',
                        padding: '0px',
                        borderRadius: '30px',
                        overflow: 'hidden',
                        '& > div': {
                          p: '2px 5px !important',
                        },
                      }}
                      componentsProps={{
                        input: {
                          style: { padding: '0px !important' },
                        },
                        root: {
                          style: { borderRadius: '30px' },
                        },
                      }}
                      size='small'
                      value={filters.sort.order_by}
                      IconComponent={() => null}
                      onChange={e => {
                        filters.sort.order_by = e.target.value as any;
                        handleUpdateFilters();
                      }}
                    >
                      {Object.entries(sortByMap).map(([k, v]) => {
                        return (
                          <MenuItem key={k} value={k}>
                            {t(v)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {(filters.sort.order_by === 'id' || filters.sort.order_by === 'name') && (
                      <Button
                        sx={{ minWidth: 'auto', color: 'black', m: '0 4px', p: '2px' }}
                        onClick={() => {
                          filters.sort.desc = !filters.sort.desc;
                          handleUpdateFilters();
                        }}
                      >
                        {filters.sort.desc ? (
                          <BiDownArrowAlt style={{ width: '20px', height: '20px' }} />
                        ) : (
                          <BiUpArrowAlt style={{ width: '20px', height: '20px' }} />
                        )}
                      </Button>
                    )}
                    {filters.sort.order_by === 'random' && (
                      <Button
                        sx={{ minWidth: 'auto', color: 'black', m: '0 4px', p: '2px' }}
                        onClick={() => {
                          filters.sort.desc = !filters.sort.desc;
                          handleUpdateFilters();
                        }}
                      >
                        <BiRefresh style={{ width: '20px', height: '20px' }} />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
            <hr />
            <div className={styles.activeFilters}>
              <div className={styles.activeFiltersTitle}>
                <IoOptionsOutline />
                <div>{t('filters')}</div>
              </div>
              <div>
                {imageSrc && (
                  <div className={styles.visualSearch}>
                    <div>
                      <img src={imageSrc} draggable={false} />
                      <RxCross1 onClick={() => (setImage(undefined), setImageSrc(undefined), navigate(`/${paths.catalog}`))} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div className={styles.filters}>
              <div>
                {/* Style dropdown */}
                <Dropdown
                  className={styles.dropdown}
                  open={false}
                  element={open => (
                    <div className={`${styles.dropdownElement} ${styles.blue} ${open && styles.blueShown}`}>
                      <div>
                        {t('style')} {activeDrawings.length > 0 && `(${activeDrawings.length})`}
                      </div>
                      <div style={dropdownTransforms(open)}>
                        <IoChevronUp />
                      </div>
                    </div>
                  )}
                >
                  <div className={`${styles.dropdownContent} ${styles.dropdownDrawing}`}>
                    {filters.drawings
                      .sort((a, b) => t(a.name).localeCompare(t(b.name)))
                      .map((drawing, i) => {
                        const { name, checked } = drawing;
                        return (
                          <div key={i}>
                            <input
                              id={name}
                              name={name}
                              type='checkbox'
                              checked={checked}
                              onChange={() => {
                                drawing.checked = !drawing.checked;
                                handleUpdateFilters();
                              }}
                            />
                            <label htmlFor={name}>{t(name)}</label>
                          </div>
                        );
                      })}
                  </div>
                </Dropdown>
                {/* Color dropdown */}
                <Dropdown
                  className={`${styles.dropdown} ${styles.dropdownContentColor}`}
                  open={false}
                  element={open => (
                    <div className={`${styles.dropdownElement} ${styles.blue} ${open && styles.blueShown}`}>
                      <div>
                        {t('colors')} {activeColors.length > 0 && `(${activeColors.length})`}
                      </div>
                      <div style={dropdownTransforms(open)}>
                        <IoChevronUp />
                      </div>
                    </div>
                  )}
                >
                  <div className={`${styles.dropdownContent} ${styles.dropdownColor}`}>
                    {filters.colors.map((color, i) => {
                      const { name, checked, colors } = color;
                      const className = `color-${name}`;

                      const background = `{background: linear-gradient(to right, ${colors.join(',')})};}`;

                      return (
                        <Tooltip
                          key={i}
                          title={t(name)}
                          PopperProps={{
                            sx: {
                              pointerEvents: 'none',
                              '& > div': {
                                marginTop: '0px !important',
                              },
                            },
                          }}
                        >
                          <div>
                            <input
                              className={className}
                              type='checkbox'
                              checked={checked}
                              style={{ border: checked ? `1px solid black` : '' }}
                              onChange={() => {
                                color.checked = !color.checked;
                                handleUpdateFilters();
                              }}
                            />
                            <style>
                              {`.${className}[type=checkbox]`}
                              {background}
                              {`.${className}[type=checkbox]:checked`}
                              {background}
                              {`.${className}[type=checkbox]:checked::before{background-color:${pickTextColorBasedOnBgColor(name)} !important}`}
                            </style>
                          </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                </Dropdown>
                {/* Date dropdown */}
                <Dropdown
                  className={styles.dropdown}
                  open={false}
                  watchResize
                  element={open => (
                    <div className={`${styles.dropdownElement} ${styles.blue} ${open && styles.blueShown}`}>
                      <div>Date</div>
                      <div style={dropdownTransforms(open)}>
                        <IoChevronUp />
                      </div>
                    </div>
                  )}
                >
                  <div className={`${styles.dropdownContent} ${styles.dropdownDates}`}>
                    <div>
                      <label>{t('from')}</label>
                      <TextField
                        className={styles.dateInput}
                        error={!correctFrom}
                        type='number'
                        label={t('year')}
                        InputProps={{ inputProps: { min: minBookDate, max: dateEnd ?? '' } }}
                        helperText={!correctFrom && t('yearFormat', { begin: minBookDate, end: filters.dateEnd.value ?? maxBookDate })}
                        value={dateBegin ?? ''}
                        size={'small'}
                        FormHelperTextProps={{ style: { fontSize: '0.6em' } }}
                        onChange={({ target }) => {
                          const value = parseInt(target.value);
                          setDateBegin(value);
                          if (target.value == '') {
                            setDateBegin(undefined);
                            filters.dateBegin.value = undefined;
                          } else if (validateDates(value, filters.dateEnd.value)) {
                            filters.dateBegin.value = value;
                            if (validateDates(value, dateEnd)) filters.dateEnd.value = dateEnd;
                            else filters.dateEnd.value = undefined;
                          }
                        }}
                      />
                    </div>
                    <div>
                      <label>{t('to')}</label>
                      <TextField
                        className={styles.dateInput}
                        error={!correctTo}
                        type='number'
                        label={t('year')}
                        InputProps={{ inputProps: { min: dateBegin ?? minBookDate, max: maxBookDate } }}
                        helperText={!correctTo && t('yearFormat', { begin: filters.dateBegin.value ?? minBookDate, end: maxBookDate })}
                        size={'small'}
                        FormHelperTextProps={{ style: { fontSize: '0.6em' } }}
                        value={dateEnd ?? ''}
                        onChange={({ target }) => {
                          const value = parseInt(target.value);
                          setDateEnd(value);
                          if (target.value == '') {
                            setDateEnd(undefined);
                            filters.dateEnd.value = undefined;
                          } else if (validateDates(filters.dateBegin.value, value)) {
                            filters.dateEnd.value = value;
                            if (validateDates(dateBegin, value)) filters.dateBegin.value = dateBegin;
                            else filters.dateBegin.value = undefined;
                          }
                        }}
                      />
                    </div>
                  </div>
                </Dropdown>
                {/* Book dropdown */}
                <StateFunction>
                  {() => {
                    const [up, setUp] = useState(false);
                    const [open, setOpen] = useState(false);

                    useEffect(() => {
                      if (filters.all_books || filters.books.length > 0) setOpen(true);
                    }, [filters.all_books || filters.books.length > 0]);

                    return (
                      <Query<Metadata[]>
                        query={`api/metadata/${
                          filters.dateBegin
                            ? `?filters=${encodeURIComponent(
                                JSON.stringify({
                                  dateBegin: filters.dateBegin?.value,
                                  dateEnd: filters.dateEnd?.value,
                                  type: 'book',
                                  showEmpty: filters.showEmpty,
                                }),
                              )}`
                            : ''
                        }`}
                        memoizedLoading
                      >
                        {({ data, loading }) => {
                          const books = data ?? [];
                          const [search, setSearch] = useState('');
                          const [showMore, setShowMore] = useState(0);

                          const handleCheck = (b?: number) => () => {
                            if (b != undefined) {
                              if (filters.books.includes(b)) filters.books.remove(b);
                              else filters.books.push(b);
                              filters.all_books = false;
                            } else {
                              filters.books = [];
                              filters.all_books = !filters.all_books;
                            }
                            handleUpdateFilters();
                          };

                          useMouse('click', () => setShowMore(0), [showMore]);

                          const [shown, showMoreAction, currentLimit] = useShowAmount(
                            books,
                            search,
                            'books',
                            showMore,
                            maxShownBooksFilter,
                            limitShowMetadataFilter,
                          );

                          if (loading || books.length == 0) return <></>;

                          const handleSearch = e => {
                            setSearch(e.target.value);
                          };

                          return (
                            <>
                              <Dropdown
                                className={styles.dropdown}
                                watchResize={true}
                                open={open}
                                handleOpen={o => setOpen(o)}
                                element={open => (
                                  <div className={`${styles.dropdownElement} ${styles.blue} ${open && styles.blueShown}`}>
                                    <div>
                                      {t('books')} {filters.books.length > 0 && `(${filters.books.length})`}
                                    </div>
                                    <div className={styles.dropdownElementBook}>
                                      <HiOutlineEye
                                        onClick={e => {
                                          e.stopPropagation();
                                          setUp(true);
                                        }}
                                      />
                                      <div style={dropdownTransforms(open)}>
                                        <IoChevronUp />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              >
                                <div className={`${styles.dropdownContent} ${styles.dropdownBooks}`} onClick={e => e.stopPropagation()}>
                                  <TextField
                                    className={styles.searchBook}
                                    label={t('searchForBooks')}
                                    variant='filled'
                                    value={search}
                                    onChange={handleSearch}
                                    type='search'
                                    size='small'
                                    InputLabelProps={{ style: { pointerEvents: 'none' } }}
                                  />
                                  {filters.all_books && (
                                    <div>
                                      <input type='checkbox' onChange={handleCheck()} checked={filters.all_books} />
                                      <label>{t('all')}</label>
                                    </div>
                                  )}
                                  {shown
                                    .filter((_, i) => i < currentLimit)
                                    .map((b, i) => {
                                      const r = `book-${i}`;
                                      return (
                                        <Tooltip key={i} title={b.name} placement='right'>
                                          <div>
                                            <input id={r} type='checkbox' onChange={handleCheck(b.id)} checked={filters.books.includes(b.id)} />
                                            <label htmlFor={r}>{b.name}</label>
                                          </div>
                                        </Tooltip>
                                      );
                                    })}
                                </div>
                                <div>
                                  {shown.length > maxShownBooksFilter && (
                                    <div className={styles.showPlus}>
                                      <BlackButton
                                        onClick={e => {
                                          e.stopPropagation();
                                          if (showMoreAction) setShowMore(0);
                                          else setShowMore(showMore + 1);
                                        }}
                                      >
                                        {showMoreAction ? t('showLess') : t('showMore')}
                                      </BlackButton>
                                    </div>
                                  )}
                                  {!(filters.all_books || shown.every(s => filters.books.includes(s.id))) && (
                                    <div className={styles.showPlus} onClick={e => e.stopPropagation()}>
                                      <BlackButton
                                        onClick={() => {
                                          if (search == '') {
                                            filters.all_books = true;
                                            filters.books = [];
                                          } else {
                                            filters.all_books = false;
                                            filters.books = shown.map(s => s.id);
                                          }
                                          handleUpdateFilters();
                                        }}
                                      >
                                        {`${t('selectAll')} (${shown.length})`}
                                      </BlackButton>
                                    </div>
                                  )}
                                </div>
                                <Popup className={styles.popup} up={up} onClose={() => setUp(false)}>
                                  {up => (
                                    <BookFilter
                                      books={books}
                                      up={up}
                                      onFilter={books => {
                                        filters.all_books = false;
                                        filters.books = [...books];
                                        setUp(false);
                                        handleUpdateFilters();
                                        if (books.length != 0) setOpen(true);
                                      }}
                                    />
                                  )}
                                </Popup>
                              </Dropdown>
                            </>
                          );
                        }}
                      </Query>
                    );
                  }}
                </StateFunction>
                {/* Rebrack dropdown */}
                <Query<Rebrack[]>
                  query={`api/rebracks/${
                    filters.dateBegin
                      ? `?filters=${encodeURIComponent(JSON.stringify({ dateBegin: filters.dateBegin?.value, dateEnd: filters.dateEnd?.value }))}`
                      : ''
                  }`}
                  memoizedLoading
                >
                  {({ data, loading }) => {
                    const rebracks = data ?? [];

                    const [search, setSearch] = useState('');
                    const [open, setOpen] = useState(false);
                    const [showMore, setShowMore] = useState(0);

                    useEffect(() => {
                      if (filters.all_rebracks || filters.rebracks.length > 0) setOpen(true);
                    }, [filters.all_rebracks || filters.rebracks.length > 0]);

                    const [shown, showMoreAction, currentLimit] = useShowAmount(
                      rebracks,
                      search,
                      'rebracks',
                      showMore,
                      maxShownRebrackFilter,
                      limitShowMetadataFilter,
                    );

                    if (loading || rebracks.length == 0) return <></>;

                    const handleCheck = (b?: number) => () => {
                      if (b != undefined) {
                        if (filters.rebracks.includes(b)) filters.rebracks.remove(b);
                        else filters.rebracks.push(b);
                        filters.all_rebracks = false;
                      } else {
                        filters.rebracks = [];
                        filters.all_rebracks = !filters.all_rebracks;
                      }
                      handleUpdateFilters();
                    };

                    const handleSearch = e => {
                      setSearch(e.target.value);
                    };

                    return (
                      <>
                        {loading || rebracks.length == 0 ? (
                          <></>
                        ) : (
                          <>
                            <Dropdown
                              className={styles.dropdown}
                              watchResize={true}
                              open={open}
                              handleOpen={o => setOpen(o)}
                              element={open => (
                                <div className={`${styles.dropdownElement} ${styles.blue} ${open && styles.blueShown}`}>
                                  <div>Rebracks {filters.rebracks.length > 0 && `(${filters.rebracks.length})`}</div>
                                  <div style={dropdownTransforms(open)}>
                                    <IoChevronUp />
                                  </div>
                                </div>
                              )}
                            >
                              <div className={`${styles.dropdownContent} ${styles.dropdownBooks}`}>
                                <TextField
                                  className={styles.searchBook}
                                  label={t('searchForRebracks')}
                                  variant='filled'
                                  value={search}
                                  onChange={handleSearch}
                                  type='text'
                                  size='small'
                                  InputLabelProps={{ style: { pointerEvents: 'none' } }}
                                />
                                {filters.all_rebracks && (
                                  <div>
                                    <input type='checkbox' onChange={handleCheck()} checked={filters.all_rebracks} />
                                    <label>{t('all')}</label>
                                  </div>
                                )}
                                {shown
                                  .filter((_, i) => i < currentLimit)
                                  .map(({ name, id }, i) => {
                                    return (
                                      <Tooltip key={i} title={name} placement='right'>
                                        <div>
                                          <input
                                            type='checkbox'
                                            id={`rebrack-${name}`}
                                            onChange={handleCheck(id)}
                                            checked={filters.rebracks.includes(id)}
                                          />
                                          <label htmlFor={`rebrack-${name}`}>{name}</label>
                                        </div>
                                      </Tooltip>
                                    );
                                  })}
                              </div>
                              <div>
                                {shown.length > maxShownRebrackFilter && (
                                  <div className={styles.showPlus}>
                                    <BlackButton
                                      onClick={e => {
                                        e.stopPropagation();
                                        if (showMoreAction) setShowMore(0);
                                        else setShowMore(showMore + 1);
                                      }}
                                    >
                                      {showMoreAction ? t('showLess') : t('showMore')}
                                    </BlackButton>
                                  </div>
                                )}
                                {!(filters.all_rebracks || shown.every(s => filters.rebracks.includes(s.id))) && (
                                  <div className={styles.showPlus} onClick={e => e.stopPropagation()}>
                                    <BlackButton
                                      onClick={() => {
                                        if (search == '') {
                                          filters.all_rebracks = true;
                                          filters.rebracks = [];
                                        } else {
                                          filters.all_rebracks = false;
                                          filters.rebracks = shown.map(s => s.id);
                                        }
                                        handleUpdateFilters();
                                      }}
                                    >
                                      {`${t('selectAll')} (${shown.length})`}
                                    </BlackButton>
                                  </div>
                                )}
                              </div>
                            </Dropdown>
                          </>
                        )}
                      </>
                    );
                  }}
                </Query>
                {/* Patterns dropdowns */}
                <Dropdown
                  className={styles.dropdown}
                  open={false}
                  watchResize
                  element={open => (
                    <div className={`${styles.dropdownElement} ${styles.blue} ${open && styles.blueShown}`}>
                      <div>{t('patterns')}</div>
                      <div style={dropdownTransforms(open)}>
                        <IoChevronUp />
                      </div>
                    </div>
                  )}
                >
                  <PatternsDropdown />
                </Dropdown>
              </div>
            </div>
          </div>
          <hr style={{ width: 1, height: '100vh' }} />
        </div>
        <div className={styles.content}>
          <ActiveFilters />
          {view == 'pattern' ? <PatternList /> : view == 'page' ? <PageList /> : <RebrackList />}
        </div>
      </div>
    </>
  );
};
