import { TextField, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import { defaultDelay, useFiltersImages } from 'components/SmartCatalog/contexts';
import { BlackButton } from 'components/utils/Button/BlackButton';

import { axiosInstance as axios } from 'utils/axios';
import { useDebounce } from 'utils/functions';

import styles from './PatternsDropDown.module.scss';

// Import other required dependencies and components

interface Pattern {
  id: number;
  name: string;
}

const PatternsDropdown = () => {
  // State hooks
  const [[filters, setFilters]] = useFiltersImages();
  const [patternSearch, setPatternSearch] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const [patterns, setPatterns] = useState<Pattern[]>([]);
  const [patternsCount, setPatternsCount] = useState<number | null>(null);

  // Debounce function
  useDebounce(
    () => {
      const getPatterns = async () => {
        console.log('Hellothere');

        try {
          const res = await axios.get(`/get_pattern_from_name?query=${patternSearch}`);
          setPatternsCount(res.data.count);
          setPatterns(res.data.patterns);
        } catch (error) {
          // Handle error
        } finally {
          setIsloading(false);
        }
      };

      // Check if patternSearch is not an empty string before making the request
      if (patternSearch !== '') {
        getPatterns();
      } else {
        setFilters({ ...filters, patterns: [] });
      }
    },
    defaultDelay,
    [patternSearch],
  );

  useEffect(() => {
    const getPatterns = async () => {
      console.log('Hellothere');

      try {
        const res = await axios.get(`/get_pattern_from_name?query=${patternSearch}`);
        setPatternsCount(res.data.count);
        setPatterns(res.data.patterns);
      } catch (error) {
        // Handle error
      } finally {
        setIsloading(false);
      }
    };
    getPatterns();
  }, []);

  // Event handler for pattern search
  const handlePatternSearch = e => {
    setIsloading(true);

    // Clear patterns if search is empty
    if (e.target.value === '') {
      setPatterns([]);
      setPatternsCount(0);
    }

    setPatternSearch(e.target.value);
  };

  return (
    <div className={''}>
      {/* Search input */}
      <TextField
        autoComplete={'off'}
        label={`${t('searchForPatterns')}`}
        variant='filled'
        value={patternSearch}
        onChange={handlePatternSearch}
        type='search'
        size='small'
        InputLabelProps={{ style: { pointerEvents: 'none' } }}
      />

      {/* Display patterns list if available */}
      {patterns.length > 0 && <PatternList count={patternsCount ?? 0} patterns={patterns} />}

      {/* Display no result message if no patterns are found */}
      {patternSearch !== '' && patternsCount === 0 && !isLoading && <div className={styles.noResultMessage}>{`${t('noResult')}`}</div>}
    </div>
  );
};

interface PatternListProps {
  patterns: Pattern[];
  count: number;
}

const PatternList = ({ count, patterns }: PatternListProps) => {
  const [[filters, setFilters]] = useFiltersImages();
  const [itemsPerPage, setItemPerPage] = useState(5);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [selectedPatterns, setSelectedPatterns] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const shownPatterns = patterns.slice(0, itemsPerPage);

  const toggleShow = () => {
    if (!showMore) {
      setShowMore(!showMore);
      setItemPerPage(prevIndex => prevIndex + 10);
    } else {
      setShowMore(!showMore);
      setItemPerPage(prevIndex => prevIndex - 10);
    }
  };

  useDebounce(
    () => {
      setFilters({ ...filters, patterns: selectedPatterns });
    },
    500,
    [selectedPatterns],
  );

  useEffect(() => {
    if (filters.patterns.length === patterns.length) setSelectAll(true);
    else setSelectAll(false);
  }, [filters.patterns.length]);

  const onSelectPattern = (patternId: number) => {
    setSelectedPatterns(prev => [...prev, patternId]);
  };
  const onDeselectPattern = (patternId: number) => {
    setSelectedPatterns(prev => prev.filter(id => id !== patternId));
  };
  const onSelectAll = () => {
    setSelectAll(true);
    const allPatternIds = patterns.map(pattern => pattern.id);
    setSelectedPatterns(allPatternIds);
  };
  const onDeselectAll = () => {
    setSelectAll(false);
    setSelectedPatterns([]);
  };

  return (
    <div className={styles.patternDropDownList}>
      {selectAll && (
        <div className={styles.patternDropDownItem}>
          <input
            id={'selectAll'}
            type='checkbox'
            checked={selectAll}
            onChange={() => {
              if (selectAll) {
                onDeselectAll();
              }
            }}
          />
          <label htmlFor={`selectAll`}>{`${t('all')}`}</label>
        </div>
      )}
      {shownPatterns &&
        shownPatterns.map(pattern => (
          <PatternListItem
            onSelectAll={selectAll}
            pattern={pattern}
            key={pattern.id}
            onDeselect={patternId => onDeselectPattern(patternId)}
            onSelect={patternId => onSelectPattern(patternId)}
          />
        ))}
      {patterns.length > 5 && (
        <div className={styles.buttonsContainer}>
          <BlackButton
            className={styles.blackButton}
            onClick={e => {
              e.stopPropagation();
              toggleShow();
            }}
          >
            {!showMore ? `${t('showMore')}` : `${t('showLess')}`}
          </BlackButton>
        </div>
      )}
      <div className={styles.buttonsContainer}>
        {!selectAll && (
          <BlackButton
            className={styles.blackButton}
            onClick={() => {
              onSelectAll();
            }}
          >
            {`${t('selectAll')} (${count})`}
          </BlackButton>
        )}
      </div>
    </div>
  );
};

interface PatternListItemProps {
  pattern: Pattern;
  onSelect: (patternId: number) => void;
  onDeselect: (patternId: number) => void;
  onSelectAll: boolean;
}

const PatternListItem = ({ pattern }: PatternListItemProps) => {
  const [[filters, setFilters]] = useFiltersImages();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  // useEffect(() => {
  //   if (onSelectAll) {
  //     setIsChecked(true);
  //   } else {
  //     setIsChecked(false);
  //   }
  // }, [filters.patterns.length]);

  return (
    <>
      {pattern && (
        <div className={styles.patternDropDownItem}>
          <input
            id={String(pattern.id)}
            type='checkbox'
            checked={filters.patterns.includes(pattern.id)}
            onChange={() => {
              if (filters.patterns.includes(pattern.id)) setFilters({ ...filters, patterns: filters.patterns.filter(id => id !== pattern.id) });
              else setFilters({ ...filters, patterns: [...filters.patterns, pattern.id] });
              // if (!isChecked) {
              //   setIsChecked(true);
              //   onSelect(pattern.id);
              // } else {
              //   setIsChecked(false);
              //   onDeselect(pattern.id);
              // }
            }}
            value={pattern.name}
          />
          <Tooltip title={pattern.name} placement='right' className={styles.patternItemLabel}>
            <label htmlFor={`${pattern}`}>{pattern.name}</label>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default PatternsDropdown;
