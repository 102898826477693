import { Query, StateFunction, useRequest } from '@neovision/react-query';
import type { TotalProgress } from '@neovision/react-query/lib/utils/xhr/progress';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FaBookOpen } from 'react-icons/fa';

import { maxBookDate, minBookDate } from 'components/SmartCatalog/Catalog';
import { Slider } from 'components/utils/Slider';

import type { Company, Metadata } from 'interfaces';
import { DropType } from 'interfaces/DropType';

import { isActive } from 'utils/checks';
import { parseErrors } from 'utils/functions';

import book from 'assets/images/book/book.png';
import cross from 'assets/images/cross.svg';

import styles from './index.module.scss';

const expandTextarea = ({ target }: any) => {
  if (target.nodeName != 'TEXTAREA') return;
  target.style.height = 'auto';
  if (target.scrollHeight > 30) target.style.height = `${target.scrollHeight}px`;
};

export const DropFile: FunctionComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState<File>();
  const [year, setYear] = useState<number>();
  const [collection, setCollection] = useState<string>();
  const [fabricType, setFabricType] = useState<string>();
  const [site, setSite] = useState<string>();
  const [building, setBuilding] = useState<string>();
  const [room, setRoom] = useState<string>();
  const [shelf, setShelf] = useState<string>();
  const [companyId, setCompanyId] = useState<number>();
  const [otherMetadata, setOtherMetadata] = useState<string>();
  const [loading, setLoading] = useState<TotalProgress>();
  const request = useRequest();

  const fileSizeAsString = (file: File) => {
    if (file.size < 10 ** 3) return `${file.size} ${t('bytes')}`;
    if (file.size < 10 ** 6) return `${(file.size / 10 ** 3).toFixed(1)} Kilo ${t('bytes')}`;
    if (file.size < 10 ** 9) return `${(file.size / 10 ** 6).toFixed(1)} Mega ${t('bytes')}`;
    return `${(file.size / 10 ** 9).toFixed(1)} Giga ${t('bytes')}`;
  };

  const name = file ? file.name.split('.').reduce((prev, curr, i, arr) => (i == arr.length - 1 ? prev : prev + curr), '') : '';

  const reset = () => {
    setFile(undefined);
    setLoading(undefined);
  };

  return (
    <div className={styles.main}>
      <div className={styles.book}>
        <h3>{file ? name : t('dropAFile')}</h3>
        <div>
          {file ? (
            <div className={`${styles.inactiveZone}`}>
              <div className={styles.innerImage}>
                <img src={book} draggable={false} />
              </div>
              <button
                className={styles.delete}
                onClick={() => {
                  setFile(undefined);
                }}
              >
                <img src={cross} />
              </button>
            </div>
          ) : (
            <Dropzone
              accept={{ 'application/zip': [] }}
              onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
                e.stopPropagation();
              }}
              maxFiles={1}
              minSize={1}
              noDragEventsBubbling
              onDropAccepted={acceptedFiles => {
                if (acceptedFiles.length == 1) {
                  setFile(acceptedFiles[0]);
                }
              }}
            >
              {({ getInputProps, getRootProps, isDragAccept, isDragReject }) => (
                <div className={`${styles.activeZone} ${isDragAccept ? styles.accept : isDragReject ? styles.reject : ''}`} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <FaBookOpen className={styles.icon} />
                  <h3>{t('addAZipFile')}</h3>
                </div>
              )}
            </Dropzone>
          )}
        </div>
        {file && (
          <div className={styles.info}>
            <div>
              <label>{t('addedOn')}</label>
              <div>{new Date().toISOString().split('T')[0]}</div>
            </div>
            <div>
              <label>{t('size')}</label>
              <div>{`${fileSizeAsString(file)}`}</div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.data}>
        <h3>Informations</h3>
        {file && (
          <StateFunction>
            {() => {
              const [type, setType] = useState(DropType.Book);
              const [quality, setQuality] = useState(name);

              return (
                <form
                  className={styles.mainForm}
                  onSubmit={e => {
                    e.preventDefault();
                    const formData = new FormData();
                    if (type == DropType.Rebrack) {
                      const dateName = name + new Date().toJSON();
                      formData.append('name', dateName);
                      formData.append('zip', file, dateName + '.zip');
                    } else {
                      formData.append('name', name);
                      formData.append('zip', file);
                    }
                    formData.append('type', type.toString());
                    companyId && formData.append('company', companyId.toString());
                    year && formData.append('year', year.toString());
                    collection && formData.append('collection', collection);
                    fabricType && formData.append('cloth_type', fabricType);
                    site && formData.append('site_location', site);
                    building && formData.append('building_location', building);
                    room && formData.append('room_location', room);
                    shelf && formData.append('shelf_location', shelf);
                    otherMetadata && formData.append('other', otherMetadata);
                    type == DropType.Rebrack && quality && formData.append('quality', quality);

                    Auth.currentAuthenticatedUser().then(user => {
                      const { refreshToken } = user.getSignInUserSession();
                      user.refreshSession(refreshToken, (err: any) => {
                        if (err) throw new Error(err);

                        request<Metadata>('api/metadata/', {
                          data: formData,
                          method: 'POST',
                          progress: setLoading,
                          onRejected: () => reset(),
                        })
                          .then(res => {
                            if (res) {
                              enqueueSnackbar(t('fileAdded'), { variant: 'success' });
                              reset();
                            } else enqueueSnackbar(t('cantImportFile'), { variant: 'error' });
                          })
                          .catch(e => {
                            const errors = parseErrors(e, t('cantImportFile'));
                            if (errors) errors.forEach(e => enqueueSnackbar(e, { variant: 'error' }));
                            else enqueueSnackbar(t('cantImportFile'), { variant: 'error' });
                          });
                      });
                    });
                  }}
                >
                  <div>
                    <label>{t('company')}*</label>
                    <Query<Company[]> query='api/companies'>
                      {({ data, loading }) => {
                        const companies = loading ? [] : data.filter(company => isActive(company.catalog_subscription_end));

                        useEffect(() => {
                          if (!loading && !companyId && companies.length > 0) setCompanyId(companies[0].id);
                        }, [loading, companies]);

                        if (loading) return <div>Loading...</div>;

                        return (
                          <select onChange={e => setCompanyId(parseInt(e.target.value))} defaultValue={companyId}>
                            {companies.map((company, i) => (
                              <option key={i} value={company.id}>
                                {company.name}
                              </option>
                            ))}
                          </select>
                        );
                      }}
                    </Query>
                  </div>
                  <div>
                    <label>Type*</label>
                    <div>
                      <label htmlFor='book'>{t('book')}</label>
                      <input name='type' id='book' type={'radio'} defaultChecked onChange={() => setType(DropType.Book)} />
                    </div>
                    <div>
                      <label htmlFor='rebrack'>Rebrack</label>
                      <input name='type' id='rebrack' type={'radio'} onChange={() => setType(DropType.Rebrack)} />
                    </div>
                    <div>
                      <label htmlFor='pattern'>{t('pattern')}</label>
                      <input name='type' id='pattern' type={'radio'} onChange={() => setType(DropType.Colors)} disabled />
                    </div>
                  </div>
                  <div>
                    <label>{type == DropType.Book ? t('bookYear') : t('rebrackYear')}</label>
                    <input min={minBookDate} max={maxBookDate} type={'number'} value={year} onChange={e => setYear(parseInt(e.target.value))} />
                  </div>
                  {type == DropType.Rebrack && (
                    <div>
                      <label htmlFor='quality'>{t('quality')}</label>
                      <input name='quality' id='quality' value={quality} onChange={e => setQuality(e.target.value)} />
                    </div>
                  )}
                  <div>
                    <label>Collection</label>
                    <input type={'text'} value={collection} onChange={e => setCollection(e.target.value)} />
                  </div>
                  <div>
                    <label>{t('fabricType')}</label>
                    <input type={'text'} value={fabricType} onChange={e => setFabricType(e.target.value)} />
                  </div>
                  <div className={styles.localisation}>
                    <label>{t('location')}</label>
                    <div>
                      <label>{t('site').capitalize()}</label>
                      <input type={'text'} value={site} onChange={e => setSite(e.target.value)} />
                    </div>
                    <div>
                      <label>{t('building')}</label>
                      <input type={'text'} value={building} onChange={e => setBuilding(e.target.value)} />
                    </div>
                    <div>
                      <label>{t('room')}</label>
                      <input type={'text'} value={room} onChange={e => setRoom(e.target.value)} />
                    </div>
                    <div>
                      <label>{t('shelf')}</label>
                      <input type={'text'} value={shelf} onChange={e => setShelf(e.target.value)} />
                    </div>
                  </div>
                  <div>
                    <label>{t('other')}</label>
                    <textarea value={otherMetadata} onChange={e => setOtherMetadata(e.target.value)} onInput={expandTextarea} />
                  </div>

                  <hr />
                  {!loading ? (
                    <div className={styles.submit}>
                      <button type='submit'>{t('importFile')}</button>
                    </div>
                  ) : (
                    <>
                      <Slider className={styles.loader} count={loading.upload.percentage} length={100} />
                      {(() => {
                        loading.upload.percentage == 100 && setLoading(undefined);
                        return <></>;
                      })()}
                    </>
                  )}
                </form>
              );
            }}
          </StateFunction>
        )}
      </div>
    </div>
  );
};
