import type { Reject } from '@neovision/react-query/lib/utils/api';
import type { ReactElement } from 'react';
import { Fragment, useEffect, useState } from 'react';

import { colors } from 'utils/colors';

export const isChristmas = () => {
  const m = new Date().getMonth();
  return m + 1 == 12;
};

export const useDebounce = (func: () => any, timeout = 300, effect: any[] = []) => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  useEffect(() => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        func();
      }, timeout),
    );
  }, effect);
};

export const riter = (n: number, e: ReactElement) => [...new Array(n)].map((_, i) => <Fragment key={i}>{e}</Fragment>);

export const pickTextColorBasedOnBgColor = (bgColor: string) => {
  const hex = colorToHex(bgColor as keyof typeof colors);
  const color = hex.charAt(0) === '#' ? hex.substring(1, 7) : hex;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.547 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
};

export const colorToHex = (color: keyof typeof colors): string => {
  if (typeof colors[color.toLowerCase()] != 'undefined') return colors[color.toLowerCase()];
  return '#000000';
};

export const parseErrors = (rej: Reject<any>, optional?: string) => {
  const { data } = rej;
  if (Array.isArray(data) && data.length > 0) return [data[0]];
  else if (typeof data == 'object' && Object.keys(data).length > 0) {
    const { non_field_errors } = data;
    if (non_field_errors && Array.isArray(non_field_errors)) return non_field_errors;
    else {
      const entries = Object.entries<any>(data);
      return [`${entries[0][0]} : ${entries[0][1]}`];
    }
  } else if (optional) return [optional];
  return undefined;
};
