import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unsafe-finally */
import { requestError } from '../../../utils/log';
import styles from './index.module.scss';
import React from 'react';
const getDefaultState = () => ({
    hasError: false,
    error: { e: undefined, info: undefined },
});
export class ErrorBoundary extends React.Component {
    state = getDefaultState();
    componentDidCatch = (e, info) => {
        requestError(e);
        this.setState({ hasError: true, error: { e, info } });
    };
    componentDidUpdate(prevProps) {
        if (prevProps.children != this.props.children)
            this.setState(getDefaultState());
    }
    render() {
        const { children, detail } = this.props;
        const { hasError, error } = this.state;
        if (!hasError)
            return _jsx(_Fragment, { children: children });
        return (_jsxs("div", { className: styles.main, children: [_jsx("div", { children: error?.e?.message }), detail && (_jsxs(_Fragment, { children: [_jsx("hr", {}), _jsx("div", { children: error?.info?.componentStack.split('\n').map((v, k) => (_jsx("div", { children: v }, k))) })] }))] }));
    }
}
export default ErrorBoundary;
