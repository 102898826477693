import { Typography } from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useRef, useState } from 'react';
import { LuCheck, LuFilePlus2, LuFileText, LuLoader2, LuPlus, LuTrash2 } from 'react-icons/lu';
import { RxCross2 } from 'react-icons/rx';
import { useParams } from 'react-router-dom';

import type { IFile } from 'interfaces/pattern/Pattern';

import { axiosInstance as axios } from 'utils/axios';

import styles from './patternFile.module.scss';

interface PatternFileProps {
  files: IFile[];
  onUpload: (file: IFile) => void;
  onDelete: (file: IFile) => void;
}

const PatternFile = ({ files, onUpload, onDelete }: PatternFileProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingMessage, setUploadingMesesage] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  const hiddenFileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDownload = async (documentId: number, documentName: string) => {
    if (files.length > 0) {
      try {
        const response = await axios.get(`/download_document_from_id/?document_id=${documentId}`, {
          responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', documentName);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        enqueueSnackbar('Server error', {
          variant: 'error',
        });
      }
    }
  };

  const selectImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFileToUpload(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (fileToUpload) {
      try {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', fileToUpload);
        const { data } = await axios.post(`/upload_document_to_pattern_id?pattern_id=${id}`, formData);

        onUpload(data.file);
        setUploadingMesesage('Image téléversé');
      } catch (error) {
        enqueueSnackbar('Server error', {
          variant: 'error',
        });
      } finally {
        setIsUploading(false);
        removeSelectedFile();
        setTimeout(() => {
          setUploadingMesesage(null);
        }, 2000);
      }
    }
  };

  const removeSelectedFile = () => {
    setFileToUpload(null);
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.value = '';
    }
  };

  const removeFile = async (file: IFile) => {
    setIsDeleting(true);
    try {
      const deleteDocumentResponse = await axios.delete(`/delete_document_from_id/?document_id=${file.id}`);
      if (deleteDocumentResponse.status === 200) {
        removeSelectedFile();
        onDelete(file);
      }
    } catch (error) {
      enqueueSnackbar('Server error', {
        variant: 'error',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.linkedDocumentsTitle}>
          <input ref={hiddenFileInputRef} id='input-file' accept='.pdf' className={styles.fileInput} type='file' onChange={e => selectImage(e)} />
          <Typography pr={2}>Documents associés</Typography>
          {!fileToUpload && !uploadingMessage && (
            <LuFilePlus2
              onClick={() => {
                hiddenFileInputRef.current?.click();
              }}
              className={styles.plusFileIcon}
            />
          )}
          {uploadingMessage && (
            <div className={styles.uploadingMessageContainer}>
              <Typography>{uploadingMessage}</Typography>
              <LuCheck />
            </div>
          )}
          {fileToUpload && (
            <>
              <p>{fileToUpload.name}</p>
              <RxCross2 className={styles.cross} onClick={removeSelectedFile} />
              <button className={styles.uploadButton} onClick={() => handleUpload()}>
                Upload
                {isUploading && <LuLoader2 className={styles.loader} />}
              </button>
            </>
          )}
        </div>
        <div className={styles.filesContainer}>
          {files.map(file => {
            return (
              <div key={file.id} className={styles.documentItemContainer}>
                <div className={styles.fileTitle}>
                  <LuFileText />
                  <div className={styles.fileLink} onClick={() => handleDownload(file.id, file.name)}>
                    <Typography>{file.name}</Typography>
                  </div>
                </div>
                <div className={styles.fileActions}>
                  <LuTrash2 onClick={() => removeFile(file)} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PatternFile;
