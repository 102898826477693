export const formatDate = (inputDate: string | Date) => {
  let date = inputDate;

  if (typeof date == 'string') {
    date = new Date(inputDate);
  }

  const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

  return formattedDate;
};
